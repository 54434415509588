import React, { useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import App from 'domains/App';
import NotFound from 'domains/NotFound';
import useFeatures from 'domains/hooks/useFeatures.hook';
import Login from '../domains/Session/Login';
import PrivateRoute from './PrivateRoute';

const Routes = (): JSX.Element => {
  const { features, setAppFeautes } = useFeatures();
  useEffect(() => {
    setAppFeautes();
  }, [setAppFeautes]);

  if (features.loading) {
    return <div className="app-loader">Loading...</div>;
  }

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />

        <PrivateRoute path="/" component={App} exact={false} />

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
