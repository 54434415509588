import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'types/AppState';

const selectors = {
  channelSelector: createSelector(
    ({ inbox }: AppState) => inbox.channel,
    (inbox) => inbox
  ),
  messageSelector: createSelector(
    ({ inbox }: AppState) => inbox.messages,
    (messages) => messages
  ),
  authSelector: createSelector(
    ({ inbox }: AppState) => inbox.auth,
    (inbox) => inbox
  ),
  toastSelector: createSelector(
    ({ inbox }: AppState) => inbox.toast,
    (toast) => toast
  ),
};

export default selectors;
