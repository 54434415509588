import { Order } from 'domains/Orders/types';

enum BulkAction {
  None = '',
  MarkFulfilled = 'bulk_fulfilled',
  MarkPaid = 'bulk_paid',
  PrintInvoices = 'bulk_invoice',
  PrintPackingSlips = 'bulk_packing_slip',
  PrintMasterPickList = 'bulk_master_picklist',
  PrintMasterPickListExtended = 'bulk_master_picklist_extended',
}

export interface BulkApiOptions {
  fulfilledOrders?: Order[];
  isAllOrders?: boolean;
  searchTerm?: string;
  filterStatus?: string;
  startDate?: string;
  endDate?: string;
  showExtendedPickList?: boolean;
}

export default BulkAction;
