import React from 'react';

import images from 'config';

export interface DropdownProps {
  options: { value: string; label: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dropdown = ({ options, onChange }: DropdownProps): JSX.Element => (
  <div className="dropdown-wrapper">
    <select className="dropdown-list" onChange={onChange}>
      {options.map(
        (option): JSX.Element => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      )}
    </select>
    <img className="arrow" src={images.dropdownIcon} alt="dropdown arrow" />
  </div>
);

export default Dropdown;
