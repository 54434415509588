import { AxiosResponse } from 'axios';
import { setOrdersToFulfilledStatus } from 'domains/api';
import { BulkApiOptions } from 'types/BulkAction';
import apiClient from '../api/apiAuth';

export const bulkGenerateInvoices = async (
  selectedOrders: string[],
  options: BulkApiOptions
): Promise<AxiosResponse> => {
  const fulfilledOrders = options?.fulfilledOrders || [];

  const fulfilledOrderIds = fulfilledOrders.map((order) => order.orderId || '');

  if (fulfilledOrders.length > 0) {
    await setOrdersToFulfilledStatus(fulfilledOrderIds);
  }

  const response = await apiClient().post('/invoices/bulk/pdf-generation', {
    orderIds: selectedOrders,
    fulfilledOrderIds,
  });

  return response;
};

export const bulkMarkInvoicesPaid = async (selectedOrders: string[]): Promise<AxiosResponse> => {
  const response = await apiClient().post('/invoices/bulk/pay', {
    orderIds: selectedOrders,
  });

  return response;
};
