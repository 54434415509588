import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import cx from 'classnames';

import { useAppDispatch } from 'store';
import { setMobileMenuOpen } from 'store/appSlice';
import selectors from 'store/appSelectors';

import images from 'config';
import useChatChannel from 'domains/Inbox/hooks/useChatChannel.hook';
import { Logo, LinkButton } from '../shared/components';

import { logout } from '../Session/redux/sessionSlice';

import useRouter from '../hooks/useRouter';
import { FRONTEND_NAME, INBOX_MENU } from '../../constants';

interface MenuItem {
  id: number;
  name: string;
  displayName: string;
  icon: string;
}

interface MobileMenuProps {
  menuItems: MenuItem[];
  isMenuOpen: boolean;
  isCurrentMenu: (arg: string) => boolean;
}

const MobileMenu = ({ menuItems, isMenuOpen, isCurrentMenu }: MobileMenuProps): JSX.Element => {
  const {
    channel: { unreadMsgsCount },
  } = useChatChannel();
  const router = useRouter();
  const isMobileMenuOpen = useSelector(selectors.isMobileMenuOpen);
  const animateClose = useSelector(selectors.animateClose);
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        className={cx('left-container-mobile', {
          full: isMobileMenuOpen,
          mini: !isMobileMenuOpen && animateClose,
        })}
      >
        <div className="mobile-menu-logo-close">
          <LinkButton
            dataTestid="mobile-menu"
            className="toggle-icon"
            onClick={() => dispatch(setMobileMenuOpen(!isMobileMenuOpen))}
          >
            <img src={images.mobileMenuCloseIcon} alt="menu" className="close-button" />
          </LinkButton>
          <Link to="/">
            <Logo frontendName={FRONTEND_NAME} />
          </Link>
        </div>
        <div className="mobile-menu-content">
          <LinkButton
            className="menu-items"
            onClick={() => dispatch(setMobileMenuOpen(false))}
            componentContianer="div"
          >
            <>
              {menuItems.map((item) => (
                <Link
                  key={item.id}
                  to={`/${item.name}`}
                  className={cx('menu-item', {
                    'selected-menu-item': isCurrentMenu(item.name),
                  })}
                >
                  <img src={item.icon} alt={item.name} width={18} height={15} />
                  {/**
                   * For menu item INBOX_MENU i.e. chat, show unread msgs if unreadMsgCount > 0
                   */}
                  {item.name === INBOX_MENU && !!unreadMsgsCount && <div className="oval">{unreadMsgsCount}</div>}

                  {isMenuOpen && (
                    <span
                      className={cx('text', {
                        'selected-text': isCurrentMenu(item.name),
                      })}
                    >
                      {item.displayName}
                    </span>
                  )}
                </Link>
              ))}
            </>
          </LinkButton>

          <LinkButton dataTestid="mobile-menu-logout" className="logout" onClick={() => dispatch(logout(router.push))}>
            <>
              <img src={images.logoutIcon} alt="logout" width={18} height={15} />
              <span className="text">Logout</span>
            </>
          </LinkButton>
        </div>
      </div>

      {isMobileMenuOpen && (
        <LinkButton
          dataTestid="mobile-logout-overlay"
          className="overlay"
          onClick={() => dispatch(setMobileMenuOpen(false))}
        />
      )}
    </>
  );
};

export default MobileMenu;
