import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'types/AppState';
import { NotifyUser } from 'types/Notifiers';
import { OrderListToaster } from '../types';
import { hasAnyUnfulfillments, allAreFulfillments } from '../lib/bulk';
import { ORDER_LIST_LIMIT } from '../constants';

const selectors = {
  ordersSelector: createSelector(
    ({ orders }: AppState) => orders.orders,
    (orders) => orders
  ),
  allPreviousOrder: createSelector(
    ({ orders }: AppState) => orders.allPreviousOrder,
    (allPreviousOrder) => allPreviousOrder
  ),
  isLoadingSelector: createSelector(
    ({ orders }: AppState) => orders.isLoading,
    (isLoading) => isLoading
  ),
  isErrorSelector: createSelector(
    ({ orders }: AppState) => orders.isError,
    (isError) => isError
  ),
  isDoneLoadingSelector: createSelector(
    ({ orders }: AppState) => orders.isDoneLoading,
    (isDoneLoading) => isDoneLoading
  ),
  pageSelector: createSelector(
    ({ orders }: AppState) => orders.page,
    (page) => page
  ),
  searchTermSelector: createSelector(
    ({ orders }: AppState) => orders.searchTerm,
    (searchTerm) => searchTerm
  ),
  filterStatusSelector: createSelector(
    ({ orders }: AppState) => orders.filterStatus,
    (filterStatus) => filterStatus
  ),
  pageCountFromSelector: createSelector(
    ({ orders }: AppState) => orders.page,
    (page) => (page - 1) * ORDER_LIST_LIMIT + 1
  ),
  pageCountToSelector: createSelector(
    ({ orders }: AppState) => orders.page,
    ({ orders }: AppState) => orders.totalCount,
    (page, totalCount) => {
      const max = (page - 1) * ORDER_LIST_LIMIT + ORDER_LIST_LIMIT;
      if (max > totalCount) {
        return totalCount;
      }
      return max;
    }
  ),
  numberOfPagesSelector: createSelector(
    ({ orders }: AppState) => orders.totalCount,
    (totalCount) => Math.ceil(totalCount / ORDER_LIST_LIMIT)
  ),
  totalCountSelector: createSelector(
    ({ orders }: AppState) => orders.totalCount,
    (totalCount) => totalCount
  ),
  totalNewOrders: createSelector(
    ({ orders }: AppState) => orders.newOrdersCount,
    (newOrdersCount) => newOrdersCount
  ),
  /*
   * Currently, we do not have an API to fetch the Supplier information.
   * Therefore, for now, the supplier name is to be found from orders.
   *
   * There can be different variations of the same Supplier name containing the following:
   *
   */
  supplierNameSelector: createSelector(
    ({ orders }: AppState) => orders.orders,
    (orders) => {
      if (orders && orders.length > 0) {
        // auxiliary parts of the supplier name that we need to remove
        const auxiliaryParts = ['Exclusive', 'PayFac'];

        const supplierName = orders[0].supplierId;
        const split = supplierName?.split(' - ');

        // if one of the auxiliary parts was found, return name without it
        if (split && split.length > 1 && auxiliaryParts.includes(split[1])) {
          return split[0];
        }

        return supplierName;
      }

      return undefined;
    }
  ),
  isMergeLoading: createSelector(
    ({ orders }: AppState) => orders.isMergeLoading,
    (isMergeLoading) => isMergeLoading
  ),
  isMergeDone: createSelector(
    ({ orders }: AppState) => orders.isMergeDone,
    (isMergeDone) => isMergeDone
  ),
  notifyUser: ({ orders }: AppState): OrderListToaster => orders.notifyUser,
  notifyWithWarningsForTable: ({ orders }: AppState): Omit<NotifyUser, 'isWarning'> =>
    orders.notifyWithWarningsForTable,
  isShowingBulkActionsModalSelector: createSelector(
    ({ orders }: AppState) => orders.isShowingBulkActionsModal,
    (isShowingBulkActionsModal) => isShowingBulkActionsModal
  ),
  bulkActionTypeSelector: createSelector(
    ({ orders }: AppState) => orders.bulkActionType,
    (bulkActionType) => bulkActionType
  ),
  isSelectedOrdersContainUnfulfilledSelector: createSelector(
    ({ orders }: AppState) => orders,
    (orders) => (selectedOrders: string[]) =>
      hasAnyUnfulfillments(Object.values(orders.allPreviousOrder), selectedOrders)
  ),
  isSelectedOrdersAllFulfilledSelector: createSelector(
    ({ orders }: AppState) => orders,
    (orders) => (selectedOrders: string[]) => allAreFulfillments(Object.values(orders.allPreviousOrder), selectedOrders)
  ),
  sortSelector: createSelector(
    ({ orders }: AppState) => orders.sort,
    (sort) => sort
  ),
  dateFilterSelector: createSelector(
    ({ orders }: AppState) => orders.dateFilter,
    (dateFilter) => dateFilter
  ),
  displayDateRange: createSelector(
    ({ orders }: AppState) => orders.displayDateRange,
    (displayDateRange) => displayDateRange
  ),
  startEndDate: createSelector(
    ({ orders }: AppState) => orders,
    (orders) => ({ startDate: orders.startDate, endDate: orders.endDate })
  ),
  selectedOrders: createSelector(
    ({ orders }: AppState) => orders.selectedOrders,
    (selectedOrders) => selectedOrders
  ),
  selectedOrdersCountSelector: createSelector(
    ({ orders }: AppState) => orders.selectedOrders,
    (selectedOrders) => selectedOrders.length
  ),
  areSelectedOrdersAllSelector: createSelector(
    ({ orders }: AppState) => orders.areSelectedOrdersAll,
    (areSelectedOrdersAll) => areSelectedOrdersAll
  ),
  showSelectAllNotificationSelector: createSelector(
    ({ orders }: AppState) => orders.showSelectAllNotification,
    (showSelectAllNotification) => showSelectAllNotification
  ),
};

export default selectors;
