import React from 'react';

import images from 'config';

export interface LoadingProps {
  text?: string;
}

const Loading = ({ text = undefined }: LoadingProps): JSX.Element => (
  <div className="loading-container">
    <div className="spinner">
      <img src={images.loadingIcon} alt="loading" className="loading" />
    </div>
    {text && text}
  </div>
);

export default Loading;
