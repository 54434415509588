import { createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { AppThunk } from 'types/AppThunk';
import { fetchSettings, saveSettings } from '../api';
import { Settings } from '../types/Settings';

export interface settingsContainer {
  settings: Settings;
  isLoading: boolean;
  isError: boolean;
  shouldShowSuccess: boolean;
  notifyUser: { isNotify: boolean; isWarning: boolean; message: string };
  settingsChangeError: { isNotify: boolean; title: string };
}

export const initialState: settingsContainer = {
  settings: {
    notesShowSupplierNotesOnInvoice: false,
    notesShowSupplierNotesOnPackingSlip: false,
    lastFetchTimestamp: '',
  },
  isLoading: false,
  isError: false,
  shouldShowSuccess: false,
  notifyUser: { isNotify: false, isWarning: false, message: '' },
  settingsChangeError: { isNotify: false, title: '' },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetState: () => initialState,
    getSettings: (state) => ({
      ...state,
      isLoading: true,
      isError: false,
      settingsChangeError: initialState.settingsChangeError,
    }),
    getSettingsFulfilled: (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,
      settings: action.payload,
    }),
    toggleNotifier: (state, action) => ({
      ...state,
      notifyUser: action.payload,
    }),
    toggleSettingsChangeError: (state: settingsContainer, action) => {
      state.settingsChangeError = action.payload;
      return state;
    },
  },
});

export const { toggleNotifier, toggleSettingsChangeError } = settingsSlice.actions;

export const getSettings = (): AppThunk => async (dispatch) => {
  dispatch(settingsSlice.actions.getSettings());
  try {
    const settings = await fetchSettings();
    dispatch(settingsSlice.actions.getSettingsFulfilled(settings));
  } catch (e) {
    dispatch(
      toggleNotifier({
        isNotify: true,
        isWarning: true,
        message: 'Something went wrong. Try again later.',
      })
    );
  }
};

export const updateSettings = (settings: Settings): AppThunk => async (dispatch) => {
  try {
    const newSettings = await saveSettings(settings);
    dispatch(settingsSlice.actions.getSettingsFulfilled(newSettings));
    dispatch(
      toggleNotifier({
        isNotify: true,
        isWarning: false,
        message: 'Your settings were saved successfully.',
      })
    );
  } catch (e) {
    if (e?.response?.status === 409) {
      batch(() => {
        dispatch(
          settingsSlice.actions.toggleSettingsChangeError({
            isNotify: true,
            title: 'Cannot save settings',
          })
        );
      });
    } else {
      dispatch(
        toggleNotifier({
          isNotify: true,
          isWarning: true,
          message: 'Unable to save changes. Try again later.',
        })
      );
    }
  }
};
