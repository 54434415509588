enum PaymentType {
  card = 'card',
  newCard = 'new_card',
  ach = 'ach',
  eft = 'eft',
  bank = 'bank',
  pads = 'pads',
  invoice = 'invoice',
  wire = 'wire',
  paySupplier = 'paySupplier',
}

export default PaymentType;
