import { useMemo } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch, match as matchType } from 'react-router-dom';
import { Location, History } from 'history';

import queryString, { ParseOptions } from 'query-string';

export interface Match {
  isExact: boolean;
  path: string;
  url: string;
}

export interface ReactRouterFunctions<Params, MatchParams, LocationState, HistoryState> {
  push: (path: string, state?: unknown) => void;
  replace: (path: string, state?: unknown) => void;
  pathname: string;
  query: Params | ParseOptions;
  match: matchType<MatchParams>;
  location: Location<LocationState>;
  history: History<HistoryState>;
}

function useRouter<
  PARAMS = Record<string, never>,
  LOCATION = Record<string, never>,
  HISTORY = Record<string, never>,
  MATCH = Record<string, never>
>(): ReactRouterFunctions<PARAMS, MATCH, LOCATION, HISTORY> {
  const params = useParams<PARAMS>();
  const location = useLocation<LOCATION>();
  const history = useHistory<HISTORY>();
  const match = useRouteMatch<MATCH>();

  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
}

export default useRouter;
