import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
import cx from 'classnames';

export type Checked = boolean | null | 'indeterminate';

export interface CheckboxFieldProps {
  id: string;
  name: string;
  value: string | number;
  checked?: Checked;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  shouldDisabledCheck?: boolean;
  dataTestid?: string;
}

const CheckboxField = ({
  value,
  checked = null,
  label = undefined,
  onChange = undefined,
  disabled = false,
  shouldDisabledCheck = false,
  dataTestid = 'checkbox-field',
  ...props
}: CheckboxFieldProps): JSX.Element => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error;
  const refCheckbox = useRef<HTMLInputElement>(null);
  let isChecked = checked || false;
  let isDisabling = disabled;

  useEffect(() => {
    if (refCheckbox.current) {
      refCheckbox.current.indeterminate = false;
    }
  }, [checked]);

  const updateSelectedCheckboxes = (newValue: string): void => {
    const newFieldValue = new Set(field.value);

    if (newFieldValue.has(newValue)) {
      newFieldValue.delete(newValue);
    } else {
      newFieldValue.add(newValue);
    }

    helpers.setValue(Array.from(newFieldValue));
  };

  field.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSelectedCheckboxes(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  if (disabled && !shouldDisabledCheck && field.value.includes(value)) {
    isDisabling = false;
  }

  if (field.value && checked === null) {
    isChecked = field.value.includes(value);
  } else if (isChecked === 'indeterminate') {
    isChecked = false;

    if (refCheckbox.current) {
      refCheckbox.current.indeterminate = true;
    }
  }

  return (
    <div
      className={cx('input-checkbox-field', {
        'input-checkbox-field-error': hasError,
      })}
    >
      <label className="text-input-label">
        <input
          data-testid={dataTestid}
          type="checkbox"
          className={cx('checkbox-input', {
            'checkbox-input-error': hasError,
            indeterminate: refCheckbox.current?.indeterminate,
          })}
          disabled={isDisabling}
          // must be down because it is unknown what Foremilk props it is need
          // same goes with rpops as all Formilk props need some custom props
          // such as id and name
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={refCheckbox}
          value={value}
          checked={isChecked as boolean}
        />
        {label && { label }}
      </label>

      {hasError && (
        <div
          data-testid={`${dataTestid}-error`}
          className={cx('error-text-container', {
            error: hasError,
          })}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default CheckboxField;
