import React, { FunctionComponent } from 'react';
import images from 'config';
import { THIRD_PARTY_INTEGRATION } from '../../../../../constants';

export interface ThirdPartyIntegrationProps {
  qbdStatus?: string;
  isQBDSyncSent?: boolean;
  invoiceId?: string | undefined | null;
  qbdErrorMessage?: string;
  thirdPartyIntegrationName?: string;
}

const SyncSent = ({
  bodyText,
  thirdPartyIntegrationImg,
  altText,
}: {
  bodyText: string;
  thirdPartyIntegrationImg: string;
  altText: string;
}): JSX.Element => (
  <div className="qbd-sync-label">
    <div className="qb-sync-container">
      <img src={thirdPartyIntegrationImg} alt={altText} />
    </div>
    <div className="qbd-sync-text">{bodyText}</div>
  </div>
);

const SyncError = ({
  bodyText,
  thirdPartyIntegrationImg,
  altText,
}: {
  bodyText: string;
  thirdPartyIntegrationImg: string;
  altText: string;
}): JSX.Element => (
  <div className="qbd-sync-error-label">
    <div className="qb-sync-container">
      <img src={thirdPartyIntegrationImg} alt={altText} />
    </div>
    <div className="qbd-sync-text">{bodyText}</div>
  </div>
);

const Syncing = ({ bodyText }: { bodyText: string }): JSX.Element => (
  <div className="qbd-sync-label">
    <div className="qb-sync-container">
      <img src={images.qbSyncing} alt="quickbooks sync icon" />
    </div>
    <div className="qbd-sync-text">{bodyText}</div>
  </div>
);

const ThirdPartyIntegrationLabel: FunctionComponent<ThirdPartyIntegrationProps> = ({
  qbdStatus,
  isQBDSyncSent,
  invoiceId,
  qbdErrorMessage,
  thirdPartyIntegrationName,
}: ThirdPartyIntegrationProps): JSX.Element => {
  const integrationName = thirdPartyIntegrationName || 'quickbooks-online';
  const thirdPartInegrationInfo = THIRD_PARTY_INTEGRATION[integrationName];

  return (
    <>
      {(qbdStatus === 'synced' || qbdStatus === 'sent') && invoiceId && (
        <SyncSent
          bodyText={invoiceId}
          thirdPartyIntegrationImg={thirdPartInegrationInfo.thirdPartyIntSynced}
          altText={thirdPartInegrationInfo.alertnativeText}
        />
      )}
      {(qbdStatus === 'syncing' || qbdStatus === 'sending' || isQBDSyncSent) &&
        !(qbdStatus === 'internal_error' || qbdStatus === 'setting_error') && <Syncing bodyText="Sync in progress" />}

      {qbdStatus === 'internal_error' && (
        <SyncError
          bodyText="Internal error. Please contact support."
          thirdPartyIntegrationImg={thirdPartInegrationInfo.thirdPartyIntError}
          altText={thirdPartInegrationInfo.alertnativeText}
        />
      )}

      {qbdStatus === 'setting_error' &&
        (process.env.REACT_APP_INTEGRATION_ERROR === 'true' && qbdErrorMessage ? (
          <SyncError
            bodyText={`${qbdErrorMessage} Please contact support.`}
            thirdPartyIntegrationImg={thirdPartInegrationInfo.thirdPartyIntError}
            altText={thirdPartInegrationInfo.alertnativeText}
          />
        ) : (
          <SyncError
            bodyText="Setting error. Please contact support."
            thirdPartyIntegrationImg={thirdPartInegrationInfo.thirdPartyIntError}
            altText={thirdPartInegrationInfo.alertnativeText}
          />
        ))}
    </>
  );
};

export default ThirdPartyIntegrationLabel;
