import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { updateUnfulfilledOrders } from 'domains/Orders/redux/ordersSlice';

import { AppThunk } from 'types/AppThunk';
import BulkAction from 'types/BulkAction';

import { getNotifications, markAsRead } from '../api';

import { Notification } from '../types/Notification';
import { NotificationToaster } from '../types/NotificationToaster';

export interface NotificationContainer {
  isShowingInvoicePrintPreview: boolean;
  notifyUser: NotificationToaster;
  notifications?: Notification[];
  subText?: string;
}

export const initialState: NotificationContainer = {
  isShowingInvoicePrintPreview: false,
  notifyUser: {
    isNotify: false,
    isWarning: false,
    toasterMessage: '',
    subText: undefined,
  },
  notifications: undefined,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetState: () => initialState,
    toggleHasNotifyUser: (state: NotificationContainer, action: PayloadAction<NotificationToaster>) => {
      state.notifyUser = action.payload;

      return state;
    },
    toggleShowingInvoicePrintPreview: (state: NotificationContainer, action: PayloadAction<boolean>) => {
      state.isShowingInvoicePrintPreview = action.payload;

      return state;
    },
    setNotification: (state: NotificationContainer, action: PayloadAction<Notification[]>) => {
      state.notifications = [...(state.notifications || []), ...action.payload];

      return state;
    },
  },
});

export const {
  resetState,
  toggleHasNotifyUser,
  toggleShowingInvoicePrintPreview,
  setNotification,
} = notificationSlice.actions;

export const displayNotification = (): AppThunk => async (dispatch) => {
  const notifications = await getNotifications();

  if (notifications.length === 0) return;

  dispatch(notificationSlice.actions.setNotification(notifications));

  const notification = notifications[notifications.length - 1];

  if (notification.type === BulkAction.PrintInvoices && notification.action === 'print') {
    if (!notification.additionalInfo) throw Error;

    const { orderIds, fulfilledOrderIDs } = notification.additionalInfo;
    const removeOrders = new Set(orderIds);

    // TODO: try to move this over to shared domain when we have a slice there
    // as it is starting to become clear that a shared slice maybe need in the
    // future
    dispatch(updateUnfulfilledOrders(removeOrders));

    let toasterMessage;
    let toasterMessageAddistionalInformation;

    const fulfilledOrderIDsLength = (fulfilledOrderIDs || []).length;
    if (fulfilledOrderIDsLength > 0) {
      if (fulfilledOrderIDsLength > 1) {
        // show fulfilled line, plural
        toasterMessage = `${fulfilledOrderIDsLength} orders have been marked as fulfilled.`;
      } else {
        // show fulfilled line, singular
        toasterMessage = 'An order has been marked as fulfilled.';
      }
      if (orderIds.length > 1) {
        // show fulfilled line, plural
        toasterMessageAddistionalInformation = `${orderIds.length} invoices are ready to be viewed.`;
      } else {
        // show fulfilled line, singular
        toasterMessageAddistionalInformation = 'An invoice is ready to be viewed.';
      }
    } else if (orderIds.length > 1) {
      // hide fulfilled line, plural
      toasterMessage = `${orderIds.length} invoices are ready to be viewed.`;
    } else {
      // hide fulfilled line, singular
      toasterMessage = 'The invoice is ready to be viewed.';
    }

    dispatch(
      notificationSlice.actions.toggleHasNotifyUser({
        isNotify: true,
        toasterMessage,
        toasterMessageAddistionalInformation,
        subText: 'View invoices',
      })
    );

    return;
  }

  if (notification.action === 'error') {
    dispatch(
      notificationSlice.actions.toggleHasNotifyUser({
        isWarning: true,
        isNotify: true,
        toasterMessage: 'Something went wrong',
        subText: undefined,
      })
    );
  }
};

export const performNotificationAction = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState().notification;
    const { notifications }: { notifications: Notification<string[]>[] } = state;

    if (!notifications) return;

    const notification = notifications[notifications.length - 1];

    if (notification.type === BulkAction.PrintInvoices && notification.action === 'print') {
      dispatch(notificationSlice.actions.toggleShowingInvoicePrintPreview(true));
    }

    await markAsRead(notification.notificationId);
  } catch (e) {
    dispatch(
      notificationSlice.actions.toggleHasNotifyUser({
        isWarning: true,
        isNotify: true,
        toasterMessage: 'Something went wrong',
        subText: undefined,
      })
    );
  }
};

export const performCloseAction = (): AppThunk => async (dispatch, getState) => {
  const state = getState().notification;
  const { notifications }: { notifications: Notification<string[]>[] } = state;

  if (!notifications) return;

  const notification = notifications[notifications.length - 1];

  await markAsRead(notification.notificationId);

  dispatch(
    toggleHasNotifyUser({
      isNotify: false,
      isWarning: false,
      toasterMessage: '',
      subText: undefined,
    })
  );
};
