import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'types/AppState';

const selectors = {
  featuresSelector: createSelector(
    ({ features }: AppState) => features,
    (features) => features
  ),
};

export default selectors;
