import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import {
  setOrdersToFulfilledStatus,
  getOrderPackingSlipsPDFs,
  getMasterPickListPDFs,
  getOrders,
  mergeAddonOrders,
} from 'domains/api';
import { bulkGenerateInvoices, bulkMarkInvoicesPaid } from 'domains/shared/api';

import { AppThunk } from 'types/AppThunk';
import BulkAction, { BulkApiOptions } from 'types/BulkAction';
import { NotifyUser } from 'types/Notifiers';

import { OrderListItem, OrderList, OrderListToaster } from '../types';

import OrderStatus from '../types/OrderStatus';
import DateFilter from '../types/DateFilter';
import { AllPreviousOrder } from '../types/Order';

import getStartEndDate from '../lib/dateFilter';

import { fetchOrderDetails } from '../../OrderDetails/redux/orderDetailsSlice';
import { ORDER_LIST_LIMIT } from '../constants';
import { allAreUnfulfillments, fulfillmentOrders, removeNonInvoiceOrders } from '../lib/bulk';

let requestInProgress: CancelTokenSource;

export interface OrderContainer {
  orders: OrderListItem[];
  allPreviousOrder: AllPreviousOrder;
  isLoading: boolean;
  isError: boolean;
  isDoneLoading: boolean;
  searchTerm: string;
  filterStatus: OrderStatus;
  page: number;
  isMergeLoading: boolean;
  isMergeDone: boolean;
  isShowingPackingSlip: boolean;
  notifyUser: OrderListToaster;
  notifyWithWarningsForTable: Omit<NotifyUser, 'isWarning'>;
  totalCount: number;
  newOrdersCount: number;
  packingSlipLink?: string;
  isShowingBulkActionsModal: boolean;
  bulkActionType: BulkAction;
  sort: { column: string; direction: 'asc' | 'desc' };
  dateFilter: DateFilter;
  startDate: string;
  endDate: string;
  displayDateRange: boolean;
  selectedOrders: string[];
  areSelectedOrdersAll: boolean;
  showSelectAllNotification: boolean;
}

export const initialState: OrderContainer = {
  orders: [],
  allPreviousOrder: {},
  isLoading: false,
  isError: false,
  isDoneLoading: false,
  searchTerm: '',
  filterStatus: OrderStatus.Unfulfilled,
  page: 1,
  isMergeLoading: false,
  isMergeDone: false,
  isShowingPackingSlip: false,
  notifyUser: {
    isNotify: false,
    isWarning: false,
    toasterMessage: '',
  },
  notifyWithWarningsForTable: {
    isNotify: false,
    toasterMessage: '',
  },
  totalCount: 0,
  newOrdersCount: 0,
  packingSlipLink: undefined,
  isShowingBulkActionsModal: false,
  bulkActionType: BulkAction.None,
  sort: { column: 'Delivery date', direction: 'asc' },
  dateFilter: DateFilter.All,
  startDate: '',
  endDate: '',
  displayDateRange: false,
  selectedOrders: [],
  areSelectedOrdersAll: false,
  showSelectAllNotification: false,
};

export interface FormValues {
  selectedOrders: string[];
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetState: (state: OrderContainer, action: PayloadAction<{ excludes: Partial<OrderContainer> } | undefined>) => {
      const excludedFields = action.payload ? action.payload.excludes : {};

      return {
        ...initialState,
        ...excludedFields,
      };
    },
    resetStateKeepFilters: (state: OrderContainer) => ({
      ...initialState,
      dateFilter: state.dateFilter,
      startDate: state.startDate,
      endDate: state.endDate,
      searchTerm: state.searchTerm,
      filterStatus: state.filterStatus,
      sort: state.sort,
      selectedOrders: state.selectedOrders,
    }),
    getOrdersStart: (state) => ({
      ...state,
      isLoading: true,
      isError: false,
    }),
    setSelectedOrders: (state, action: PayloadAction<string[]>) => ({
      ...state,
      selectedOrders: action.payload,
    }),
    setAreSelectedOrdersAll: (state, action: PayloadAction<boolean>) => ({
      ...state,
      areSelectedOrdersAll: action.payload,
    }),
    setShowSelectAllNotification: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showSelectAllNotification: action.payload,
    }),
    getOrdersSuccess: (state: OrderContainer, action: PayloadAction<OrderList>) => ({
      ...state,
      orders: action.payload.orders,
      isLoading: false,
      isError: false,
      newOrdersCount: action.payload.newOrdersCount,
      isDoneLoading: state.orders.length + action.payload.orders.length >= action.payload.totalCount,
      totalCount: action.payload.totalCount,
    }),
    getOrdersFailure: (state) => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
    setPage: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    updateSearchTerm: (state: OrderContainer, action) => ({
      ...state,
      searchTerm: action.payload,
      page: 1,
      orders: [],
      isDoneLoading: false,
      areSelectedOrdersAll: false,
      showSelectAllNotification: false,
    }),
    updateFilterStatus: (state: OrderContainer, action) => ({
      ...state,
      filterStatus: action.payload,
      page: 1,
      orders: [],
      isDoneLoading: false,
      areSelectedOrdersAll: false,
      showSelectAllNotification: false,
    }),
    toggleHasNotifyUser: (state: OrderContainer, action: PayloadAction<OrderListToaster>) => {
      state.notifyUser = action.payload;

      return state;
    },
    toggleNotifyWithWarningsForTable: (state: OrderContainer, action: PayloadAction<Omit<NotifyUser, 'isWarning'>>) => {
      state.notifyWithWarningsForTable = action.payload;

      return state;
    },

    updateSort: (state, action) => {
      const sort: { column: string; direction: 'asc' | 'desc' } = {
        column: action.payload.column,
        direction: action.payload.direction || 'desc',
      };

      // we already sort by this column
      // change the direction
      if (state.sort.column === action.payload.column) {
        if (state.sort.direction === 'desc') {
          sort.direction = 'asc';
        } else {
          sort.direction = 'desc';
        }
      }

      return {
        ...state,
        page: 1,
        orders: [],
        isDoneLoading: false,
        sort,
      };
    },
    updateDateFilter: (state: OrderContainer, action: PayloadAction<DateFilter>) => {
      // in case filter is custom we need to get the date from the user
      if (action.payload === DateFilter.Custom) {
        return {
          ...state,
          dateFilter: action.payload,
          displayDateRange: true,
        };
      }

      // prevent reloading data if filter is the same
      if (state.dateFilter === action.payload) {
        return state;
      }

      return {
        ...state,
        ...getStartEndDate(action.payload),
        dateFilter: action.payload,
        displayDateRange: false,
        page: 1,
        orders: [],
        isDoneLoading: false,
        areSelectedOrdersAll: false,
        showSelectAllNotification: false,
      };
    },
    // this reducer will be used when custom is selected
    updateStartEndDate: (state: OrderContainer, action) => ({
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      page: 1,
      orders: [],
      isDoneLoading: false,
      areSelectedOrdersAll: false,
      showSelectAllNotification: false,
      displayDateRange: false,
    }),
    setMergeDone: (state: OrderContainer) => {
      state.isMergeDone = true;
      return state;
    },
    setIsMergeLoading: (state: OrderContainer, action: PayloadAction<boolean>) => {
      state.isMergeLoading = action.payload;

      return state;
    },
    setPackingSlipLink: (state: OrderContainer, action: PayloadAction<string>) => {
      state.packingSlipLink = action.payload;

      return state;
    },
    setPreviousOrders: (state: OrderContainer, action: PayloadAction<AllPreviousOrder>) => {
      state.allPreviousOrder = action.payload;

      return state;
    },
    toggleShowingPackingSlip: (state: OrderContainer, action: PayloadAction<boolean>) => {
      state.isShowingPackingSlip = action.payload;

      return state;
    },
    toggleBulkActionsModal: (state: OrderContainer, action: PayloadAction<boolean>) => ({
      ...state,
      isShowingBulkActionsModal: action.payload,
    }),
    setBulkActionType: (state: OrderContainer, action: PayloadAction<BulkAction>) => ({
      ...state,
      bulkActionType: action.payload,
    }),
  },
});

export const {
  setPage,
  updateSearchTerm,
  updateFilterStatus,
  resetState,
  resetStateKeepFilters,
  setPackingSlipLink,
  toggleShowingPackingSlip,
  toggleHasNotifyUser,
  toggleNotifyWithWarningsForTable,
  toggleBulkActionsModal,
  setBulkActionType,
  updateSort,
  updateDateFilter,
  updateStartEndDate,
  setSelectedOrders,
  setAreSelectedOrdersAll,
  setShowSelectAllNotification,
} = ordersSlice.actions;

export const fetchOrders = (): AppThunk => async (dispatch, getState) => {
  try {
    const sortableColumns: { [k: string]: string } = {
      'Delivery date': 'delivery_date',
      'Order date': 'placed_at',
    };

    const state: OrderContainer = getState().orders;
    dispatch(ordersSlice.actions.getOrdersStart());

    if (requestInProgress) {
      // there is currently a request running, cancel it so we won't get bad data
      requestInProgress.cancel('old request');
    }

    let sortBy = 'delivery_date';
    if (state.sort.column) {
      sortBy = sortableColumns[state.sort.column];
      if (state.sort.direction === 'desc') {
        sortBy = `-${sortBy}`;
      }

      if (!sortBy) {
        sortBy = 'delivery_date';
      }
    }

    requestInProgress = axios.CancelToken.source();
    const orders = await getOrders(
      (state.page - 1) * ORDER_LIST_LIMIT,
      ORDER_LIST_LIMIT,
      state.searchTerm,
      state.filterStatus,
      requestInProgress.token,
      sortBy,
      state.startDate,
      state.endDate
    );
    const newAllPreviousOrder = { ...state.allPreviousOrder };

    orders.orders.forEach((order) => {
      if (!newAllPreviousOrder[order.orderId || '']) {
        newAllPreviousOrder[order.orderId || ''] = order;
      }
    });

    dispatch(ordersSlice.actions.setPreviousOrders(newAllPreviousOrder));

    dispatch(ordersSlice.actions.getOrdersSuccess({ ...orders, resetOrders: false }));
  } catch (error) {
    if (error) {
      if (error.message && error.message === 'old request') {
        // cancelling the request throws an error.
        // in this case there's a newer request we're waiting for
        // so we don't want to show an error to the user
        return;
      }

      dispatch(ordersSlice.actions.getOrdersFailure());
    }

    throw error;
  }
};

// This is a perfect example of a intergration test as there is no action
// however there are a number of intergration test that can be done to test it.
/* istanbul ignore next */
export const submitBulkAction = (
  selectedOrders: string[],
  resetForm: () => void
): AppThunk => async (dispatch, getState): Promise<void> => {
  const {
    bulkActionType,
    areSelectedOrdersAll,
    searchTerm,
    filterStatus,
    startDate,
    endDate,
  } = getState().orders;
  const isBulkPrintAction = [
    BulkAction.PrintPackingSlips,
    BulkAction.PrintMasterPickList,
    BulkAction.PrintMasterPickListExtended,
  ].includes(bulkActionType);

  let newSelectOrders = [...selectedOrders];
  let fulfilledOrders: OrderListItem[] = [];
  let newWindow;
  if (isBulkPrintAction) {
    newWindow = window.open('/orders/loading/true');
  }

  try {
    let apiToCall;
    const options: BulkApiOptions = {};
    switch (bulkActionType) {
      case BulkAction.MarkFulfilled:
        apiToCall = setOrdersToFulfilledStatus;
        break;
      case BulkAction.MarkPaid:
        apiToCall = bulkMarkInvoicesPaid;
        break;
      case BulkAction.PrintInvoices:
        newSelectOrders = removeNonInvoiceOrders(Object.values(getState().orders.allPreviousOrder), newSelectOrders);

        apiToCall = bulkGenerateInvoices;
        fulfilledOrders = fulfillmentOrders(Object.values(getState().orders.allPreviousOrder), newSelectOrders);
        options.fulfilledOrders = fulfilledOrders;
        break;
      case BulkAction.PrintPackingSlips:
        apiToCall = getOrderPackingSlipsPDFs;
        break;
      case BulkAction.PrintMasterPickList:
        apiToCall = getMasterPickListPDFs;
        break;
      case BulkAction.PrintMasterPickListExtended:
        apiToCall = getMasterPickListPDFs;
        options.showExtendedPickList = true;
        break;
      default:
        throw new Error('Incorrect value of bulk action type.');
    }

    // include search filters so backend can fetch all selected orders
    if (
      [
        BulkAction.PrintMasterPickList,
        BulkAction.PrintMasterPickListExtended,
      ].includes(bulkActionType)
    ) {
      if (areSelectedOrdersAll) {
        options.isAllOrders = true;
        options.searchTerm = searchTerm;
        options.filterStatus = filterStatus;
        options.startDate = startDate;
        options.endDate = endDate;
      }
    }

    const res = await apiToCall(newSelectOrders, options);

    const data = await res.data;

    if (BulkAction.PrintInvoices === bulkActionType) {
      resetForm();

      // reset toaster
      dispatch(toggleNotifyWithWarningsForTable(initialState.notifyWithWarningsForTable));

      let toasterMessage = '';

      // all are not fulfilled
      if (allAreUnfulfillments(Object.values(getState().orders.allPreviousOrder), newSelectOrders)) {
        if (newSelectOrders.length === 1) {
          toasterMessage = 'an order will be fulfilled. The invoice is being created and ';
        } else {
          toasterMessage = `${newSelectOrders.length} orders will be fulfilled. Invoices are being created and `;
        }
      } else {
        if (fulfilledOrders.length > 0) {
          if (fulfilledOrders.length === 1) {
            toasterMessage = 'an order will be fulfilled and ';
          } else {
            toasterMessage = `${fulfilledOrders.length} orders will be fulfilled and `;
          }
        }

        if (newSelectOrders.length === 1) {
          toasterMessage += 'an invoice is getting ready to be created. ';
        } else {
          toasterMessage += `${newSelectOrders.length} invoices are getting ready to be created. `;
        }
      }

      toasterMessage += `${
        toasterMessage.charAt(toasterMessage.length - 2) === '.' ? 'Y' : 'y'
      }ou will be notified when completed.`;

      // capitalize first character
      toasterMessage = toasterMessage.charAt(0).toUpperCase() + toasterMessage.slice(1);

      dispatch(
        toggleHasNotifyUser({
          isNotify: true,
          toasterMessage,
          toasterDuration: 10000,
        })
      );
    }

    let url = '';
    if (isBulkPrintAction) {
      url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        })
      );

      if (newWindow) {
        newWindow.location.href = url;

        resetForm();
      }
    }
  } catch (e) {
    if (newWindow) {
      newWindow.close();
    }

    dispatch(
      toggleHasNotifyUser({
        isWarning: true,
        isNotify: true,
        toasterMessage: 'Something went wrong.',
      })
    );

    throw e;
  }
};

export const mergeOrders = (orderUrlsafe: string, shouldFetchOrderDetails: boolean): AppThunk => async (dispatch) => {
  try {
    dispatch(ordersSlice.actions.setIsMergeLoading(true));
    await mergeAddonOrders(orderUrlsafe);
    batch(() => {
      dispatch(ordersSlice.actions.setIsMergeLoading(false));
      dispatch(ordersSlice.actions.setMergeDone());
    });

    if (shouldFetchOrderDetails) {
      dispatch(fetchOrderDetails(orderUrlsafe));
    }
  } catch (error) {
    if (error?.response?.data?.message === 'Can not merge, addon orders not found.') {
      batch(() => {
        dispatch(ordersSlice.actions.setIsMergeLoading(false));
        dispatch(ordersSlice.actions.setMergeDone());
      });

      if (shouldFetchOrderDetails) {
        dispatch(fetchOrderDetails(orderUrlsafe));
      }
    } else {
      dispatch(ordersSlice.actions.setIsMergeLoading(false));
      throw error;
    }
  }
};

export const updateUnfulfilledOrders = (removeOrders: Set<string>): AppThunk => async (dispatch, getState) => {
  const orderState = getState().orders;

  if (orderState.filterStatus !== OrderStatus.Unfulfilled && orderState.filterStatus !== OrderStatus.New) return;

  let { newOrdersCount }: { newOrdersCount: number } = orderState;
  const { orders }: { orders: OrderListItem[] } = orderState;

  const newOrders: OrderListItem[] = orders.filter((order: OrderListItem) => {
    if (!order.orderId) return false;

    if (removeOrders.has(order.orderId)) {
      if (order.fulfillmentStatus?.toLowerCase() === 'placed') {
        newOrdersCount -= 1;
      }

      return false;
    }

    return true;
  });
  const newOrderList: OrderList = {
    limit: 0,
    offset: 0,
    newOrdersCount,
    orders: newOrders,
    totalCount: newOrders.length,
    resetOrders: true,
  };

  dispatch(ordersSlice.actions.getOrdersSuccess(newOrderList));
};
