import React from 'react';
import { isMobile } from 'react-device-detect';
import getVisiblePages from 'domains/shared/utils/pagination';
import Button from '../button/Button';
import Ellipses from './Ellipses';
import PaginationLink from './PaginationLink';

export interface PaginationProps {
  numberOfPages: number;
  page: number;
  setPage: (n: number) => void;
}

const Pagination = ({ numberOfPages, page, setPage }: PaginationProps): JSX.Element => {
  const visibleRadius = 1;

  const visiblePages = getVisiblePages(visibleRadius, page, numberOfPages);
  const pagesComponents = [];

  if (visiblePages[0] >= 2) {
    pagesComponents.push(<PaginationLink key="page-1" index={1} currentPage={page} onChange={setPage} />);

    if (visiblePages[0] !== 2) {
      pagesComponents.push(<Ellipses key="ellipses-1" />);
    }
  }

  visiblePages.map((p) =>
    pagesComponents.push(<PaginationLink key={`page-${p}`} index={p} currentPage={page} onChange={setPage} />)
  );
  if (page <= numberOfPages - visibleRadius - 1 && visiblePages.length < numberOfPages) {
    if (page < numberOfPages - visibleRadius - 1) {
      pagesComponents.push(<Ellipses key="ellipses-2" />);
    }

    pagesComponents.push(
      <PaginationLink key={`page-${numberOfPages}`} index={numberOfPages} currentPage={page} onChange={setPage} />
    );
  }

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  return (
    <section className="pagination">
      <Button disabled={page === 1} onClick={previousPage}>
        Previous
      </Button>

      {!isMobile && <ul className="pagination-list">{pagesComponents}</ul>}

      <Button disabled={numberOfPages === 0 || page === numberOfPages} onClick={nextPage}>
        Next
      </Button>
    </section>
  );
};

export default Pagination;
