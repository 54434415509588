import React, { useEffect } from 'react';
import { Location } from 'history';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';

import useRouter from 'domains/hooks/useRouter';

import { Button, Logo, TextField } from 'domains/shared/components';

import { isAuthenticated } from 'domains/api/apiAuth';

import { initLoggedOutUser as initLoggedOutUserIntercom } from 'integrations/intercom';

import LoginSchema from './LoginSchema';

import { login, identifyUser } from '../redux/sessionSlice';
import selectors from '../redux/sessionSelectors';

import { FRONTEND_NAME } from '../../../constants';

const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter<{}, { from: Location }, {}>();
  const { from } = router.location.state || {
    from: { pathname: '/' },
  };
  const errorMessage = useSelector(selectors.errorMessage);
  const user = useSelector(selectors.user);

  useEffect(() => {
    if (isAuthenticated()) router.replace(from.pathname);
  }, [router, user, from]);

  useEffect(() => {
    initLoggedOutUserIntercom();
  }, []);

  useEffect(() => {
    let query = window.location.search;
    if (query[query.length - 1] === '/') {
      query = query.slice(0, query.length - 1);
    }

    const urlParams = new URLSearchParams(query);
    const firstName = urlParams.get('firstName');
    const lastName = urlParams.get('lastName');
    const email = urlParams.get('email');
    const supplierName = urlParams.get('vendorName');
    dispatch(identifyUser(firstName, lastName, email, supplierName));
  }, [dispatch]);

  return (
    <main role="main" className="login">
      <section className="login-box">
        <header className="login-header">
          <Logo frontendName={FRONTEND_NAME} />

          <h1>Log in</h1>

          <>Continue to your order desk.</>
        </header>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={async ({ email, password }) => {
            dispatch(login(email, password));
          }}
        >
          {({ isSubmitting }) => (
            <Form className="login-body">
              {errorMessage && (
                <div data-testid="login-form-error" className="error">
                  {errorMessage}
                </div>
              )}

              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                disabled={isSubmitting}
                dataTestid="login-email"
              />

              <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                disabled={isSubmitting}
                dataTestid="login-password"
              />

              <div className="login-footer">
                <div className="login-button-container">
                  <Button color="primary" data-testid="login-submit" type="submit" disabled={isSubmitting}>
                    Log in
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </main>
  );
};

export default Login;
