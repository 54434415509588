import React, { FunctionComponent } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export interface PDFDocumentProxy {
  numPages: number;
}

export interface PDFViewerProps {
  filePath: string;
  onLoadSuccess: (pdf: PDFDocumentProxy) => void;
  numPages: number;
}

const PDFViewer: FunctionComponent<PDFViewerProps> = ({ filePath, onLoadSuccess, numPages }: PDFViewerProps) => (
  <Document
    file={filePath}
    options={{
      cMapUrl: 'cmaps/',
      cMapPacked: true,
    }}
    onLoadSuccess={onLoadSuccess}
  >
    {Array.from(new Array(numPages), (el, index) => (
      <Page key={filePath + index} className="pdf-page-container" pageNumber={index + 1} />
    ))}
  </Document>
);

export default PDFViewer;
