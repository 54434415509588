export const capitalize = (word: string): string => word[0].toUpperCase() + word.slice(1);

export const isSupportedBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  const isIOSSafari =
    userAgent.indexOf('applewebkit') > -1 &&
    userAgent.indexOf('mobile') > -1 &&
    userAgent.indexOf('safari') > -1 &&
    userAgent.indexOf('linux') === -1 &&
    userAgent.indexOf('android') === -1 &&
    userAgent.indexOf('chrome') === -1 &&
    userAgent.indexOf('ios') === -1 &&
    userAgent.indexOf('browser') === -1;
  const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(userAgent);

  return isIOSSafari || !isMobile;
};

/*
 * Rounds a number to 2 decimal places and returns it as a string for display.
 *
 * null and undefined are accepted for easier use of the util: to call round(n) instead of round(n || 0)
 * Also, it gived the advantage of having the default display here (0.00)
 *
 * The main issue is that toFixed(2) rounds 0.355 to 0.35 so it cannot be used alone.
 *
 * We need to use toFixed(3) in Math.round() because the multiplication by 100 can result
 * in an infinite number so instead of 0.5 it might return 0.49999999...
 * The toFixed(2) at the end is needed to display a 0 at the end: 0.30 instead of 0.3
 *
 * toLocaleString is used to add a thousand separator (comma)
 */
export const roundNumber = (input?: number | null, decimalPlaces = 2): number => {
  if (!input) {
    return 0.0;
  }
  const denominator = 10 ** decimalPlaces;
  return Math.round(parseFloat((input * denominator).toFixed(3))) / denominator;
};

export const round = (input?: number | null, decimalPlaces = 2): string =>
  Number(roundNumber(input))
    .toLocaleString('en', {
      minimumFractionDigits: decimalPlaces,
    })
    .replace(',', '');

export const getTypeObjectKeys = <T>(obj: T): (keyof T)[] => Object.keys(obj) as (keyof T)[];

export const dateToString = (date: moment.Moment): string => date.format('YYYY-MM-DD');
