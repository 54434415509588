import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader';

import AppHeader from 'domains/AppHeader';
import AppBody from 'domains/AppBody';

import { useAppDispatch } from 'store';

import { getAccountDetails, isAuthenticated } from 'domains/api/apiAuth';
import { activateAfterUserLogin as activateAfterUserLoginIntegration } from 'integrations';

import useChat from 'domains/Inbox/hooks/useChat.hook';
import useFeatures from 'domains/hooks/useFeatures.hook';
import useRouter from 'domains/hooks/useRouter';
import { hide } from 'integrations/intercom';
import Menu from './Menu';

import { updateLoginUser } from '../Session/redux/sessionSlice';

import sessionSelectors from '../Session/redux/sessionSelectors';
import ordersSelectors from '../Orders/redux/ordersSelectors';

const App = (): JSX.Element => {
  const { features } = useFeatures();
  const { getAccessToken, channelsTwilio } = useChat();
  const {
    location: { pathname },
  } = useRouter();
  const dispatch = useAppDispatch();

  const user = useSelector(sessionSelectors.user);
  const supplierName = useSelector(ordersSelectors.supplierNameSelector);

  const isChatRoute = pathname.includes('inbox');

  useEffect(() => {
    const fetchAccount = async () => {
      const loginUser = await getAccountDetails();

      if (loginUser) {
        dispatch(updateLoginUser(loginUser));

        return null;
      }

      return Error('Unable to get user');
    };

    // if user's authenticated they will get their account from the server
    if (isAuthenticated()) {
      fetchAccount();
      if (features.isChatEnabled) {
        getAccessToken();
      }
    }
  }, [dispatch, getAccessToken, features.isChatEnabled]);

  useEffect(() => {
    const activateIntegrations = async () => {
      // simulate fetching the supplier information
      const supplier = {
        id: supplierName || '', // to make each supplier unique as we do not have the ids of the suppliers
        name: supplierName || '',
      };

      // activate needed actions of third-party integrations after user login was updated
      activateAfterUserLoginIntegration(user, supplier);
    };

    // if user's authenticated, third-party integrations will be activated
    if (isAuthenticated()) activateIntegrations();
  }, [user, supplierName]);

  const consumeUnreadMsgs = useCallback(async () => {
    const channelId = localStorage.getItem('channelId') || '';
    if (channelId && channelsTwilio[channelId]) {
      await channelsTwilio[channelId].setAllMessagesConsumed();
    }
    localStorage.removeItem('channelId');
  }, [channelsTwilio]);

  useEffect(() => {
    if (isChatRoute) hide();
    else {
      /** Consume previously selected channel's unread msgs when changing to other routes */
      consumeUnreadMsgs();
    }
  }, [isChatRoute, consumeUnreadMsgs]);

  return (
    <>
      {/** hiding Intercom on chat route */}
      {!isChatRoute && (
        <LiveChatLoaderProvider providerKey={process.env.REACT_APP_INTERCOM_ID || ''} provider="intercom">
          <Intercom color="#38087b" />
        </LiveChatLoaderProvider>
      )}
      <AppHeader />
      <div className="app-body-menu">
        <Menu />
        <AppBody />
      </div>
    </>
  );
};

export default App;
