import React, { ChangeEvent, createRef, useEffect } from 'react';
import { useField } from 'formik';
import cx from 'classnames';

export interface TextareaField {
  id: string;
  name: string;
  disabled?: boolean;
  dataTestid?: string;
  placeholder?: string;
  // allows us to set focus on the element
  isFocused?: boolean;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = ({
  placeholder,
  isFocused = false,
  disabled = false,
  dataTestid = 'textarea',
  ...props
}: TextareaField): JSX.Element => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const textareaRef = createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (isFocused && textareaRef.current) {
      // focus on one of the form element in order to allow the user to hit esc
      // before typing a message
      textareaRef.current.focus();
    }
  }, [props, isFocused, textareaRef]);

  return (
    <div
      className={cx('textarea-field', {
        'inputarea-field-error': hasError,
      })}
    >
      <textarea
        data-testid={dataTestid}
        className={cx('text-input', 'textarea', {
          'textarea-error': hasError,
        })}
        placeholder={placeholder}
        ref={textareaRef}
        disabled={disabled}
        // must be down because it is unknown what Foremilk props it is need
        // same goes with rpops as all Formilk props need some custom props
        // such as id and name
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      {hasError && (
        <div
          data-testid={`${dataTestid}-error`}
          className={cx('errorarea-container', {
            error: hasError,
          })}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Textarea;
