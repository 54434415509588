import React from 'react';
import cx from 'classnames';

import Button from '../button/Button';

export interface DropdownMenuItemImage {
  source: string;
  alternativeText: string;
  width?: string | number;
  height?: string | number;
}

export interface DropdownMenuItemProps {
  text: string | JSX.Element;
  onClick: () => void;
  image?: DropdownMenuItemImage;
  dataTestid?: string;
  disabled?: boolean;
  containerClassName?: string;
  itemClassName?: string;
}

const DropdownMenuItem = ({
  text,
  onClick,
  image = undefined,
  containerClassName = '',
  itemClassName = '',
  dataTestid = 'dropdown-menu-item',
  disabled = false,
}: DropdownMenuItemProps): JSX.Element => {
  const containerClasses = cx('dropdown-menu-item', containerClassName);
  const itemClasses = cx('menu-item', itemClassName);

  return (
    <li className={containerClasses}>
      <Button dataTestid={dataTestid} className={itemClasses} color="link" onClick={onClick} disabled={disabled}>
        <>
          {image && (
            <div className="item-image-container">
              <img src={image.source} alt={image.alternativeText} width={image.width} height={image.height} />
            </div>
          )}
          {text}
        </>
      </Button>
    </li>
  );
};

export default DropdownMenuItem;
