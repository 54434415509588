import React, { FunctionComponent, useState } from 'react';
import cx from 'classnames';

import Button from '../button/Button';
import LinkButton from '../button/LinkButton';

export interface ParentMenuItemButton {
  className?: string;
  dataTestid?: string;
  disabled?: boolean;
}

export interface DropdownMenuProps {
  children: React.ReactNode | React.ReactNode[];

  ParentMenuItem: JSX.Element;
  parentMenuItemButton?: ParentMenuItemButton;
  containerClassName?: string;
  closeOnChildClick?: boolean;
}

const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  children,
  ParentMenuItem,
  parentMenuItemButton = {
    className: '',
    dataTestid: 'dropdown-menu',
    disabled: false,
  },
  containerClassName = '',
  closeOnChildClick = false,
}: DropdownMenuProps): JSX.Element => {
  const containerClasses = cx('custom-dropdown-menu', containerClassName);
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  return (
    <>
      <div className={containerClasses} onMouseLeave={() => setIsShowingMenu(false)}>
        <Button
          dataTestid={parentMenuItemButton?.dataTestid}
          className={cx(parentMenuItemButton?.className)}
          disabled={parentMenuItemButton?.disabled}
          color="link"
          onClick={() => setIsShowingMenu(!isShowingMenu)}
        >
          {ParentMenuItem}
        </Button>

        {isShowingMenu && (
          <LinkButton componentContianer="div" onClick={() => closeOnChildClick && setIsShowingMenu(false)}>
            <ul className="dropdown-menu-item-container">{children}</ul>
          </LinkButton>
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
