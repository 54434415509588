import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'types/AppState';
import { NotifyUser } from 'types/Notifiers';
import { Order } from 'domains/Orders/types';
import { Terms } from 'domains/OrderDetails/enums';

import { OrderDetails } from './orderDetailsSlice';
import { FormValues } from '../types/FormValues';

const selectors = {
  orderDetailContainer: ({ orderDetails }: AppState): OrderDetails => orderDetails,
  orderDetailSelector: createSelector(
    ({ orderDetails }: AppState): Order | null => orderDetails.orderDetails,
    (_orderDetails) => {
      if (!_orderDetails) {
        return null;
      }

      const orderDetails = { ..._orderDetails } as Order;

      const { buyerTerms } = orderDetails;

      orderDetails.isPayOnDelivery = buyerTerms === Terms.NET0;
      orderDetails.isPayOnTerms = !orderDetails.isPayOnDelivery;

      return orderDetails;
    }
  ),
  orderDetailsChanges: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderDetailsChanges,
    (orderDetailsChanges) => orderDetailsChanges
  ),
  deliveryDate: ({ orderDetails }: AppState): string =>
    orderDetails.orderDetailsChanges.deliveryDate ||
    orderDetails.orderDetails?.deliveryDate ||
    moment().format('YYYY-MM-DD'),

  notifyUser: ({ orderDetails }: AppState): NotifyUser => orderDetails.notifyUser,
  isCreating: ({ orderDetails }: AppState): boolean => orderDetails.isEditing && !orderDetails.orderDetails?.orderId,
  isSubmittingConfirmOrder: ({ orderDetails }: AppState): boolean => orderDetails.isSubmittingConfirmOrder,
  isEditing: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isEditing,
    (isEditing) => isEditing
  ),
  isLoadingCustomerDetails: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isLoadingCustomerDetails,
    (isLoadingCustomerDetails) => isLoadingCustomerDetails
  ),
  isLoading: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isLoading,
    (isLoading) => isLoading
  ),
  isError: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isError,
    (isError) => isError
  ),

  // Remove this when all fields are standard fields as Formik is able to handle
  // the changes
  hasThereBeenAnyChanges: ({ orderDetails }: AppState): boolean =>
    orderDetails.orderDetailsChanges.items.length > 0 ||
    orderDetails.orderDetailsChanges.deliveryDate !== undefined ||
    orderDetails.orderDetailsChanges.messageToCustomer !== undefined,

  formikInitialValues: ({ orderDetails }: AppState): FormValues => orderDetails.formikInitialValues,

  showOrderAccept: createSelector(
    ({ orderDetails }: AppState) => orderDetails.showOrderAccept,
    (showOrderAccept) => showOrderAccept
  ),
  showOrderReject: createSelector(
    ({ orderDetails }: AppState) => orderDetails.showOrderReject,
    (showOrderReject) => showOrderReject
  ),
  showOrderChangeConfirmation: createSelector(
    ({ orderDetails }: AppState) => orderDetails.showOrderChangeConfirmation,
    (showOrderChangeConfirmation) => showOrderChangeConfirmation
  ),

  showRemoveItemWarning: ({ orderDetails }: AppState): boolean => orderDetails.showRemoveItemWarning,

  isShowingPackingSlipModal: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isShowingPackingSlipModal,
    (isShowingPackingSlipModal) => isShowingPackingSlipModal
  ),
  isShowingPrintInvoiceConfirmationModalSelector: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isShowingPrintInvoiceConfirmationModal,
    (isShowingPrintInvoiceConfirmationModal) => isShowingPrintInvoiceConfirmationModal
  ),
  isShowingInvoicePreviewSelector: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isShowingInvoicePreview,
    (isShowingInvoicePreview) => isShowingInvoicePreview
  ),
  isQBDSyncRequestSent: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isQBDSyncRequestSent,
    (isQBDSyncRequestSent) => isQBDSyncRequestSent
  ),
  isAddingPayment: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isAddingPaymentMethod,
    (isAddingPaymentMethod) => isAddingPaymentMethod
  ),
  getPaymentSources: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderDetails?.paymentSources,
    (paymentSources) => paymentSources
  ),
  getPaymentTerms: createSelector(
    ({ orderDetails }: AppState) => orderDetails?.paymentTerm,
    (paymentTerm) => paymentTerm
  ),
  paymentMethod: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderDetails?.paymentSourceType,
    (paymentSourceType) => paymentSourceType
  ),
  buyerCountryCode: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderDetails?.buyerShippingAddress,
    (buyerShippingAddress) => buyerShippingAddress?.countryCode?.toUpperCase()
  ),
  isLoadingPaymentMethods: createSelector(
    ({ orderDetails }: AppState) => orderDetails.isLoadingPaymentMethods,
    (isLoadingPaymentMethods) => isLoadingPaymentMethods
  ),
  supplierNote: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderDetails?.supplierNote,
    (supplierNote) => supplierNote
  ),
  shouldRefocusItemSearchFieldSelector: createSelector(
    ({ orderDetails }: AppState) => orderDetails.shouldRefocusItemSearchField,
    (shouldRefocusItemSearchField) => shouldRefocusItemSearchField
  ),
  shouldShowOrderChangeError: createSelector(
    ({ orderDetails }: AppState) => orderDetails.orderChangeError,
    (orderChangeError) => orderChangeError
  ),

  balanceToken: createSelector(
    ({ orderDetails }: AppState) => orderDetails.balanceToken,
    (balanceToken) => balanceToken
  ),
  pendingOrderId: createSelector(
    ({ orderDetails }: AppState) => orderDetails.pendingOrderId,
    (pendingOrderId) => pendingOrderId
  ),
  paymentId: createSelector(
    ({ orderDetails }: AppState) => orderDetails.paymentId,
    (paymentId) => paymentId
  ),
  customItemsSelector: createSelector(
    ({ orderDetails }: AppState) => orderDetails.customItems,
    (customItems) => customItems
  ),
};
export default selectors;
