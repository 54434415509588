import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import User from 'types/User';
import Supplier from 'types/Supplier';
import { version } from '../../package.json';

export const initialize = (): void => {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID || '', {
    release: version,
  });
  setupLogRocketReact(LogRocket);
};

export const identify = (user: User, supplier: Supplier): void => {
  LogRocket.identify(user.id, {
    name: user.name,
    email: user.email,
    supplier: `Supplier ${supplier.name}`,
    supplierId: String(supplier.id),
  });
};
