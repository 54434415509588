import React, { useState } from 'react';

import cx from 'classnames';

import { OptionProps, ValueType, InputActionMeta } from 'react-select';

import { useAppDispatch } from 'store';
import { Button, SearchField } from 'domains/shared/components';
import { OptionType } from 'domains/shared/components/field/Search';

import useRouter from '../../../hooks/useRouter';
import { searchForBuyers } from '../../../api';

import { setUpNewOrder } from '../../../OrderDetails/redux/orderDetailsSlice';

import { CustomerFromAutoComplate } from '../../types/Customer';

export interface CreateOrderProps {
  onToggle: (isShowingCreateOrderModal: boolean) => void;
}

const CreateOrder = ({ onToggle }: CreateOrderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [searchText, setSearchText] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [selectCustomer, setSelectCustomer] = useState<CustomerFromAutoComplate | null>(null);
  const [noOptionsMessage, setNoOptionsMessage] = useState<string | null>(null);

  const OptionComponent = ({ data, isFocused, innerProps, innerRef }: OptionProps<OptionType, false>): JSX.Element => (
    <div
      className={cx('create-order-option-container', {
        'create-order-option-alt-colour': isFocused,
      })}
      ref={innerRef}
      // do to unknown props that React-Select uses to control the select box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      <div className="create-order-option customer-name">{data.name}</div>
      <div className="create-order-option customer-address">{data.address}</div>
    </div>
  );

  const onChange = (selected: ValueType<OptionType, false> | CustomerFromAutoComplate) => {
    if (!selected) return setIsDisable(true);

    const customer = selected as CustomerFromAutoComplate;

    setSearchText(customer.name || customer.address);

    setIsDisable(false);
    setNoOptionsMessage(null);
    setSelectCustomer(customer);

    return undefined;
  };

  const onInputChange = (newValue: string, { action }: InputActionMeta) => {
    if (action !== 'input-change') return;

    if (newValue === '') {
      setSelectCustomer(null);
      setNoOptionsMessage(null);
      setIsDisable(true);
    } else {
      setNoOptionsMessage('No customers found');
    }

    setSearchText(newValue);
  };

  const onBuyerSubmit = () => {
    if (selectCustomer !== null) {
      onToggle(false);

      router.push('/orders/create');

      dispatch(setUpNewOrder(selectCustomer));
    }
  };

  return (
    <section>
      <SearchField
        id="buyer-search"
        placeholder="Select customer"
        noOptionsMessage={noOptionsMessage}
        loadingMessage="Loading customers"
        searchEndpoint={searchForBuyers}
        components={{
          Option: OptionComponent,
        }}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={searchText}
      />

      <div className="create-order-buyer-footer">
        <Button
          color="dismiss"
          className="cancel-button"
          data-testid="create-order-buyer-cancel"
          onClick={() => onToggle(false)}
        >
          Cancel
        </Button>

        <Button data-testid="create-order-buyer-submit" type="submit" disabled={isDisable} onClick={onBuyerSubmit}>
          Continue
        </Button>
      </div>
    </section>
  );
};

export default CreateOrder;
