import apiClient from 'domains/api/apiAuth';
import { ChatChannelBody, ChatTokenBody } from 'domains/Inbox';

const BASE_URL = process.env.REACT_APP_CHAT;

export const getAccessToken = async (data: ChatTokenBody): Promise<{ accessToken: string }> => {
  const response = await apiClient(BASE_URL).post('/token', { ...data });
  return response.data;
};

export const createChannel = async (data: ChatChannelBody): Promise<{ channelSid: string }> => {
  const response = await apiClient(BASE_URL).post(`/v1/channel/${data.orderId}`, { ...data });
  return response.data;
};
