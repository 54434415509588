import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store';
import { InvoicePrintPreview } from 'domains/shared/components';

import { GET_NOTIFICATION_IN_MS } from '../../constants';

import NotificationToaster from './components/NotificationToaster';

import {
  displayNotification,
  performCloseAction,
  performNotificationAction,
  toggleShowingInvoicePrintPreview,
} from './redux/notificationSlice';
import selectors from './redux/notificationSelectors';

const Notification = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const notifyUser = useSelector(selectors.notifyUser);
  const notificationSelector = useSelector(selectors.notification);
  const isShowingInvoicePrintPreview = useSelector(selectors.isShowingInvoicePrintPreview);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(displayNotification());
    }, GET_NOTIFICATION_IN_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  return (
    <section className="notification-container">
      <InvoicePrintPreview
        pdfUrl={notificationSelector?.contentUrl || ''}
        modalState={{
          isShowingModal: isShowingInvoicePrintPreview,
          setIsShowingModal: (isShowingInvoicePrint: boolean) => {
            dispatch(toggleShowingInvoicePrintPreview(isShowingInvoicePrint));
          },
        }}
        dataTestid="notification-invoice-print-preview"
      />

      {notifyUser.isNotify && (
        <NotificationToaster
          isWarning={notifyUser.isWarning}
          isLink={notificationSelector?.contentUrl !== ''}
          notificationCreationTime={moment
            .utc(notificationSelector?.createdAt || new Date())
            .local()
            .format('h:mm a')}
          subText={notifyUser.subText}
          message={
            <>
              {notifyUser.toasterMessage}
              {notifyUser.toasterMessageAddistionalInformation && (
                <>
                  <br />
                  {notifyUser.toasterMessageAddistionalInformation}
                </>
              )}
            </>
          }
          onLinkClose={() => {
            dispatch(performNotificationAction());
          }}
          onClose={() => {
            dispatch(performCloseAction());
          }}
          dataTestid="notification-toaster"
        />
      )}
    </section>
  );
};

export default Notification;
