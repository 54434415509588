import React, { ChangeEvent } from 'react';

import { useField, useFormikContext } from 'formik';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import cx from 'classnames';

export interface NumberProps {
  id: string;
  name: string;
  label: string;
  prefix?: string;
  suffix?: string;
  isShowingLabel?: boolean;
  originalNumber?: number;
  dataTestid?: string;
}

const Number = ({
  label,
  prefix = '',
  suffix = '',
  originalNumber = undefined,
  isShowingLabel = true,
  dataTestid = 'number-field',
  ...props
}: NumberProps): JSX.Element => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.error && meta.touched;
  const numberMask = createNumberMask({
    prefix,
    suffix,
    allowDecimal: true,
  });

  const { setFieldValue } = useFormikContext();
  field.onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numberString: string = e.target.value
      .replace('$', '') // Removes the default value of Masked Input
      .replace(',', '') // Removes this beause numbers use this
      .replace(prefix, '')
      .replace(suffix, '');

    if (numberString === '_') {
      helpers.setValue('');

      return;
    }

    helpers.setValue(numberString);
  };

  field.onBlur = () => {
    // convert amount back to number so the user can go back to the default state
    setFieldValue(props.id, window.Number(field.value));
  };

  return (
    <div
      className={cx('input-text-field', 'number-container', {
        'input-text-field-error': hasError,
        'no-label-input': !isShowingLabel,
      })}
    >
      <MaskedInput
        data-testid={dataTestid}
        className={cx('text-input', {
          'text-input-error': hasError,
        })}
        mask={numberMask}
        // must be down because it is unknown what Foremilk props it is need
        // same goes with rpops as all Formilk props need some custom props
        // such as id and name
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      {isShowingLabel && (
        <label className="text-input-label" htmlFor={field.name}>
          {label}
        </label>
      )}

      {hasError && (
        <div
          data-testid={`${dataTestid}-error`}
          className={cx('error-text-container', {
            error: hasError,
          })}
        >
          {meta.error}
        </div>
      )}

      {!hasError && originalNumber && (
        <div className="original-number">
          {prefix}
          <span>{originalNumber}</span>
          {suffix}
        </div>
      )}
    </div>
  );
};

export default Number;
