const getVisiblePages = (visibleRadius: number, currentPage: number, maxPages: number): number[] => {
  const visiblePages = [];

  let start = currentPage - visibleRadius;
  let end = currentPage + visibleRadius;

  if (start < 1) {
    start = 1;
    end = start + visibleRadius * 2;
  }

  if (end > maxPages) {
    start = maxPages - visibleRadius * 2;
    end = maxPages;
  }

  if (visibleRadius * 2 + 1 > maxPages) {
    start = 1;
    end = maxPages;
  }

  for (let i = start; i <= end; i += 1) {
    visiblePages.push(i);
  }

  return visiblePages;
};

export default getVisiblePages;
