import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import images from 'config';
import DropdownMenu from '../DropdownMenu/index';
import DropdownMenuItem from '../DropdownMenu/DropdownMenuItem';

export interface PrintActionsProps {
  disableButton?: boolean;
  disabledInvoice?: boolean;
  disabledPackingSlip?: boolean;
  disabledMasterPickList?: boolean;
  isLastItem?: boolean;
  printInvoice: () => void;
  printPackingSlip: () => void;
  printMasterPickList?: () => void;
  printMasterPickListExtended?: () => void;
  additionalLabel?: JSX.Element;
}

const PrintActions: FunctionComponent<PrintActionsProps> = ({
  disableButton = false,
  disabledInvoice = false,
  disabledPackingSlip = false,
  disabledMasterPickList = false,
  isLastItem = false,
  printInvoice,
  printPackingSlip,
  printMasterPickList,
  printMasterPickListExtended,
  additionalLabel,
}: PrintActionsProps): JSX.Element => (
  <div className={cx('bulk-actions-container', { 'last-item': isLastItem })}>
    <DropdownMenu
      ParentMenuItem={
        <>
          <img className="order-icon-image" src={images.printIcon} alt="print" width={13} height={21} />
          <div className="order-icon-text">Print</div>
          <img className="dropdown-icon" alt="print dropdown icon" src={images.dropdownIcon} width={6} height={21} />
        </>
      }
      parentMenuItemButton={{
        className: 'print-name-container',
        disabled: disableButton,
      }}
    >
      <DropdownMenuItem
        dataTestid="action-icon-print-packing-invoice"
        text={<>Invoice{additionalLabel}</>}
        onClick={printInvoice}
        disabled={disabledInvoice}
      />
      <DropdownMenuItem
        dataTestid="action-icon-view-packing-slip"
        text={<>Packing slip{additionalLabel}</>}
        onClick={printPackingSlip}
        disabled={disabledPackingSlip}
      />
      {printMasterPickList && (
        <DropdownMenuItem
          dataTestid="action-icon-view-master-picklist"
          text="Pick list"
          onClick={printMasterPickList}
          disabled={disabledMasterPickList}
        />
      )}
      {printMasterPickListExtended && (
        <DropdownMenuItem
          dataTestid="action-icon-view-master-picklist-extended"
          text="Extended pick list"
          onClick={printMasterPickListExtended}
          disabled={disabledMasterPickList}
        />
      )}
    </DropdownMenu>
  </div>
);

export default PrintActions;
