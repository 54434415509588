/* eslint-disable @typescript-eslint/no-explicit-any */
import { logException } from 'domains/shared/lib/logger';
import { Channel } from 'twilio-chat/lib/channel';
import { Message } from 'twilio-chat/lib/message';
import { ChannelMembers, InboxChannelItem, InboxMessage } from '../types';

/**
 * A method to find unread messages
 *
 * @param {Array} channels Array of Chat channels
 */
export const calculateUnreadChatMessages = (channels: InboxChannelItem = {}): number => {
  if (Object.keys(channels).length === 0) return 0;

  let unreadMessagesCount = 0;

  // Iterates through each channel and finds how many messages are not yet read by the user
  Object.entries(channels).forEach(([, { lastConsumedMessageIndex, lastMessageIndex }]) => {
    unreadMessagesCount += lastMessageIndex - (lastConsumedMessageIndex || 0);
  });

  return unreadMessagesCount;
};

/**
 * Prepare the Chat messages by reading metadata info when required
 *
 * @param {Array} messages Array of Chat messages
 */
export const prepareMessages = async (messages: Message[]): Promise<InboxMessage[]> => {
  let url = '';
  return Promise.all(
    messages.map(async (message) => {
      const data = {
        type: message.type,
        attributes: message.attributes,
        author: message.author,
        timestamp: message.dateCreated.toISOString(),
        body: message.body,
        url,
        filename: '',
        contentType: '',
        sid: message.sid,
      };
      if (message.type === 'media') {
        const chatImageUrl = await message.media.getContentTemporaryUrl();
        try {
          // As Twilio Chat media message url is temporary (valid for 300 seconds only)
          // We are here reading the dataUrl of image and storing locally in store.
          const req = await fetch(chatImageUrl);
          const blob = await req.blob();
          const reader = new FileReader();
          url = await new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(new Error('cannot be downloaded'));
          });
        } catch (error) {
          logException(error);
        }

        data.contentType = message.media.contentType;
        data.filename = message.media.filename;
        data.url = url;
      }
      return data;
    })
  );
};

/**
 * Prepare the Channel Payload
 * @param {Channel} channel
 */
export const getChannelPayload = async (
  channel: Channel,
  channelMembers: ChannelMembers
): Promise<InboxChannelItem | null> => {
  try {
    const {
      buyer_id: buyerId,
      order_number: orderNumber,
      order_place_at: orderPlaceAt,
      closed,
      order_id: orderId,
      buyer_name: buyerName,
    } = await channel.getAttributes();

    const { dateCreated = '', index: lastMessageIndex = 0 } = channel.lastMessage || {};

    const { sid, lastConsumedMessageIndex, dateCreated: channelCreated } = channel;

    let timestamp = '';

    if (dateCreated) {
      timestamp = dateCreated.toISOString();
    } else {
      timestamp = channelCreated.toISOString();
    }

    const payload = {
      [sid]: {
        timestamp,
        lastMessageIndex,
        lastConsumedMessageIndex,
        buyerId,
        orderNumber,
        orderPlaceAt,
        closed,
        orderId,
        buyerName,
        channelMembers,
      },
    };
    return payload;
  } catch (e) {
    logException(e);
    return null;
  }
};

/**
 * Sort method to arrage Chat channel list
 *
 * @param {Object} a First Chat channel object
 * @param {Object} b Second Chat channel object
 */
export const sortChannelListHandler = (a: [string, any], b: [string, any]): number => {
  const [, { timestamp: aLastUpdated }] = a;
  const [, { timestamp: bLastUpdated }] = b;

  const date1 = new Date(aLastUpdated).getTime();
  const date2 = new Date(bLastUpdated).getTime();

  return date2 - date1;
};

/**
 * Sort method to arrage Chat channel member
 *
 * @param {Object} a First Chat channel member
 * @param {Object} b Second Chat channel member
 */
export const sortChannelMembers = (a: [string, any], b: [string, any]): number => {
  const [, name1 = ''] = a;
  const [, name2 = ''] = b;

  if (name1.toLowerCase() < name2.toLowerCase()) {
    return -1;
  }
  if (name1.toLowerCase() > name2.toLowerCase()) {
    return 1;
  }
  return 0;
};
