import React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { useAppDispatch } from 'store';
import {
  Button,
  DropdownMenu as UserDropDown,
  DropdownMenuItem,
  LinkButton,
  Logo,
  Modal,
} from 'domains/shared/components';
import { resetState as orderDetailsResetState } from 'domains/OrderDetails/redux/orderDetailsSlice';
import cx from 'classnames';

import images from 'config';

import { setIsShowingDiscardOrder, setIsShowingCreateOrder, setMobileMenuOpen } from 'store/appSlice';
import appSelectors from 'store/appSelectors';

import invoiceSelectors from '../Invoice/redux/invoiceSelectors';
import orderDetailsSelectors from '../OrderDetails/redux/orderDetailsSelectors';

import CreateOrder from '../Orders/components/create/Create';

import { logout } from '../Session/redux/sessionSlice';
import sessionSelectors from '../Session/redux/sessionSelectors';
import Notification from '../Notification/notificationContainer';

import useRouter from '../hooks/useRouter';

import { FRONTEND_NAME } from '../../constants';

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { firstName, lastName, name } = useSelector(sessionSelectors.user);
  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : '';

  const isMenuOpen = useSelector(appSelectors.isMenuOpen);
  const isMobileMenuOpen = useSelector(appSelectors.isMobileMenuOpen);
  const isShowingCreateOrder = useSelector(appSelectors.isShowingCreateOrder);
  const isEditingInvoice = useSelector(invoiceSelectors.isEditing);
  const isEditingOrder = useSelector(orderDetailsSelectors.isEditing);
  const isOrderDetails = router.pathname.startsWith('/orders/');
  const isInvoicesDetails = router.pathname.startsWith('/invoices/');

  const onCreateOrderClick = () => {
    if (
      router.pathname.endsWith('/create') ||
      (isOrderDetails && isEditingOrder) ||
      (isInvoicesDetails && isEditingInvoice)
    ) {
      dispatch(setIsShowingDiscardOrder(true));
    }

    dispatch(setIsShowingCreateOrder(true));
  };
  const onCreateOrderToggle = (isShowingCreateOrderModal: boolean) => {
    dispatch(orderDetailsResetState());
    dispatch(setIsShowingCreateOrder(isShowingCreateOrderModal));
  };

  return (
    <div className="app-header">
      <div className="header-left" />
      <div className="header-left-mobile">
        <LinkButton
          dataTestid="mobile-menu"
          className="toggle-icon"
          onClick={() => dispatch(setMobileMenuOpen(!isMobileMenuOpen))}
        >
          <img src={images.hambuergerIcon} alt="menu" className="hamburgerIcon" />
        </LinkButton>
        <Link to="/">
          <Logo frontendName={FRONTEND_NAME} />
        </Link>
      </div>
      <div className="header-middle">
        <span
          className={cx('welcome-text', {
            open: !isMenuOpen,
            close: isMenuOpen,
          })}
        >
          Welcome back, {name}
        </span>
      </div>

      <div className="header-right">
        <Button color="secondary" className="create-order-btn" onClick={onCreateOrderClick}>
          Create order
        </Button>

        <UserDropDown
          ParentMenuItem={<>{initials}</>}
          parentMenuItemButton={{
            className: 'user-dropdown-menu-avatar',
            dataTestid: 'user-dropdown-menu-avatar',
          }}
          containerClassName="user-dropdown"
        >
          <DropdownMenuItem
            dataTestid="user-dropdown-menu-logout"
            itemClassName="logout"
            image={{
              source: images.logoutIcon,
              alternativeText: 'logout',
            }}
            text="Logout"
            onClick={() => dispatch(logout(router.push))}
          />
        </UserDropDown>

        <Notification />
      </div>

      <Modal
        headerText="Create order"
        showDisplayingModal={isShowingCreateOrder}
        onToggle={onCreateOrderToggle}
        dataTestid="create-order-modal"
        className="create-order-modal"
      >
        <CreateOrder onToggle={onCreateOrderToggle} />
      </Modal>
    </div>
  );
};

export default Header;
