import React from 'react';

import { ModalState } from 'domains/shared/components/modal/types/ModalState';
import Modal from 'domains/shared/components/modal/Modal';
import Button from 'domains/shared/components/button/Button';

export interface DiscardText {
  forCreate?: string;
  forEdit?: string;
}

export interface DiscardFormProps {
  onConfirm: () => void;
  onCancel: (isShowingDiscard: boolean) => void;
  state: ModalState;
  isCreating: boolean;
  discardText: DiscardText;
}

const DiscardForm = ({ onConfirm, onCancel, state, isCreating, discardText }: DiscardFormProps): JSX.Element => {
  const defaultText = { forCreate: 'form', forEdit: 'changes' };
  const changesOrCreateTitle = isCreating
    ? discardText.forCreate || defaultText.forCreate
    : discardText.forEdit || defaultText.forEdit;
  const changesOrCreate = isCreating ? `this ${changesOrCreateTitle}` : `these ${changesOrCreateTitle}`;

  return (
    <Modal
      headerText={`Discard ${changesOrCreateTitle}`}
      bodyText={`Are you sure you want to discard ${changesOrCreate}?`}
      showDisplayingModal={state.isShowingModal}
      onToggle={state.setIsShowingModal}
      dataTestid="discard-form-modal"
    >
      <footer className="discard-form-footer">
        <div className="cancel-button-continer">
          <Button color="dismiss" blockStyle data-testid="discard-form-cancel" onClick={() => onCancel(false)}>
            Continue editing
          </Button>
        </div>

        <div>
          <Button data-testid="discard-form-submit" type="submit" blockStyle onClick={() => onConfirm()}>
            Discard {changesOrCreateTitle}
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default DiscardForm;
