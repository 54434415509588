import React, { useState } from 'react';
import cx from 'classnames';

import images from 'config';

import { LinkButton, Toaster } from 'domains/shared/components';

export interface NotificationToasterAction {
  action: NotificationAction;
}

export interface NotificationToasterProps {
  message: string | React.ReactElement;
  onClose: () => void;
  notificationCreationTime: string;
  subText?: string;
  isLink?: boolean;
  onLinkClose?: () => void;
  isWarning?: boolean;
  dataTestid?: string;
}

const NotificationToaster = ({
  message,
  onClose,
  notificationCreationTime,
  onLinkClose = undefined,
  subText = undefined,
  isLink = false,
  isWarning = false,
  dataTestid = 'notification-toaster',
}: NotificationToasterProps): JSX.Element => {
  const [hideToaster, setHideToaster] = useState(false);

  const AdditionalInfo = (
    <div className="notification-toaster">
      <LinkButton
        dataTestid={`button-${dataTestid}`}
        onClick={() => {
          if (onLinkClose) {
            onLinkClose();
            setHideToaster(true);
          }
        }}
        className={cx('sub-text', { link: isLink })}
        disabled={!isLink}
        componentContianer="div"
      >
        {subText || ' '}
      </LinkButton>

      {notificationCreationTime}
    </div>
  );

  return (
    <Toaster
      isWarning={isWarning}
      additionalInfo={AdditionalInfo}
      message={message}
      icon={{
        src: images.notifyToasterGreySquareIcon,
        alt: 'grey square',
      }}
      dataTestid={dataTestid}
      shouldHideToaster={hideToaster}
      shouldAutoClose={false}
      onClose={onClose}
    />
  );
};

export default NotificationToaster;
