import React from 'react';

interface Address {
  line1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

interface AddressProps {
  address: Address;
}

const Address = ({ address }: AddressProps): JSX.Element => (
  <>
    {address?.line1}
    <br />
    {`${address?.city} ${address?.state} ${address?.zipCode}`}
    <br />
    {address?.country}
  </>
);

export default Address;
