import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { setMenuOpen } from 'store/appSlice';
import selectors from 'store/appSelectors';

import cx from 'classnames';

import useRouter from 'domains/hooks/useRouter';

import images from 'config';
import useChatChannel from 'domains/Inbox/hooks/useChatChannel.hook';
import useFeatures from 'domains/hooks/useFeatures.hook';
import { INBOX_MENU, ORDERS_MENU, SETTINGS_MENU, FRONTEND_NAME } from '../../../constants';

import MobileMenu from '../../AppBody/MobileMenu';
import { Logo } from '../../shared/components';

const APP_MENU_ITEMS = [
  {
    id: 100,
    name: ORDERS_MENU,
    displayName: 'Orders',
    icon: images.ordersIcon,
    alt: 'orders icon',
  },
  {
    id: 102,
    name: SETTINGS_MENU,
    displayName: 'Settings',
    icon: images.settingsIcon,
    alt: 'settings icon',
  },
];

const Menu = (): JSX.Element => {
  const {
    channel: { unreadMsgsCount },
  } = useChatChannel();
  const isMenuOpen = useSelector(selectors.isMenuOpen);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {
    features: { isChatEnabled },
  } = useFeatures();
  const menuCollapsed = localStorage.getItem('menuCollapsed');
  let menuState;
  if (menuCollapsed === 'true') {
    menuState = true;
  } else {
    menuState = false;
  }
  const [isExpanded, setIsExpanded] = useState(menuState);
  useEffect(() => {
    if (localStorage.getItem('menuCollapsed') === 'false') {
      setIsExpanded(false);
      dispatch(setMenuOpen(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isChatEnabled) {
      const inboxMenu = APP_MENU_ITEMS.find((menu) => menu.name === INBOX_MENU);
      if (!inboxMenu) {
        APP_MENU_ITEMS.splice(1, 0, {
          id: 101,
          name: INBOX_MENU,
          displayName: 'Inbox',
          icon: images.inboxIcon,
          alt: 'inbox icon',
        });
      }
    }
  }, [isChatEnabled]);

  const isCurrentMenu = (name: string): boolean => {
    if (name === 'orders') {
      return (
        // in case the user is on the invoices path
        // we want the order menu item to be selected
        router.pathname.includes(name) || router.pathname.includes('invoices')
      );
    }

    return router.pathname.includes(name);
  };

  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem('menuCollapsed', 'false');

      return;
    }
    setIsExpanded(true);
    localStorage.setItem('menuCollapsed', 'true');
  };
  const toggleCollapseMenu = (bool: boolean) => {
    dispatch(setMenuOpen(bool));
    handleToggler();
  };
  return (
    <div className="app-body-menu">
      {/* LEFT SIDE */}
      <div className="left-container-and-button">
        <div
          className={cx('left-container', {
            mini: isMenuOpen,
            full: !isMenuOpen,
          })}
        >
          {isMenuOpen && (
            <Link to="/">
              <img src={images.condensedLogo} alt="condensedLogo" className="condensedLogo" />
            </Link>
          )}{' '}
          {!isMenuOpen && (
            <Link to="/">
              <Logo frontendName={FRONTEND_NAME} />
            </Link>
          )}
          {APP_MENU_ITEMS.map((item) => (
            <Link
              key={item.id}
              to={`/${item.name}`}
              className={cx('menu-item', {
                'selected-menu-item': isCurrentMenu(item.name),
              })}
            >
              <img src={item.icon} alt={item.name} />
              {/**
               * For menu item 101 i.e. chat, show unread msgs if unreadMsgCount > 0
               */}
              {item.name === INBOX_MENU && !!unreadMsgsCount && <div className="oval">{unreadMsgsCount}</div>}

              {!isMenuOpen && (
                <div
                  className={cx('text', {
                    'selected-text': isCurrentMenu(item.name),
                  })}
                >
                  {item.displayName}
                </div>
              )}
            </Link>
          ))}
          <div className="collapse-order-menu">
            {isMenuOpen && (
              <button className="collapse-order-menu-button-close" onClick={() => toggleCollapseMenu(false)} />
            )}

            {!isMenuOpen && (
              <button className="collapse-order-menu-button-open" onClick={() => toggleCollapseMenu(true)} />
            )}
          </div>
        </div>
      </div>
      <MobileMenu menuItems={APP_MENU_ITEMS} isMenuOpen={isMenuOpen} isCurrentMenu={isCurrentMenu} />
      {/* RIGHT SIDE */}
    </div>
  );
};

export default Menu;
