import { Invoice } from 'domains/Invoice/types';
import { Order } from 'domains/Orders/types';

import apiClient from '../apiAuth';

const invoice = {
  fetchInvoice: async (invoiceNumber: string): Promise<Invoice> => {
    const response = await apiClient().get(`/invoices/${invoiceNumber}`);

    return response.data;
  },
  markInvoicePaid: async (invoiceNumber: string, lastFetchTimestamp: string): Promise<Order> => {
    const response = await apiClient().post(`/invoices/${invoiceNumber}/pay?lft=${lastFetchTimestamp || 0}`);

    return response.data;
  },
  adjustInvoice: async (updatedInvoice: Invoice, lastFetchTimestamp: string): Promise<Invoice> => {
    const response = await apiClient().post(
      `/credit-notes?invoicenumber=${updatedInvoice.invoiceNumber}&lft=${lastFetchTimestamp || 0}`,
      updatedInvoice
    );

    return response.data;
  },
  updateSupplierNoteForInvoice: async (
    urlsafe: string,
    lastFetchTimestamp: string,
    supplierNote: string
  ): Promise<Order> => {
    const response = await apiClient().put(`/invoices/${urlsafe}?lft=${lastFetchTimestamp || 0}`, {
      supplierNote,
    });

    return response.data;
  },
  isInvoiceUpdated: async (invoiceNumber: string, lastFetchTimestamp: string): Promise<string> => {
    const response = await apiClient()(`/invoices/${invoiceNumber}/validate?lft=${lastFetchTimestamp || 0}`);

    return response.data;
  },
};

export default invoice;
