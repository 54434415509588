import apiClient from 'domains/api/apiAuth';
import { OrderCustomItem } from 'domains/Orders/types';
import { CustomProduct } from 'domains/Orders/types/OrderCustomItem';

import { logMessage } from 'domains/shared/lib/logger';

const BASE_URL = process.env.REACT_APP_CATALOG;

const getCustomItems = async (orderUrlsafeKey: string, buyerUrlsafeKey: string): Promise<OrderCustomItem[]> => {
  const response = await apiClient(BASE_URL).get(
    `/buyers/${buyerUrlsafeKey}/customProducts/?orderUrlsafeKey=${orderUrlsafeKey}`
  );

  const products: CustomProduct[] = response?.data?.customProducts;

  if (!products) {
    logMessage('fetching custom items from be-catalog returned data without items');
  }

  const legacyProducts: OrderCustomItem[] = [];

  for (let i = 0; i < products.length; i += 1) {
    const product: CustomProduct = products[i];
    const legacyProduct: OrderCustomItem = {
      id: product.id,
      name: product.productName,
      externalRef: product.packageSku,
      packaging: product.packageDescription,
      quantity: product.quantity,
      price: product.pricePerUnit,
    };

    legacyProducts.push(legacyProduct);
  }

  return legacyProducts;
};

export default getCustomItems;
