import React from 'react';
import { OrderCustomItem } from 'domains/Orders/types';
import Skeleton from '../skeleton';
import CustomItem from './CustomItem';

interface CustomItemsProps {
  customItems: OrderCustomItem[];
  isLoading: boolean;
}

const CustomItems = ({ customItems, isLoading }: CustomItemsProps): JSX.Element => {
  if (isLoading) {
    return (
      <>
        <div className="order-info">
          <Skeleton width="200px" height="18px" />
        </div>
        <div className="order-info">
          <Skeleton width="200px" height="18px" />
        </div>
        <div className="order-info">
          <Skeleton width="200px" height="18px" />
        </div>
      </>
    );
  }

  return (
    <>
      {customItems?.length > 0 && <div>Please add the following product(s) to the order</div>}

      {customItems?.map((orderItem: OrderCustomItem) => (
        <CustomItem key={`${orderItem.externalRef}_${orderItem.name}`} customItem={orderItem} />
      ))}
    </>
  );
};

export default CustomItems;
