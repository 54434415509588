import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'types/AppState';

import { Notification } from '../types/Notification';
import { NotificationToaster } from '../types/NotificationToaster';

const selectors = {
  notification: ({ notification }: AppState): Notification | undefined => {
    if (notification.notifications) {
      return notification.notifications[notification.notifications.length - 1];
    }

    return undefined;
  },
  isShowingInvoicePrintPreview: createSelector(
    ({ notification }: AppState) => notification.isShowingInvoicePrintPreview,
    (isShowingInvoicePrintPreview) => isShowingInvoicePrintPreview
  ),
  notifyUser: ({ notification }: AppState): NotificationToaster => notification.notifyUser,
};

export default selectors;
