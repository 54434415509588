import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { UserFromServerPayload } from 'types/User';

import { logException } from 'domains/shared/lib/logger';

import { AuthResponse } from '../Session/types/Auth';

const SESSION_KEY_NAME = 'sportal_skey';
const ACCESS_TOKEN_NAME = 'sportal_at';
const SESSION_EXPIRY_NAME = 'sportal_se';
const REFRESH_TOKEN_NAME = 'sportal_rt';
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;
let refreshPromise: Promise<AxiosResponse> | null = null;

export const isAuthenticated = (): boolean => Boolean(localStorage.getItem(ACCESS_TOKEN_NAME));

// Check if an access token already exists and has not expired
const isAccessTokenValid = (config: AuthResponse) => {
  if (!config.accessToken) {
    return false;
  }

  // Wrap in try/catch because jwtDecode throws exception on bad token
  try {
    const { exp } = jwtDecode(config.accessToken);

    if (moment.unix(exp - 60).isBefore(moment())) {
      return false;
    }
  } catch (error) {
    logException(error, 'isAccessTokenValid');
    return false;
  }

  return true;
};

// Check if a valid user session exists with a sessionKey and refreshToken
const isSessionAvailable = (config: AuthResponse) => {
  const { sessionKey, refreshToken, expiresAt } = config;

  if (!sessionKey || !refreshToken || !expiresAt) {
    return false;
  }

  return true;
};

export const createSession = (responseFromAuthServer: AuthResponse): void => {
  // Assign sessionKey, refreshToken and session expirty to localStorage
  // Assign accessToken to global window variable.
  window.localStorage.setItem(SESSION_KEY_NAME, responseFromAuthServer.sessionKey);
  window.localStorage.setItem(ACCESS_TOKEN_NAME, responseFromAuthServer.accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_NAME, responseFromAuthServer.refreshToken);
  window.localStorage.setItem(SESSION_EXPIRY_NAME, responseFromAuthServer.expiresAt.toString());
};

export const deleteSession = (): void => {
  window.localStorage.removeItem(SESSION_KEY_NAME);
  window.localStorage.removeItem(ACCESS_TOKEN_NAME);
  window.localStorage.removeItem(REFRESH_TOKEN_NAME);
  window.localStorage.removeItem(SESSION_EXPIRY_NAME);
};

export const refreshSession = async (sessionId: string, refreshToken: string): Promise<AxiosResponse> =>
  axios.post(`${AUTH_BASE_URL}/sessions/refresh`, {
    session_id: sessionId,
    refresh_token: refreshToken,
  });

// If the session has expired, a new session is requested from the server and a new
// access token is returned.  For multiple request within a promise, only one request
// is made to start a new session and the refreshPromise parameter is checked
// after that to avoid starting another session everytime
const refreshAccessToken = async (config: AuthResponse) => {
  try {
    if (!isSessionAvailable(config)) {
      throw Error();
    }

    if (!refreshPromise) {
      refreshPromise = refreshSession(config.sessionKey, config.refreshToken);
    }

    const response = await refreshPromise;
    if (response && response.data) {
      config.accessToken = response.data.accessToken;
      config.refreshToken = response.data.refreshToken;
      config.expiresAt = response.data.expiresAt;

      createSession(response.data);
    }
  } catch (error) {
    // When an error occurs, remove all session info from local storage and redirect to signin
    deleteSession();

    // TODO: possible race condition, investigate how to remove reload
    window.location.reload();

    logException(error, 'refreshAccessToken');
    throw error;
  } finally {
    refreshPromise = null;
  }
};

// Add access token to all request headers (Authorization Bearer)
// Retrieve the access token
const getAccessToken = async (config: AuthResponse) => {
  try {
    // If the session exists and hasn't expired, use existing access token
    if (isAccessTokenValid(config)) {
      return config.accessToken;
    }

    // If the session exists and has expired, re-generate the access token using
    // the sessionKey and refreshToken
    await refreshAccessToken(config);

    return config.accessToken;
  } catch (error) {
    logException(error, 'getAccessToken');
    throw error;
  }
};

export const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
  const clientReposne = { ...response };

  try {
    if (clientReposne.data && clientReposne.request?.responseType !== 'blob') {
      clientReposne.data = camelCaseKeys(clientReposne.data, { deep: true });
    }
  } catch (error) {
    logException(error, 'responseInterceptor');
  }

  return clientReposne;
};

export const requestAuthInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    if (isAuthenticated()) {
      const sessionKey = window.localStorage.getItem(SESSION_KEY_NAME) || '';
      const localStorageAccessToken = window.localStorage.getItem(ACCESS_TOKEN_NAME) || '';
      const expiresAt = Number(window.localStorage.getItem(SESSION_EXPIRY_NAME)) || 0;
      const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_NAME) || '';

      const accessToken = await getAccessToken({
        sessionKey,
        accessToken: localStorageAccessToken,
        expiresAt,
        refreshToken,
      });

      if (config.params) {
        config.params = snakeCaseKeys(config.params);
      }

      if (config.data) {
        config.data = snakeCaseKeys(config.data, { deep: true });
      }

      config.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  } catch (error) {
    logException(error, 'requestAuthInterceptor');
  }

  // We need to return config for API calls to continue working
  // Just throwing an error will cause all endpoints to fail.
  return config;
};

const API_AXIOS = (baseURL = process.env.REACT_APP_API_ENDPOINT, data = {}): AxiosInstance => {
  if (process.env.NODE_ENV === 'test') return axios;

  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    let configParam = { ...instance.defaults, ...config };

    if (Object.keys(data).length > 0) {
      // in case we sent some data not from the http method we want to add it here
      // logout is an example
      configParam = { ...configParam, data };
    }

    return requestAuthInterceptor(configParam);
  });

  instance.interceptors.response.use(responseInterceptor);

  return instance;
};

export default API_AXIOS;

export const API_AXIOS_BILLING = (baseURL = process.env.REACT_APP_BILLING_SUPPLIER, data = {}): AxiosInstance => {
  if (process.env.NODE_ENV === 'test') return axios;

  const billingInstance = axios.create({ baseURL });

  billingInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    let configParam = { ...billingInstance.defaults, ...config };

    if (Object.keys(data).length > 0) {
      // in case we sent some data not from the http method we want to add it here
      // logout is an example
      configParam = { ...configParam, data };
    }

    return requestAuthInterceptor(configParam);
  });

  billingInstance.interceptors.response.use(responseInterceptor);

  return billingInstance;
};

export const getAccountEmail = (): string => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME) || '';
  let email = '';
  if (accessToken) {
    const { sub } = jwtDecode(accessToken);
    email = sub;
  }

  return email;
};

export const getAccountDetails = async (): Promise<UserFromServerPayload | null> => {
  if (!isAuthenticated()) return null;

  const urlSafeEmail = encodeURIComponent(getAccountEmail());
  const response = await API_AXIOS(AUTH_BASE_URL).get(`account/by_email?email=${urlSafeEmail}`);

  return response.data;
};

export const callLogInApi = async (email: string, password: string): Promise<void> => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_AUTH_API_ENDPOINT}/sessions`, {
      email,
      password,
    });

    const responseFromAuthServer: AuthResponse = await res.data;

    createSession(responseFromAuthServer);
  } catch (error) {
    logException(error, 'login');

    Error(error);
  }
};

export const callLogOffApi = async (): Promise<void> => {
  try {
    const sessionKey = window.localStorage.getItem(SESSION_KEY_NAME) || '';
    const res = await API_AXIOS(AUTH_BASE_URL, {
      session_id: sessionKey,
    }).delete('/sessions');

    if (res.status === 200) {
      deleteSession();

      return;
    }

    Error('Unable to log off user');
  } catch (error) {
    logException(error, 'logoff');

    if (process.env.NODE_ENV === 'development') {
      console.error(error.message);
    }
  }
};
