import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'types/AppThunk';
import User, { UserFromServerPayload } from 'types/User';

import { getAccountDetails, callLogOffApi, callLogInApi } from 'domains/api/apiAuth';
import { handleLogout } from 'integrations';
import { track as trackSegment, identify as identifySegment } from 'integrations/segment';
import { initilalizeInbox } from 'domains/Inbox/redux/inboxSlice';

interface UpdateErrorMessageFromServerPayload {
  errorMessage: string;
}

export interface SessionProps extends UpdateErrorMessageFromServerPayload {
  loginUser: User;
}

export const initialState: SessionProps = {
  loginUser: {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    activationCode: '',
  },
  errorMessage: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateLoginUser: (state, action: PayloadAction<UserFromServerPayload>) => {
      const user: User = {
        name: `${action.payload.firstName} ${action.payload.lastName}`,
        ...action.payload,
      };

      state.loginUser = user;
    },
    updateErrorMessageFromServer: (state, action: PayloadAction<UpdateErrorMessageFromServerPayload>) => {
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const login = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch(sessionSlice.actions.updateErrorMessageFromServer({ errorMessage: '' }));

  try {
    await callLogInApi(email, password);

    const user = await getAccountDetails();

    if (user) {
      dispatch(sessionSlice.actions.updateLoginUser(user));

      return;
    }

    throw Error();
  } catch (error) {
    dispatch(
      sessionSlice.actions.updateErrorMessageFromServer({
        errorMessage: 'Incorrect email or password.',
      })
    );
  }
};

export const logout = (routerPush: (path: string, state?: unknown) => void): AppThunk => async (dispatch) => {
  await callLogOffApi();

  const defaultUser = {
    ...initialState.loginUser,
    firstName: '',
    lastName: '',
  };

  dispatch(sessionSlice.actions.updateLoginUser(defaultUser));
  dispatch(initilalizeInbox());

  handleLogout();

  routerPush('/');
};

export const identifyUser = (
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  supplierName?: string | null
): AppThunk => async (dispatch) => {
  if (firstName && lastName && email && supplierName) {
    identifySegment(
      {
        firstName,
        lastName,
        email,
        supplierName,
      },
      () => {
        dispatch(
          trackSegment({
            type: 'spLoginScreenLoaded',
            payload: { supplierName, memberName: `${firstName} ${lastName}` },
          })
        );
      }
    );
  } else {
    dispatch(trackSegment({ type: 'spLoginScreenLoaded', payload: {} }));
  }
};

export const { updateLoginUser, updateErrorMessageFromServer } = sessionSlice.actions;
