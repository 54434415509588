import React, { createElement, FunctionComponent, KeyboardEvent } from 'react';
import cx from 'classnames';

export type ComponentContianer = 'span' | 'div';

export type LinkButtonRole = 'button' | 'link';

export interface LinkButtonProps {
  onClick: (e: MouseEvent | KeyboardEvent) => void;
  children?: string | string[] | React.ReactElement | null;
  className?: string;
  disabled?: boolean;
  componentContianer?: ComponentContianer;
  dataTestid?: string;
  title?: string;
  role?: LinkButtonRole;
  tabIndex?: number;
}

const LinkButton: FunctionComponent<LinkButtonProps> = ({
  onClick,
  children = null,
  className = '',
  disabled = false,
  componentContianer = 'span',
  dataTestid = 'link-button',
  title = '',
  role = 'button',
  tabIndex = -1,
}: LinkButtonProps): JSX.Element => {
  const classes = cx('button-link', { 'button-disable': disabled }, className);
  let interactProps = {};

  if (!disabled) {
    interactProps = {
      onClick,
      onKeyDown: (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onClick(e);
        }
      },
      role,
      tabIndex,
      title,
    };
  }

  return createElement(
    componentContianer,
    {
      'data-testid': dataTestid,
      className: classes,
      ...interactProps,
    },
    children
  );
};

export default LinkButton;
