import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'types/AppState';
import { InvoiceContainer, calculateCredit, itemUpdatedFromCreditMemos } from './invoiceSlice';
import { OrderItem } from '../../Orders/types/index';

const selectors = {
  invoiceSelector: createSelector(
    ({ invoice }: AppState) => invoice.orderDetails,
    (orderDetails) => orderDetails
  ),
  invoiceChanges: createSelector(
    ({ invoice }: AppState) => invoice.invoiceChanges,
    (invoiceChanges) => invoiceChanges
  ),
  isEditing: createSelector(
    ({ invoice }: AppState) => invoice.isEditing,
    (isEditing) => isEditing
  ),
  isLoading: createSelector(
    ({ invoice }: AppState) => invoice.isLoading,
    (isLoading) => isLoading
  ),
  isError: createSelector(
    ({ invoice }: AppState) => invoice.isError,
    (isError) => isError
  ),
  showOrderAccept: createSelector(
    ({ invoice }: AppState) => invoice.showOrderAccept,
    (showOrderAccept) => showOrderAccept
  ),
  showOrderReject: createSelector(
    ({ invoice }: AppState) => invoice.showOrderReject,
    (showOrderReject) => showOrderReject
  ),
  showOrderChangeConfirmation: createSelector(
    ({ invoice }: AppState) => invoice.showOrderChangeConfirmation,
    (showOrderChangeConfirmation) => showOrderChangeConfirmation
  ),
  showInProgressMessage: createSelector(
    ({ invoice }: AppState) => invoice.showInProgressMessage,
    (showInProgressMessage) => showInProgressMessage
  ),
  showRemoveItemWarning: createSelector(
    ({ invoice }: AppState) => invoice.showRemoveItemWarning,
    (showRemoveItemWarning) => showRemoveItemWarning
  ),
  creditSelector: createSelector(
    ({ invoice }: AppState) => invoice,
    (invoice) => {
      // summary of the quantities changes and the additional credit
      const sumOfCredit = invoice.invoiceChanges.items.reduce((credit, item) => {
        const unchangedItem = invoice.orderDetails?.items?.find((orderItem) => item.orderProductId === orderItem.id);

        if (unchangedItem) {
          return credit + calculateCredit(itemUpdatedFromCreditMemos(invoice, unchangedItem), item);
        }
        return 0;
      }, 0);

      return sumOfCredit;
    }
  ),
  totalCreditSelector: createSelector(
    ({ invoice }: AppState) => invoice,
    (invoice) => {
      // summary of the quantities changes and the additional credit
      let sumOfCredit = invoice.invoiceChanges.items.reduce((credit, item) => {
        const unchangedItem = invoice.orderDetails?.items?.find((orderItem) => item.orderProductId === orderItem.id);

        if (unchangedItem) {
          return credit + calculateCredit(itemUpdatedFromCreditMemos(invoice, unchangedItem), item);
        }
        return 0;
      }, 0);

      sumOfCredit = invoice.invoiceChanges.additionalCredits.reduce(
        (accumulator, note) => note.amount + accumulator,
        sumOfCredit
      );

      return sumOfCredit;
    }
  ),
  isShowingInvoiceModal: createSelector(
    ({ invoice }: AppState) => invoice.isShowingInvoiceModal,
    (isShowingInvoiceModal) => isShowingInvoiceModal
  ),
  isShowingPackingSlipModal: createSelector(
    ({ invoice }: AppState) => invoice.isShowingPackingSlipModal,
    (isShowingPackingSlipModal) => isShowingPackingSlipModal
  ),
  isShowingReplacementOrderModalSelector: createSelector(
    ({ invoice }: AppState) => invoice.isShowingReplacementOrderModal,
    (isShowingReplacementOrderModal) => isShowingReplacementOrderModal
  ),
  isQBDSyncRequestSent: createSelector(
    ({ invoice }: AppState) => invoice.isQBDSyncRequestSent,
    (isQBDSyncRequestSent) => isQBDSyncRequestSent
  ),
  isProcessingCredit: createSelector(
    ({ invoice }: AppState) => invoice.isProcessingCredit,
    (isProcessingCredit) => isProcessingCredit
  ),
  itemUpdatedFromCreditMemosSelector: createSelector(
    ({ invoice }: AppState) => invoice,
    (invoice: InvoiceContainer) => (orderItem: OrderItem) =>
      // update the item quantity after adjustments
      itemUpdatedFromCreditMemos(invoice, orderItem)
  ),
  invoiceUpdatedFromCreditMemosSelector: createSelector(
    ({ invoice }: AppState) => invoice,
    (invoice: InvoiceContainer) => ({
      ...invoice.orderDetails,
      items: invoice.orderDetails?.items?.map((item) =>
        // update the item quantity after adjustments
        itemUpdatedFromCreditMemos(invoice, item)
      ),
    })
  ),
  supplierNote: createSelector(
    ({ invoice }: AppState) => invoice.orderDetails?.supplierNote,
    (supplierNote) => supplierNote
  ),
  notifier: createSelector(
    ({ invoice }: AppState) => invoice.notifyUser,
    (notifyUser) => notifyUser
  ),
  shouldShowInvoiceChangeError: createSelector(
    ({ invoice }: AppState) => invoice.invoiceChangeError,
    (invoiceChangeError) => invoiceChangeError
  ),
  isLoadingReplacement: createSelector(
    ({ invoice }: AppState) => invoice.isLoadingReplacement,
    (isLoadingReplacement) => isLoadingReplacement
  ),
  isReplacingOrder: createSelector(
    ({ invoice }: AppState) => invoice.isReplacingOrder,
    (isReplacingOrder) => isReplacingOrder
  ),
  customItemsSelector: createSelector(
    ({ invoice }: AppState) => invoice.customItems,
    (customItems) => customItems
  ),
};

export default selectors;
