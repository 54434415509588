import React, { FunctionComponent, useState } from 'react';

import cx from 'classnames';
import printJS from 'print-js';

import { isSupportedBrowser } from 'domains/shared/lib/util';

import Button from 'domains/shared/components/button/Button';
import Modal from 'domains/shared/components/modal/Modal';

import PDFViewer, { PDFDocumentProxy } from 'domains/shared/components/pdfViewer/PDFViewer';

import { ModalState } from 'domains/shared/components/modal/types/ModalState';

export interface PrintPreviewProps {
  pdfUrl: string;
  modalState: ModalState;
  isShowingLoading?: boolean;
  children?: React.ReactElement;
  dataTestid?: string;
}

const PrintPreview: FunctionComponent<PrintPreviewProps> = ({
  pdfUrl,
  modalState,
  isShowingLoading: propIsShowingLoading,
  dataTestid = 'print-preview',
  children = undefined,
}: PrintPreviewProps): JSX.Element => {
  const [isShowingLoading, setIsShowingLoading] = useState(true);

  const [numPages, setNumPages] = useState(1);

  const printPackingSlip = (event: React.MouseEvent) => {
    if (pdfUrl) {
      event.stopPropagation();

      if (isSupportedBrowser()) {
        printJS({
          printable: pdfUrl,
          type: 'pdf',
          showModal: false,
        });
      } else {
        window.open(pdfUrl);
      }
    }
  };

  const onLoadingPDFViewer = (pdf: PDFDocumentProxy) => {
    setIsShowingLoading(false);

    setNumPages(pdf.numPages);
  };

  const isLoading = () => {
    if (propIsShowingLoading !== undefined) {
      return propIsShowingLoading;
    }

    return isShowingLoading;
  };

  return (
    <Modal
      showDisplayingModal={modalState.isShowingModal}
      onToggle={modalState.setIsShowingModal}
      data-testid={`print-modal-${dataTestid}`}
      className="print-modal"
    >
      <>
        <div
          className={cx('print-container', {
            'is-loading': isLoading(),
          })}
        >
          {children || (
            <PDFViewer
              filePath={pdfUrl}
              onLoadSuccess={onLoadingPDFViewer}
              numPages={numPages}
              data-testid={`print-button-${dataTestid}`}
            />
          )}
        </div>
        <div className="preview-buttons">
          <div className="button-wrapper">
            <div className="print-btn-wrapper">
              <Button
                className="print-btn"
                color="tertiary"
                onClick={printPackingSlip}
                dataTestid={`print-button-${dataTestid}`}
              >
                Print
              </Button>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default PrintPreview;
