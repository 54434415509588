import { logException } from 'domains/shared/lib/logger';

// custom middleware for catching errors
const createLoggerMiddleware = (extraArgument) => {
  return ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
      const result = action(dispatch, getState, extraArgument);

      // If the function returns a promise (async function usually), then
      // catch the error manually and report it.
      if (result && result.catch && typeof result.catch === 'function') {
        return result.catch((error) => {
          logException(error);
        });
      }
      return result;
    }

    return next(action);
  };
};

const loggerMiddleware = createLoggerMiddleware();
loggerMiddleware.withExtraArgument = createLoggerMiddleware;

export default loggerMiddleware;
