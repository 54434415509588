import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppSliceProps {
  isMenuOpen: boolean;
  isMobileMenuOpen: boolean;
  isShowingDiscardOrder: boolean;
  isShowingCreateOrder: boolean;
  animateClose: boolean;
}

export const initialState: AppSliceProps = {
  isMenuOpen: true,
  isMobileMenuOpen: false,
  isShowingDiscardOrder: false,
  isShowingCreateOrder: false,
  animateClose: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMenuOpen: (state: AppSliceProps, action: PayloadAction<boolean>) => ({
      ...state,
      isMenuOpen: action.payload,
    }),
    setMobileMenuOpen: (state, action) => ({
      ...state,
      isMobileMenuOpen: action.payload,
      animateClose: true,
    }),
    setIsShowingDiscardOrder: (state: AppSliceProps, action: PayloadAction<boolean>) => ({
      ...state,
      isShowingDiscardOrder: action.payload,
    }),
    setIsShowingCreateOrder: (state: AppSliceProps, action: PayloadAction<boolean>) => ({
      ...state,
      isShowingCreateOrder: action.payload,
    }),
  },
});

export const { setMenuOpen, setMobileMenuOpen, setIsShowingDiscardOrder, setIsShowingCreateOrder } = appSlice.actions;
