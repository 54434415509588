import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import images from 'config';

import { Image } from 'types/Image';

import LinkButton from '../button/LinkButton';

export interface ToasterProps {
  message: string | React.ReactElement;
  onClose: () => void;
  additionalInfo?: string | React.ReactElement;
  icon?: Image;
  isWarning?: boolean;
  shouldHideToaster?: boolean;
  shouldAutoClose?: boolean;
  toasterDuration?: number;
  dataTestid?: string;
}

const Toaster = ({
  message,
  onClose,
  additionalInfo = undefined,
  icon = undefined,
  isWarning = false,
  shouldHideToaster = false,
  shouldAutoClose = true,
  toasterDuration = 5000,
  dataTestid = 'toaster',
}: ToasterProps): JSX.Element => {
  let slideDownTimeout: number | null = null;
  let hideTimeout: number | null = null;
  const [hidden, setHidden] = useState(false);
  const slideDown = (shouldSetTimeout: boolean) => {
    setHidden(true);

    if (slideDownTimeout) {
      clearTimeout(slideDownTimeout);
    }

    if (shouldSetTimeout) {
      hideTimeout = window.setTimeout(onClose, 1000);

      return hideTimeout;
    }

    return onClose();
  };

  useEffect(() => {
    if (shouldHideToaster) {
      slideDown(true);
    }

    return () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
    };

    /*
    Disable the need for slideDown to be a in the requirments as it will not be
    use all the time only when `shouldHideToaster` changes and only when the
    developer is wants to close the toaster from outside the component
    */
    // eslint-disable-next-line
  }, [shouldHideToaster]);

  if (shouldAutoClose) {
    slideDownTimeout = window.setTimeout(() => {
      slideDown(false);
    }, toasterDuration);
  }

  return (
    <div className={cx('toaster-wrapper', { hidden })} data-testid={dataTestid}>
      <div className={cx('toaster', { warning: isWarning })}>
        <div>
          <>
            <div className="toaster-main-content">
              {icon && <img src={icon.src} alt={icon.alt} className="toaster-icon" />}

              <div className="toaster-message">{message}</div>

              <LinkButton dataTestid={`button-${dataTestid}`} onClick={() => slideDown(true)}>
                <img src={images.closeIcon} alt="close button" className="toaster-close-button" />
              </LinkButton>
            </div>

            {additionalInfo && <div className="toaster-message-additional-info">{additionalInfo}</div>}
          </>
        </div>
      </div>
    </div>
  );
};

export default Toaster;
