import Cookies from 'universal-cookie';

import { AppThunk } from 'types/AppThunk';

import { addSnippet } from './snippet';

const USER_ID_KEY = 'ajs_user_id';

export interface Payload {
  [k: string]: string;
}

export interface EventToTrack {
  type: string;
  payload: Payload;
}

export const initialize = (): void => {
  if (process.env.NODE_ENV === 'development') {
    // for development we don't want to track the user activity.
    // instead we're overriding those methods for debugging puproses.
    window.analytics = {
      ...window.analytics,
      identify: () => {
        console.info('User was identified');
      },
      track: () => {
        console.info('Activity was tracked');
      },
      reset: () => {
        console.info('Segment data was reset');
      },
    };
    return;
  }

  addSnippet();
};

export const identify = (
  {
    firstName,
    lastName,
    email,
    supplierName,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    supplierName: string;
  },
  onIdentified?: () => void
): void => {
  if (window.analytics) {
    window.analytics.identify(
      email,
      {
        memberName: `${firstName} ${lastName}`,
        email,
        supplierName,
      },
      {},
      () => {
        if (onIdentified) {
          onIdentified();
        }
      }
    );
  }
};

export const reset = (): void => {
  window.analytics.reset();
};

const trackEvent = (eventName: string, props: Payload) => {
  if (window.analytics) {
    const newProps = { ...props, timeTriggered: new Date() };

    window.analytics.track(eventName, newProps);
  }
};

export const track = (event: EventToTrack, requireIdentifiedUser = true): AppThunk => (dispatch) => {
  const cookies = new Cookies(document.cookie);
  if (
    // if user is not identified we might not want to track their activity
    // as we don't want to create many users on mixpanel which will raise our bill
    !requireIdentifiedUser ||
    (window.localStorage.getItem(USER_ID_KEY) && window.localStorage.getItem(USER_ID_KEY)?.toLowerCase() !== 'null') ||
    cookies.get(USER_ID_KEY)
  ) {
    dispatch({ ...event, type: `analytics/${event.type}` });
    trackEvent(event.type, event.payload);
  }
};
