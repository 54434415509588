import { AxiosResponse, CancelToken } from 'axios';
import { FormValues } from 'domains/OrderDetails/types/FormValues';
import { BulkApiOptions } from 'types/BulkAction';
import apiClient, { API_AXIOS_BILLING } from './apiAuth';
import invoice from './invoice';
import { Customer, Order, OrderList, PaymentSource, Product } from '../Orders/types';

const isNotchPayEnabled = process.env.REACT_APP_NOTCH_PAY_ENABLED;

export const searchForBuyers = async (searchTerm: string): Promise<Customer[]> => {
  const response = await apiClient().get(`/customers/?search_term=${searchTerm}`);

  const data: Customer[] = await response.data;

  return Object.keys(data).length === 0 ? [] : data;
};

export const attachCard = async (token: string, customerId: string): Promise<void> => {
  await apiClient().post(`/payment-sources/${customerId}`, {
    tokenId: token,
  });
};

export const getPaymentSources = async (buyerId?: string): Promise<PaymentSource[]> => {
  if (isNotchPayEnabled === 'true') {
    const response = await API_AXIOS_BILLING().get(`/v1/payment_methods/${buyerId}`);
    return response.data.paymentMethods;
  }

  const response = await apiClient().get(`/payment-sources/${buyerId}`);
  return response.data;
};

export const searchForProducts = async (
  searchTerm: string,
  buyerId: string,
  vendorId: string,
  limit = 10
): Promise<Product[]> => {
  const response = await apiClient().get(
    `/catalog/?search_term=${encodeURIComponent(searchTerm)}&buyer_id=${buyerId}&vendor_id=${vendorId}&limit=${limit}`
  );

  const data: Product[] = await response.data;

  return Object.keys(data).length === 0 ? [] : data;
};

export const fetchProduct = async (itemId: string, buyerId: string, vendorId: string): Promise<Product | undefined> => {
  let data: Product | undefined;
  try {
    const response = await apiClient().get(`/catalog/${itemId}?buyer_id=${buyerId}&vendor_id=${vendorId}`);
    data = response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to retrieve item information from catalog-api');
  }

  return data;
};

export const getOrders = async (
  offset: number,
  limit: number,
  searchTerm: string,
  status: string,
  cancelToken: CancelToken,
  sortBy = 'delivery_date',
  startDate = '',
  endDate = ''
): Promise<OrderList> => {
  const queryParams = [`offset=${offset}`, `limit=${limit}`, `status=${status}`, `sort_by=${sortBy}`];

  if (searchTerm) {
    queryParams.push(`search_term=${searchTerm}`);
  }

  if (startDate && endDate) {
    queryParams.push(`start_date=${startDate}&end_date=${endDate}`);
  }

  const response = await apiClient().get(`/orders/?${queryParams.join('&')}`, {
    cancelToken,
  });
  return response.data;
};

export const setOrdersToFulfilledStatus = async (selectedOrders: string[]): Promise<AxiosResponse> => {
  const response = await apiClient().post('/orders/bulk/fulfilled', {
    orderIds: selectedOrders,
  });

  return response;
};

export const getCustomer = async (customerId: string): Promise<Customer> => {
  const response = await apiClient().get(`/customers/${customerId}`);

  return response.data;
};

export const getOrderPackingSlipsPDFs = async (selectedOrders: string[]): Promise<AxiosResponse> => {
  const response = await apiClient().post(
    '/orders/bulk/packing-slips',
    { orderIds: selectedOrders },
    { responseType: 'blob' }
  );

  return response;
};

export const getMasterPickListPDFs = async (
  selectedOrders: string[],
  options: BulkApiOptions
): Promise<AxiosResponse> => {
  const response = await apiClient().post(
    '/orders/bulk/master-picklist',
    {
      orderIds: selectedOrders,
      printTimestamp: new Date().toLocaleString(undefined, {
        hour12: false,
      }),
      ...options,
    },
    { responseType: 'blob' }
  );

  return response;
};

export const getOrderDetails = async (urlsafe: string): Promise<Order> => {
  const response = await apiClient().get(`/orders/${urlsafe}`);

  return response.data;
};

export const markInProgress = async (urlsafe: string, lastFetchTimestamp: string): Promise<Order> => {
  const response = await apiClient().post(`/orders/${urlsafe}/confirm?lft=${lastFetchTimestamp || 0}`);

  return response.data;
};

export const fulfillOrder = async (urlsafe: string, lastFetchTimestamp: string): Promise<Order> => {
  const response = await apiClient().post(`/orders/${urlsafe}/deliver?lft=${lastFetchTimestamp || 0}`);

  return response.data;
};

export const cancelOrder = async (urlsafe: string, lastFetchTimestamp: string, rejectMsg: string): Promise<Order> => {
  const response = await apiClient().delete(
    `/orders/${urlsafe}/cancel?lft=${lastFetchTimestamp || 0}&reason=${rejectMsg}`
  );

  return response.data;
};

export const markDirectAsPaid = async (urlsafe: string, lastFetchTimestamp: string): Promise<Order> => {
  const response = await apiClient().post(`/orders/${urlsafe}/pay-direct?lft=${lastFetchTimestamp}`);

  return response.data;
};

export const createOrder = async (order: FormValues, isSupplierPending = false): Promise<Order> => {
  if (isNotchPayEnabled !== 'true') {
    const response = await apiClient().post(`/orders`, order);
    return response.data;
  }

  let updatedOrder = { ...order };
  if (order.paymentSourceType === 'pay_later') {
    updatedOrder = {
      ...updatedOrder,
      payLater: true,
      paymentMethodId: '',
      payLaterViaBank: false,
      addNewCreditCard: false,
    };
  } else if (updatedOrder.paymentSourceType === 'new_card') {
    updatedOrder = {
      ...updatedOrder,
      paymentMethodId: '',
      addNewCreditCard: true,
      payLaterViaBank: false,
      payLater: false,
    };
  } else if (updatedOrder.paymentSourceType === 'ach_eft') {
    updatedOrder = {
      ...updatedOrder,
      paymentMethodId: '',
      payLaterViaBank: true,
      addNewCreditCard: false,
      payLater: false,
    };
  } else {
    updatedOrder = {
      ...updatedOrder,
      payLaterViaBank: false,
      addNewCreditCard: false,
      payLater: false,
    };
  }
  const response = await apiClient().post(`/orders?supplierPending=${isSupplierPending}`, updatedOrder);
  return response.data;
};

export const editOrder = async (urlsafe: string, updatedOrder: Order | null): Promise<Order> => {
  const response = await apiClient().put(
    `/orders/${urlsafe}?lft=${updatedOrder?.lastFetchTimestamp || 0}`,
    updatedOrder
  );

  return response.data;
};

export const createReplacementOrder = async (
  urlsafe: string,
  replacementOrder: Order,
  lastFetchTimestamp?: string
): Promise<Order> => {
  const response = await apiClient().post(`/orders/${urlsafe}/replace?lft=${lastFetchTimestamp}`, replacementOrder);

  return response.data;
};

export const sendQBDSyncRequest = async (orderId: string): Promise<Order> => {
  const response = await apiClient().post(`/orders/${orderId}/send-to-third-party`);
  return response.data;
};

export const mergeAddonOrders = async (urlsafe: string): Promise<Order> => {
  const response = await apiClient().post(`/orders/${urlsafe}/merge-addon`);

  return response.data;
};

export const updateSupplierNote = async (
  urlsafe: string,
  lastFetchTimestamp: string,
  supplierNote: string
): Promise<Order> => {
  const response = await apiClient().put(`/orders/${urlsafe}?lft=${lastFetchTimestamp || 0}`, {
    supplierNote,
  });

  return response.data;
};
export const deleteOrder = (urlsafe: string, paymentId: string): void => {
  apiClient().delete(`/orders/${urlsafe}`, {
    data: { paymentId, reason: 'Supplier cancelled the order' },
  });
};
export const activateOrder = async (urlsafe: string): Promise<Order> => {
  const response = await apiClient().put(`/orders/${urlsafe}`, {
    isSupplierPending: false,
  });

  return response.data;
};
export { invoice };
export * from './inbox';
