import React, { useEffect, useRef } from 'react';

import cx from 'classnames';

import { ActionMeta, components, ControlProps, ValueContainerProps, ValueType, InputActionMeta } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Option } from 'react-select/src/filters';

import AsyncSelect from 'react-select/async';

import { debounce } from 'lodash';

import images from 'config';

import { DEBOUNCE_DELAY_IN_MS } from '../../../../constants';

export type OptionType = {
  label: unknown;
  value: unknown;
  inputValue?: unknown;
};

export type SearchEndpointArgs = string | number;

export interface RefocusSearchProps {
  endShouldRefocusItemSearchField: () => void;
  shouldRefocusItemSearchField: boolean;
}

export interface SearchProps {
  id: string;
  onChange: (value: ValueType<OptionType, false>, actionMeta: ActionMeta<OptionType>) => void;
  onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
  searchEndpoint: (args: string) => Promise<Array<unknown>>;
  components: Partial<SelectComponents<OptionType, false>>;
  inputValue: string;
  placeholder: string;
  noOptionsMessage?: string | null;
  loadingMessage?: string;
  filterOption?: (label: Option, data: string) => boolean;
  autoFocus?: boolean;
  refocusSearch?: RefocusSearchProps;
}

const ControlComponent = ({ isFocused, ...props }: ControlProps<OptionType, false>): JSX.Element => (
  <components.Control
    className={cx('search-input-container', {
      'search-input-container-active': isFocused,
    })}
    isFocused={isFocused}
    // do to unknown props that React-Select uses to control the select box
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const ValueComponentContainer = ({ children, ...props }: ValueContainerProps<OptionType, false>): JSX.Element => (
  <components.ValueContainer
    className="search-value-container"
    // do to unknown props that React-Select uses to control the select box
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </components.ValueContainer>
);

const IndicatorsContainer = (): JSX.Element => <img src={images.searchIcon} alt="Search" className="search-icon" />;

const Search = ({
  id,
  searchEndpoint,
  onChange,
  onInputChange,
  components: customComponents,
  inputValue,
  placeholder,
  noOptionsMessage = 'No more options',
  loadingMessage = 'Loading',
  filterOption,
  autoFocus = true,
  refocusSearch,
}: SearchProps): JSX.Element => {
  // the type of ref for AsyncSelect is not working
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();

  useEffect(() => {
    if (refocusSearch?.shouldRefocusItemSearchField && refocusSearch?.endShouldRefocusItemSearchField) {
      refocusSearch.endShouldRefocusItemSearchField();

      if (ref?.current) {
        ref.current.focus();
      }
    }
  });

  return (
    <AsyncSelect
      ref={ref}
      id={id}
      cacheOptions
      components={{
        IndicatorsContainer,
        Control: ControlComponent,
        ValueContainer: ValueComponentContainer,
        ...customComponents,
      }}
      placeholder={placeholder}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
      loadOptions={debounce(searchEndpoint, DEBOUNCE_DELAY_IN_MS, {
        leading: true,
      })}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onSelectResetsInput={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      filterOption={filterOption}
      autoFocus={autoFocus}
    />
  );
};

export default Search;
