import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import appFeaturesSelector from 'store/appFeatures/appFeaturesSelector';
import { AppFeaturesProps } from 'store/appFeatures/appFeaturesSlice';
import setFeatureFlag from 'firebase';

const useFeatures = (): {
  features: AppFeaturesProps;
  setAppFeautes: () => void;
} => {
  const features = useSelector(appFeaturesSelector.featuresSelector);
  const dispatch = useAppDispatch();
  const setAppFeautes = useCallback(() => {
    setFeatureFlag(dispatch);
  }, [dispatch]);

  return {
    features,
    setAppFeautes,
  };
};

export default useFeatures;
