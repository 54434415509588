import 'segment-typescript-definitions/analytics';

import { initLoggedInUser as initLoggedInUserIntercom } from 'integrations/intercom';
import { initialize as initializeLogRocket, identify as identifyLogRocket } from 'integrations/logrocket';
import {
  initialize as initializeSegment,
  identify as identifySegment,
  reset as resetSegment,
} from 'integrations/segment';

import User from 'types/User';
import Supplier from 'types/Supplier';

// declare globally needed objects in window object for third-party integrations
declare global {
  interface Window {
    /**
     * Because this code comes form Intercom Script tag and not use we decied not to
     * maintain the linting for this file.
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    Intercom: any;
    intercomSettings: any;
    attachEvent: any;
    balanceCheckout: any;
    /* eslint-enable */
    analytics: AnalyticsJS;
  }
}

// initialize all third-party integrations
export const initialize = (): void => {
  if (process.env.NODE_ENV !== 'development') {
    initializeLogRocket();
  }

  initializeSegment();
};

// invoke needed thir-party integration functions after logged in user is available
export const activateAfterUserLogin = (user: User, supplier: Supplier): void => {
  if (supplier?.id) {
    initLoggedInUserIntercom(user, supplier);
  }

  identifyLogRocket(user, supplier);
  identifySegment({
    firstName: user.firstName,
    lastName: user.lastName,
    supplierName: supplier.name,
    email: user.email,
  });
};

export const handleLogout = (): void => {
  // when the user logs out of the system we want to reset segment data
  resetSegment();
};
