import React, { useState, useEffect, ReactNode } from 'react';
import cx from 'classnames';

import images from 'config';
import LinkButton from '../button/LinkButton';

interface MobileMenuProps {
  isVisible: boolean;
  children: ReactNode;
  menuTitle: string;
  hideMenu: () => void;
}

const MobileMenu = ({ children, isVisible, menuTitle, hideMenu }: MobileMenuProps): JSX.Element => {
  const [hasMenuBeenOpened, setHasMenuBeenOpened] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setHasMenuBeenOpened(true);
    }
  }, [isVisible]);

  return (
    <>
      <div
        className={cx({
          'filters-menu-mobile': true,
          visible: isVisible,
          close: !isVisible && hasMenuBeenOpened,
        })}
      >
        <div className="menu-content">
          <div className="filters-header">
            <h1>{menuTitle}</h1>

            <LinkButton className="close-btn" onClick={hideMenu}>
              <img src={images.mobileMenuCloseIcon} alt="close menu" width={44} height={44} />
            </LinkButton>
          </div>
          {children}
        </div>
      </div>

      {isVisible && <LinkButton componentContianer="div" className="filters-menu-overlay" onClick={hideMenu} />}
    </>
  );
};

export default MobileMenu;
