import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Channel } from 'twilio-chat/lib/channel';
import { InboxMessages } from '../types';
import inboxSelectors from '../redux/inboxSelectors';
import { sendMessageAction } from '../redux/inboxSlice';

const useChatMessage = (): {
  messages: InboxMessages;
  sendMessage: (message: string | FormData | Channel.SendMediaOptions | null, channel: Channel) => void;
} => {
  const messages = useSelector(inboxSelectors.messageSelector);
  const dispatch = useAppDispatch();
  const sendMessage = useCallback(
    (message: string | FormData | Channel.SendMediaOptions | null, channel: Channel) => {
      dispatch(sendMessageAction(message, channel));
    },
    [dispatch]
  );

  return {
    messages,
    sendMessage,
  };
};

export default useChatMessage;
