import React from 'react';
import cx from 'classnames';

export interface DetailsProps {
  HeaderComponent: JSX.Element;
  LeftComponent: JSX.Element;
  RightComponent: JSX.Element;
  FooterComponent?: JSX.Element;
  containerClassName?: string;
}

const Details = ({
  HeaderComponent,
  LeftComponent,
  RightComponent,
  FooterComponent = undefined,
  containerClassName = '',
}: DetailsProps): JSX.Element => (
  <section className={cx('container', 'is-fluid', containerClassName)}>
    <header className="details-header">{HeaderComponent}</header>

    <div className="columns details">
      <div className="column details-left-container">{LeftComponent}</div>

      <div className="column details-right-container">{RightComponent}</div>
    </div>

    {FooterComponent && <footer>{FooterComponent}</footer>}
  </section>
);

export default Details;
