import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createChannel, getAccessToken } from 'domains/api';
import { getAccountEmail } from 'domains/api/apiAuth';
import { logException } from 'domains/shared/lib/logger';
import { Channel } from 'twilio-chat/lib/channel';
import { AppThunk } from 'types/AppThunk';
import {
  ChatChannelBody,
  InboxChannelItem,
  InboxChannelItemData,
  InboxChats,
  InboxMessage,
  InboxSliceProps,
  InboxToast,
} from '../types';
import { calculateUnreadChatMessages } from '../utils/chatHelper';

export const initialState: InboxSliceProps = {
  channel: {
    isLoading: false,
    list: {},
    unreadMsgsCount: 0,
  },
  auth: {
    accessToken: '',
    conectionStatus: '',
  },
  messages: {
    isSending: false,
    chats: {},
  },
  toast: {
    display: false,
    message: '',
  },
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    setChannelLoad: (state: InboxSliceProps, action: PayloadAction<boolean>) => ({
      ...state,
      channel: {
        ...state.channel,
        isLoading: action.payload,
      },
    }),
    addChannel: (state: InboxSliceProps, action: PayloadAction<InboxChannelItem>) => ({
      ...state,
      channel: {
        ...state.channel,
        list: { ...state.channel.list, ...action.payload },
      },
    }),
    updateChannel: (
      state: InboxSliceProps,
      action: PayloadAction<{
        channelId: string;
        channel: InboxChannelItemData;
      }>
    ) => ({
      ...state,
      channel: {
        ...state.channel,
        list: {
          ...state.channel.list,
          [action.payload.channelId]: {
            ...state.channel.list[action.payload.channelId],
            ...action.payload.channel,
          },
        },
      },
    }),
    removeChannel: (state: InboxSliceProps, action: PayloadAction<InboxChannelItem>) => ({
      ...state,
      channel: {
        ...state.channel,
        list: { ...action.payload },
      },
    }),
    updateUnreadMsgs: (state: InboxSliceProps, action: PayloadAction<number>) => ({
      ...state,
      channel: {
        ...state.channel,
        unreadMsgsCount: action.payload,
      },
    }),
    setSendingMessage: (state: InboxSliceProps, action: PayloadAction<boolean>) => ({
      ...state,
      messages: {
        ...state.messages,
        isSending: action.payload,
      },
    }),
    addMessage: (state: InboxSliceProps, action: PayloadAction<InboxChats>) => ({
      ...state,
      messages: {
        ...state.messages,
        chats: {
          ...state.messages.chats,
          ...action.payload,
        },
      },
    }),
    removeMessage: (state: InboxSliceProps, action: PayloadAction<InboxChats>) => ({
      ...state,
      messages: {
        ...state.messages,
        chats: {
          ...action.payload,
        },
      },
    }),
    appendMessage: (state: InboxSliceProps, action: PayloadAction<{ channelId: string; messages: InboxMessage[] }>) => {
      const prevMessages = state.messages.chats[action.payload.channelId] || [];
      const sids = prevMessages.map((msg: InboxMessage) => msg.sid);
      const [newMessage] = action.payload.messages;
      if (!sids.includes(newMessage.sid)) {
        return {
          ...state,
          messages: {
            ...state.messages,
            chats: {
              ...state.messages.chats,
              [action.payload.channelId]: [...prevMessages, ...action.payload.messages],
            },
          },
        };
      }
      return state;
    },
    setAuthToken: (state: InboxSliceProps, action: PayloadAction<string>) => ({
      ...state,
      auth: {
        ...state.auth,
        accessToken: action.payload,
      },
    }),
    setConnectionStatus: (state: InboxSliceProps, action: PayloadAction<string>) => ({
      ...state,
      auth: {
        ...state.auth,
        conectionStatus: action.payload,
      },
    }),
    updateToast: (state: InboxSliceProps, action: PayloadAction<InboxToast>) => ({
      ...state,
      toast: {
        ...action.payload,
      },
    }),
    initilalizeInbox: () => ({
      ...initialState,
    }),
  },
});

export const {
  setChannelLoad,
  setAuthToken,
  setConnectionStatus,
  addChannel,
  updateChannel,
  removeChannel,
  updateUnreadMsgs,
  setSendingMessage,
  addMessage,
  removeMessage,
  appendMessage,
  updateToast,
  initilalizeInbox,
} = inboxSlice.actions;

/**
 * Log exception exceptions
 */
export const logExceptionAction = (payload: InboxToast): AppThunk => (dispatch) => dispatch(updateToast(payload));

/**
 * Handle Catch method in case Action is failed to execute.
 * @param {object} error Error object
 */
export const handleException = (error: { response?: { data?: { message?: string } } }): AppThunk => (dispatch) => {
  let errorMessage = '';
  if (!error.response) errorMessage = 'Detected a connection problem, please refresh this page';
  else errorMessage = (error.response.data || {}).message || 'Please try again';

  dispatch(updateToast({ display: true, message: `Error: ${errorMessage}` }));
  logException(error as Error);
};

/**
 * Clear all exceptions
 */
export const clearException = (): AppThunk => (dispatch) => dispatch(updateToast({ display: false, message: '' }));

/**
 * Fetch the Chat token for user by passing user identity
 *
 * This method fetches the token and sets the token into inbox auth reducer state.
 * The 'useEffect' method inside useChat.ts file uses it to
 * create the Twilio Chat instance.
 */
export const getAccessTokenAction = (): AppThunk => async (dispatch, getState) => {
  try {
    const {
      loginUser: { email },
    } = getState().session;
    const { accessToken = '' } = await getAccessToken({
      identity: email || getAccountEmail(),
      device: 'connect',
    });
    if (accessToken) {
      dispatch(setAuthToken(accessToken));
    }
  } catch (e) {
    dispatch(setAuthToken(''));
  }
};

/**
 * Handle connection status for Twilio
 * @param {string} status connection status
 */
export const setConnectionStatusAction = (status: string): AppThunk => async (dispatch) =>
  dispatch(setConnectionStatus(status));

export const createChannelAction = (
  data: ChatChannelBody,
  push: (path: string, state?: unknown) => void
): AppThunk => async (dispatch) => {
  try {
    dispatch(setChannelLoad(true));
    const { channelSid = '' } = await createChannel(data);
    if (channelSid) {
      push(`/inbox/${channelSid}`);
    }
    dispatch(setChannelLoad(false));
  } catch (e) {
    dispatch(setChannelLoad(false));
  }
};

/**
 * Counts unread chat messages
 */
export const unreadMsgAction = (): AppThunk => (dispatch, getState) => {
  const {
    channel: { list },
  } = getState().inbox;
  const unreadMsgs = calculateUnreadChatMessages(list);
  dispatch(updateUnreadMsgs(unreadMsgs));
};

/**
 *  Add Chat channels
 * @param {Array} channels channels array to add
 */
export const addChannelAction = (payload: InboxChannelItem | null): AppThunk => async (dispatch) => {
  if (payload) {
    dispatch(addChannel(payload));
  }
};

/**
 * Update Chat channel
 * @param {Object} channel Chat channel object
 */
export const updateChannelAction = (channelId: string, payload: InboxChannelItem | null): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const {
      channel: { list },
    } = getState().inbox;

    // Return if channelId does NOT exists
    if (!list[channelId]) {
      return;
    }

    if (payload) {
      dispatch(updateChannel({ channelId, channel: payload[channelId] }));
    }
  } catch (e) {
    dispatch(handleException(e));
  }
};

/**
 * Removes the Chat channel from store
 * @param {string} channelId Channel unique id or sid
 */
export const removeChannelAction = (channelId: string): AppThunk => async (dispatch, getState) => {
  const {
    channel: { list },
  } = getState().inbox;
  const updatedList = { ...list };
  delete updatedList[channelId];
  dispatch(removeChannel(updatedList));
  dispatch(clearException());
};

export const addMessageAction = (channelId: string, messages: InboxMessage[]): AppThunk => async (dispatch) => {
  const payload: InboxChats = {
    [channelId]: messages,
  };

  dispatch(addMessage(payload));
};

/**
 * Removes Chat message from store
 * @param {string} channelId Channel unique id or sid
 */
export const removeMessageAction = (channelId: string): AppThunk => (dispatch, getState) => {
  const {
    messages: { chats },
  } = getState().inbox;

  const updatedChats = { ...chats };
  delete updatedChats[channelId];
  dispatch(removeMessage(updatedChats));
  dispatch(clearException());
};

/**
 * Append Chat messages to channel sid
 * @param {string} channelId Channel unique id or sid
 * @param {Message} message Chat message to add
 */
export const appendMessageAction = (channelId: string, messages: InboxMessage[]): AppThunk => async (dispatch) => {
  dispatch(appendMessage({ channelId, messages }));
};

/**
 * A method to send a user Chat message.
 *
 * This method internally handles by prefixing extra info to the message in case it is the
 * first message from user for the selected channel "channelId or sid"
 *
 * @param {ChatMessageBody} message A message to be sent
 * @param {string} channelId Selected message channel Id or sid
 */
export const sendMessageAction = (
  message: string | FormData | Channel.SendMediaOptions | null,
  channel: Channel
): AppThunk => async (dispatch, getState) => {
  dispatch(setSendingMessage(true));
  try {
    const {
      inbox: {
        messages: { chats },
      },
      session: {
        loginUser: { name },
      },
    } = getState();
    const channelId = channel.sid;
    const messages = chats[channelId];
    if (!messages) {
      // If "message" is going to be first message of the user,
      // then add some extra info into the message being sent
      await channel.sendMessage(message, {
        isFirstMessage: 'true',
        vendorName: name,
      });
    } else {
      // Else, just send the message as is
      await channel.sendMessage(message);
    }

    // Sets all the messages in Chat channel as read by user
    await channel.setAllMessagesConsumed();

    dispatch(setSendingMessage(false));
  } catch (e) {
    dispatch(getAccessTokenAction());
    dispatch(setSendingMessage(false));
    dispatch(handleException(e));
  }
};
