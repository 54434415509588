import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppFeaturesProps {
  isChatEnabled: boolean;
  isOrderChatEnabled: boolean;
  loading: boolean;
}

export const initialState: AppFeaturesProps = {
  isChatEnabled: false,
  isOrderChatEnabled: false,
  loading: true,
};

export const appFeaturesSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setChat: (state: AppFeaturesProps, action: PayloadAction<boolean>) => ({
      ...state,
      isChatEnabled: action.payload,
    }),
    setOrderChat: (state: AppFeaturesProps, action: PayloadAction<boolean>) => ({
      ...state,
      isOrderChatEnabled: action.payload,
    }),
    setLoading: (state: AppFeaturesProps, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setChat, setOrderChat, setLoading } = appFeaturesSlice.actions;
