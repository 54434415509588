import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';

import { ModalState } from '../modal/types/ModalState';

import DiscardForm, { DiscardText } from './Discard';

export interface RouteLeavingGuardProps {
  when: boolean;
  state: ModalState;
  currentPath?: string;
  navigate: (path: string) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  isCreating: boolean;
  discardText: DiscardText;
}

const RouteLeavingGuard = ({
  when,
  state,
  navigate,
  isCreating,
  discardText,
  currentPath,
  onConfirm = undefined,
  onCancel = undefined,
}: RouteLeavingGuardProps): JSX.Element => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isShowingPrompt, setIsShowingPrompt] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (currentPath && currentPath === nextLocation.pathname) {
      return false;
    }

    setIsShowingPrompt(true);

    if (!confirmedNavigation) {
      if (!state.isShowingModal) {
        state.setIsShowingModal(true);
      }

      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigation = () => {
    state.setIsShowingModal(false);
    setConfirmedNavigation(true);

    // if there is no pprompt meaning the user is using the parent's state
    // to navigate away from the form instead of routes then lest the parent
    // handle how to do the confirmation
    if (!isShowingPrompt && onConfirm) onConfirm();
  };

  const handleCancel = (isShowingDiscard: boolean) => {
    state.setIsShowingModal(isShowingDiscard);

    // if there is no pprompt meaning the user is using the parent's state
    // to navigate away from the form instead of routes then lest the parent
    // handle how to do the confirmation
    if (!isShowingPrompt && onCancel) onCancel();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [navigate, confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <DiscardForm
        onConfirm={handleConfirmNavigation}
        onCancel={handleCancel}
        state={state}
        isCreating={isCreating}
        discardText={discardText}
      />
    </>
  );
};
export default RouteLeavingGuard;
