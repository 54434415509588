import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { round } from 'domains/shared/lib/util';

import { fulfillOrder, invoice as invoiceAPIs, sendQBDSyncRequest as sendQBDSyncRequestAPI } from 'domains/api';
import getCustomItems from 'domains/api/catalog';

import { AppThunk } from 'types/AppThunk';
import { NotifyUser, ConflictNotifier } from 'types/Notifiers';
import { Order, OrderCustomItem, OrderItem } from 'domains/Orders/types';

import { logException } from 'domains/shared/lib/logger';
import { CreditNote, CreditNoteItem, Invoice } from '../types';

import { setIsShowingDiscardOrder, setIsShowingCreateOrder } from '../../../store/appSlice';

export interface InvoiceContainer {
  orderDetails: Invoice | null;
  invoiceChanges: InvoiceChanges;
  isEditing: boolean;
  isLoading: boolean;
  isError: boolean;
  showOrderAccept: boolean;
  showOrderReject: boolean;
  showOrderChangeConfirmation: boolean;
  showInProgressMessage: boolean;
  showRemoveItemWarning: boolean;
  isShowingInvoiceModal: boolean;
  isShowingPackingSlipModal: boolean;
  isShowingReplacementOrderModal: boolean;
  isQBDSyncRequestSent: boolean;
  isProcessingCredit: boolean;
  notifyUser: NotifyUser;
  invoiceChangeError: ConflictNotifier;
  isLoadingReplacement: boolean;
  isReplacingOrder: boolean;
  customItems: OrderCustomItem[];
}

export interface AdditionalCreditItem {
  id: string;
  description: string;
  amount: number;
  quantity: number;
}

interface InvoiceChanges {
  items: OrderItem[];
  deliveryDate?: string;
  messageToCustomer?: string;
  orderId: string;
  invoiceNumber: string;
  reason: string;
  memo?: string;
  additionalCredits: AdditionalCreditItem[];
}

export const initialState: InvoiceContainer = {
  orderDetails: null,
  invoiceChanges: {
    items: [],
    orderId: '',
    invoiceNumber: '',
    reason: 'Missing item',
    memo: '',
    additionalCredits: [],
  },
  isEditing: false,
  isLoading: false,
  isError: false,
  showOrderAccept: false,
  showOrderReject: false,
  showOrderChangeConfirmation: false,
  showInProgressMessage: false,
  showRemoveItemWarning: false,
  isShowingInvoiceModal: false,
  isShowingPackingSlipModal: false,
  isShowingReplacementOrderModal: false,
  isQBDSyncRequestSent: false,
  isProcessingCredit: false,
  notifyUser: {
    isNotify: false,
    isWarning: false,
    toasterMessage: '',
  },
  invoiceChangeError: { isNotify: false, title: '' },
  isLoadingReplacement: false,
  isReplacingOrder: false,
  customItems: [],
};

// whether to deal with the item as a packaging or a per unit
export const isPackageCheck = (item: OrderItem): boolean => item?.unitQuantity == null || (item?.originalUnitQuantity === 1 && (item?.unit === 'case' || item?.unit === 'each'));

export const calculateCredit = (unchangedItem: OrderItem, item: OrderItem): number => {
  if (unchangedItem && item) {
    if (isPackageCheck(item)) {
      return ((unchangedItem.packagingQuantity || 0) - (item.quantity || 0)) * (unchangedItem?.packagingPrice || 0);
    }

    return ((unchangedItem.unitQuantity || 0) - (item.unitQuantity || 0)) * (unchangedItem?.unitPrice || 0);
  }
  return 0;
};

export const itemUpdatedFromCreditMemos = (invoice: InvoiceContainer, orderItem: OrderItem): OrderItem => {
  // update the item quantity after adjustments
  const allItems = invoice.orderDetails?.creditNotes
    ?.map((note) => note.items)
    .flat()
    .filter((item) => item?.externalId === orderItem.externalId);
  if (!allItems?.length) {
    return orderItem;
  }

  return {
    ...orderItem,
    /*
     * The credit memo values (for quantity and weight) sent from BE are the difference (new - original).
     * In case there was no change in a field, the sent value is 0 currently,
     * but before we used to send the original value which is positive.
     * The logic here should ignore any positive values just in case.
     */
    // updated quantity
    packagingQuantity: allItems?.reduce(
      (accumulator, creditItem) => (creditItem?.quantity < 0 ? accumulator + creditItem?.quantity : accumulator),
      orderItem.packagingQuantity || 0
    ),
    // updated unit quantity
    unitQuantity: orderItem.unitQuantity
      ? allItems?.reduce(
          (accumulator, creditItem) =>
            creditItem?.unitQuantity < 0 ? accumulator + creditItem?.unitQuantity : accumulator,
          orderItem.unitQuantity
        )
      : undefined,
  };
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetState: () => initialState,
    getOrderDetailsStart: (state) => ({
      ...state,
      isLoading: true,
      isError: false,
      isEditing: false,
      invoiceChanges: initialState.invoiceChanges, // resets order details changes on next fetch
      invoiceChangeError: initialState.invoiceChangeError,
    }),
    getOrderDetailsSuccess: (state: InvoiceContainer, action) => {
      action.payload.orderDetails.items = action.payload.orderDetails.items.map((item: OrderItem) => ({
        ...item,

        imageUrl: item.imageUrl,

        /*
         * to show a 0 instead of empty price.
         * this is fine because we do not depend on unitPrice
         * anymore in checking whether an item is weight adjustable.
         */
        unitPrice: item.unitPrice || 0,

        originalUnitQuantity: (item.originalUnitQuantity || 1) / (item.originalPackagingQuantity || 1),
      }));

      // adjust credit note items so we can reuse the components
      if (action.payload.orderDetails.creditNotes) {
        action.payload.orderDetails.creditNotes = action.payload.orderDetails.creditNotes.map((note: CreditNote) => ({
          ...note,
          items: note.items.map((item: CreditNoteItem) => {
            const originalItem = action.payload.orderDetails.items.find(
              (originalOrderItem: OrderItem) => originalOrderItem.id === item.orderProductId
            );

            return {
              imageUrl: originalItem?.imageUrl,
              externalId: originalItem ? originalItem.externalId : '',
              packagingDescription: originalItem ? originalItem.packagingDescription : '',
              name: item.description,
              orderProductId: item.orderProductId,

              // although the price is fetched, it is better to fetch it from the original item
              // because it is a read-only at this stage
              packagingPrice: originalItem.packagingPrice,
              unitPrice: originalItem.unitPrice,

              packagingQuantity: item.quantity,
              quantity: item.quantity,
              unitQuantity: item.unitQuantity,
              unit: originalItem.unit,
              total: item.total,
              subtotal: item.subtotal,
              id: item.id,
            };
          }),
        }));
      }

      action.payload.orderDetails.replacements = action.payload.orderDetails.replacements
        ? action.payload.orderDetails.replacements.map((replacement: Order) => ({
            ...replacement,
            items: replacement?.items?.map((item) => ({
              ...item,
              imageUrl: item.imageUrl,

              /*
               * to show a 0 instead of empty price.
               * this is fine because we do not depend on unitPrice
               * anymore in checking whether an item is weight adjustable.
               */
              unitPrice: item.unitPrice || 0,
            })),
          }))
        : [];

      return {
        ...state,
        orderDetails: action.payload.orderDetails,
        isLoading: false,
        isError: false,
        isEditing: false,
        invoiceChanges: {
          ...state.invoiceChanges,
          orderId: action.payload.orderDetails.orderId,
          invoiceNumber: action.payload.orderDetails.invoiceNumber,
        },
      };
    },
    getOrderDetailsFailure: (state) => ({
      ...state,
      isLoading: false,
      isError: true,
      isEditing: false,
    }),
    enableOrderDetailsEditMode: (state) => ({
      ...state,
      isEditing: true,
    }),
    disableOrderDetailsEditMode: (state) => ({
      ...state,
      isEditing: false,
      invoiceChanges: {
        ...initialState.invoiceChanges,
        orderId: state.invoiceChanges.orderId,
        invoiceNumber: state.invoiceChanges.invoiceNumber,
      },
    }),
    showOrderDetailsAcceptMessage: (state) => ({
      ...state,
      showOrderAccept: true,
    }),
    hideOrderDetailsAcceptMessage: (state) => ({
      ...state,
      showOrderAccept: false,
    }),
    showChangeOrderConfirmation: (state) => ({
      ...state,
      showOrderChangeConfirmation: true,
    }),
    hideChangeOrderConfirmation: (state) => ({
      ...state,
      showOrderChangeConfirmation: false,
    }),
    showInProgressMessage: (state) => ({
      ...state,
      showInProgressMessage: true,
    }),
    hideInProgressMessage: (state) => ({
      ...state,
      showInProgressMessage: false,
    }),
    toggleInvoiceModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isShowingInvoiceModal: action.payload,
    }),
    showPackingSlipModal: (state) => ({
      ...state,
      isShowingPackingSlipModal: true,
    }),
    hidePackingSlipModal: (state) => ({
      ...state,
      isShowingPackingSlipModal: false,
    }),
    toggleReplacementOrderModal: (state, action) => ({
      ...state,
      isShowingReplacementOrderModal: action.payload,
      isLoadingReplacement: false,
    }),
    updateDeliveryDate: (state: InvoiceContainer, action) => {
      state.invoiceChanges.deliveryDate = action.payload;
      return state;
    },
    updateOrderItem: (state: InvoiceContainer, action) => {
      const { id, packagingPrice, packagingQuantity, unitPrice, unitQuantity } = action.payload;

      // make sure we have the edited items
      if (!state.orderDetails || !state.orderDetails.items?.length) {
        return state;
      }

      const foundItemOriginal = state.orderDetails.items.find((item) => item.id === id);

      if (!foundItemOriginal) {
        return state;
      }

      const foundItem = itemUpdatedFromCreditMemos(state, foundItemOriginal);

      const itemChanges = state.invoiceChanges.items.find((item) => item.orderProductId === id);

      let unitQuantityNewValue = unitQuantity;

      /*
       * check if packagingQuantity has changed by user:
       * if item exists in change list -> check there
       * if not, check in items list
       */
      const hasQuantityChanged =
        itemChanges?.packagingQuantity !== undefined
          ? packagingQuantity !== itemChanges?.packagingQuantity
          : packagingQuantity !== foundItem.packagingQuantity;

      /*
       * check if unitQuantity has changed by user:
       * if item exists in change list -> check there
       * if not, check in items list
       */
      const hasWeightChanged =
        itemChanges?.unitQuantity || itemChanges?.unitQuantity === 0
          ? unitQuantity !== itemChanges?.unitQuantity
          : unitQuantity !== foundItem.unitQuantity;

      /*
       * if quantity has changed -> change total weight (always)
       * if the weight has changed, skip this if statement
       * otherwise the weight will be reverted back
       */
      if (
        !isPackageCheck(foundItem) &&
        packagingQuantity >= 0 &&
        foundItem.originalUnitQuantity &&
        hasQuantityChanged &&
        !hasWeightChanged
      ) {
        unitQuantityNewValue = foundItem.originalUnitQuantity * packagingQuantity;
      }

      /*
       * Valudate that the prices and QTY are corret otherwise rest them
       */
      const newPackagingPrice =
        packagingPrice === undefined || packagingPrice === null || packagingPrice < 0
          ? foundItem.packagingPrice
          : packagingPrice;
      const newPackagingQuantity =
        packagingQuantity === undefined || packagingQuantity === null || packagingQuantity < 0
          ? foundItem.packagingQuantity
          : packagingQuantity;
      const newUnitPrice =
        unitPrice === undefined || unitPrice === null || unitPrice < 0 ? foundItem.unitPrice : unitPrice;

      // should allow 0 unit quantity
      const unitQuantityToBeSent = unitQuantityNewValue >= 0 ? unitQuantityNewValue : foundItem.unitQuantity;

      const orderItemUpdateFields = {
        packagingPrice: newPackagingPrice,
        packagingQuantity: newPackagingQuantity,
        unitPrice: newUnitPrice,
        unitQuantity: unitQuantityToBeSent,
        unit: foundItem.unit,

        // fields used by BE to create credit memos
        quantity: newPackagingQuantity,
        description:
          isPackageCheck(foundItem) || !foundItem.unitQuantity
            ? undefined
            : `Weight Adjustment - ${foundItem.name} ${round(unitQuantityToBeSent - foundItem.unitQuantity)}${
                foundItem.unit
              }`,
      };

      // item may already be in the list of changed items
      // add it here if it's not
      let updatedItems = state.invoiceChanges.items;
      if (!state.invoiceChanges.items?.find((item) => item.orderProductId === id)) {
        if (updatedItems) {
          updatedItems.push({
            orderProductId: id,
            ...orderItemUpdateFields,
          });
        }
      } else {
        // update only the current item, just in case it was already in the list
        updatedItems = state.invoiceChanges.items.map((item) => {
          let newItem = item;

          if (newItem.orderProductId === id) {
            newItem = {
              orderProductId: id,
              ...orderItemUpdateFields,
            };
          }

          return newItem;
        });
      }

      if (updatedItems) {
        state.invoiceChanges.items = updatedItems;
      }

      return state;
    },
    addOrderMessage: (state: InvoiceContainer, action) => {
      state.invoiceChanges.messageToCustomer = action.payload.orderMessage;
      if (state.orderDetails) {
        state.orderDetails.orderMessage = action.payload.orderMessage;
      }

      return state;
    },
    addToOrderCredit: (state: InvoiceContainer, action) => {
      state.invoiceChanges.additionalCredits.push({
        id: `new_${nanoid()}`,
        description: action.payload.creditName,
        amount: action.payload.creditAmount,
        quantity: 1,
      });

      return state;
    },
    removeCreditFromOrder: (state: InvoiceContainer, action) => {
      state.invoiceChanges.additionalCredits = state.invoiceChanges.additionalCredits.filter(
        (credit) => credit.id !== action.payload
      );
      return state;
    },
    editOrderCredit: (state: InvoiceContainer, action) => {
      // the additionalCredits endpoint expects an array but we don't allow
      // the user to add more than 1 additional credit
      state.invoiceChanges.additionalCredits = state.invoiceChanges.additionalCredits.map((credit) => {
        if (credit.id === action.payload.id) {
          return action.payload;
        }

        return credit;
      });

      return state;
    },
    updateReason: (state: InvoiceContainer, action) => {
      state.invoiceChanges.reason = action.payload;
    },
    updateMemo: (state: InvoiceContainer, action) => {
      state.invoiceChanges.memo = action.payload;
    },
    sendQBDSyncRequest: (state: InvoiceContainer) => {
      if (state.orderDetails?.allowSyncWithThirdParty) {
        try {
          const orderId = state.orderDetails?.orderId;
          if (orderId) {
            sendQBDSyncRequestAPI(orderId);
          }
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
        }
      }

      return state;
    },
    markSendQBDRequest: (state: InvoiceContainer) => {
      state.isQBDSyncRequestSent = true;
      return state;
    },
    toggleIsProcessingCredit: (state: InvoiceContainer) => {
      state.isProcessingCredit = !state.isProcessingCredit;
      return state;
    },
    toggleHasNotifyUser: (state: InvoiceContainer, action: PayloadAction<NotifyUser>) => {
      state.notifyUser = action.payload;

      return state;
    },
    toggleInvoiceChangeError: (state: InvoiceContainer, action: PayloadAction<ConflictNotifier>) => {
      state.invoiceChangeError = action.payload;
      return state;
    },
    toggleIsLoadingReplacement: (state: InvoiceContainer) => {
      state.isLoadingReplacement = !state.isLoadingReplacement;
    },
    toggleIsReplacingOrder: (state: InvoiceContainer) => {
      state.isReplacingOrder = !state.isReplacingOrder;
    },
    updateInvoiceNote: (state: InvoiceContainer, action) => ({
      ...state,
      orderDetails: {
        ...state.orderDetails,
        supplierNote: action.payload,
      },
    }),
    setCustomItems: (state: InvoiceContainer, action) => {
      state.customItems = action.payload;
      return state;
    },
  },
});

export const {
  enableOrderDetailsEditMode,
  disableOrderDetailsEditMode,
  showOrderDetailsAcceptMessage,
  hideOrderDetailsAcceptMessage,
  updateOrderItem,
  addOrderMessage,
  addToOrderCredit,
  hideChangeOrderConfirmation,
  showChangeOrderConfirmation,
  hideInProgressMessage,
  updateDeliveryDate,
  removeCreditFromOrder,
  editOrderCredit,
  resetState,
  updateReason,
  updateMemo,
  showPackingSlipModal,
  hidePackingSlipModal,
  toggleInvoiceModal,
  toggleReplacementOrderModal,
  sendQBDSyncRequest,
  markSendQBDRequest,
  toggleIsProcessingCredit,
  toggleHasNotifyUser,
  toggleInvoiceChangeError,
  updateInvoiceNote,
} = invoiceSlice.actions;

export const fetchInvoice = (orderId?: string): AppThunk => async (dispatch) => {
  if (orderId === undefined) return;

  try {
    dispatch(invoiceSlice.actions.getOrderDetailsStart());

    const orderDetails = await invoiceAPIs.fetchInvoice(orderId);

    dispatch(invoiceSlice.actions.getOrderDetailsSuccess({ orderDetails }));

    const customItems = await getCustomItems(
      orderDetails.orderId || '',
      orderDetails.buyerId as string
    );

    dispatch(invoiceSlice.actions.setCustomItems(customItems));
  } catch (error) {
    dispatch(invoiceSlice.actions.getOrderDetailsFailure());
    throw error;
  }
};

export const markOrderFulfilled = (urlsafe: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();

    const orderDetails = await fulfillOrder(urlsafe, state.invoice.orderDetails?.lastFetchTimestamp);

    dispatch(invoiceSlice.actions.getOrderDetailsSuccess({ orderDetails }));
    if (orderDetails?.invoiceNumber && orderDetails.thirdPartyIntegrationStatus?.toLowerCase() === 'unsent') {
      dispatch(invoiceSlice.actions.sendQBDSyncRequest());
    }

    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        toasterMessage: `Order #${orderDetails?.orderNumber} has been marked as fulfilled.`,
      })
    );
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          invoiceSlice.actions.toggleInvoiceChangeError({
            isNotify: true,
            title: 'Cannot mark order as fulfilled',
          })
        );
      });
    } else {
      batch(() => {
        dispatch(
          invoiceSlice.actions.toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(fetchInvoice(getState().invoice.orderDetails.invoiceNumber));
      });
    }
    logException(error as Error);
    throw error;
  }
};

export const updateOrder = (): AppThunk => async (dispatch, getState) => {
  const state = getState().invoice;
  try {
    dispatch(toggleIsProcessingCredit());
    const updatedInvoice = await invoiceAPIs.adjustInvoice(
      {
        ...state.invoiceChanges,
        // remove temporary ids
        additionalCredits: state.invoiceChanges.additionalCredits.map((credit: AdditionalCreditItem) => {
          const newCredit = {
            amount: credit.amount,
            description: credit.description,
            quantity: credit.quantity,
          };
          return newCredit;
        }),
      },
      state.orderDetails?.lastFetchTimestamp
    );

    dispatch(toggleIsProcessingCredit());
    dispatch(invoiceSlice.actions.getOrderDetailsStart());
    dispatch(invoiceSlice.actions.hideChangeOrderConfirmation());
    dispatch(
      invoiceSlice.actions.getOrderDetailsSuccess({
        orderDetails: updatedInvoice,
      })
    );
    dispatch(
      invoiceSlice.actions.toggleHasNotifyUser({
        toasterMessage: `Order #${state.orderDetails.orderNumber} has been saved.`,
        isNotify: true,
      })
    );
  } catch (err) {
    if (err?.response?.status === 409) {
      dispatch(toggleIsProcessingCredit());
      dispatch(
        invoiceSlice.actions.toggleInvoiceChangeError({
          isNotify: true,
          title: 'Cannot process credit',
        })
      );
      dispatch(invoiceSlice.actions.hideChangeOrderConfirmation());
    } else {
      dispatch(toggleIsProcessingCredit());
      dispatch(invoiceSlice.actions.hideChangeOrderConfirmation());
    }
  }
};

export const hideDiscardMessage = (): AppThunk => (dispatch) => {
  dispatch(setIsShowingCreateOrder(false));
  dispatch(setIsShowingDiscardOrder(false));
};

export const discardChanges = (): AppThunk => (dispatch) => {
  dispatch(setIsShowingDiscardOrder(false));
  dispatch(disableOrderDetailsEditMode());
};

export const markInvoicePaid = (urlsafe: string): AppThunk => async (dispatch, getState) => {
  const state = getState().invoice;
  try {
    const orderDetails = await invoiceAPIs.markInvoicePaid(urlsafe, state.orderDetails?.lastFetchTimestamp);

    dispatch(invoiceSlice.actions.getOrderDetailsSuccess({ orderDetails }));
    dispatch(
      invoiceSlice.actions.toggleHasNotifyUser({
        toasterMessage: `Order #${state.orderDetails.orderNumber} has been marked as paid.`,
        isNotify: true,
      })
    );
  } catch (error) {
    if (error?.response?.status === 409) {
      dispatch(
        invoiceSlice.actions.toggleInvoiceChangeError({
          isNotify: true,
          title: 'Cannot mark order as paid',
        })
      );
    }
  }
};

export const syncInvoiceNote = (note: string): AppThunk => async (dispatch, getState) => {
  const state = getState().invoice;
  try {
    dispatch(invoiceSlice.actions.getOrderDetailsStart());
    await invoiceAPIs.updateSupplierNoteForInvoice(
      state.orderDetails?.invoiceNumber,
      state.orderDetails?.lastFetchTimestamp,
      note
    );
    batch(() => {
      dispatch(
        invoiceSlice.actions.toggleHasNotifyUser({
          isNotify: true,
          toasterMessage: 'Order note was updated successfully.',
        })
      );
      dispatch(fetchInvoice(state.orderDetails.invoiceNumber));
    });
  } catch (e) {
    if (e?.response?.status === 409) {
      dispatch(
        invoiceSlice.actions.toggleInvoiceChangeError({
          isNotify: true,
          title: 'Cannot update order note',
        })
      );

      dispatch(
        invoiceSlice.actions.getOrderDetailsSuccess({
          orderDetails: { ...state.orderDetails },
        })
      );
    } else {
      dispatch(
        invoiceSlice.actions.toggleHasNotifyUser({
          isNotify: true,
          isWarning: true,
          toasterMessage: 'Something went wrong.',
        })
      );

      dispatch(fetchInvoice(state.orderDetails.orderId));
    }
  }
};

export const replaceOrder = (): AppThunk => async (dispatch, getState) => {
  const state = getState().invoice;
  try {
    dispatch(invoiceSlice.actions.toggleIsLoadingReplacement());
    await invoiceAPIs.isInvoiceUpdated(state.orderDetails.invoiceNumber, state.orderDetails.lastFetchTimestamp);
    dispatch(invoiceSlice.actions.toggleIsReplacingOrder());
  } catch (error) {
    if (error?.response?.status === 409) {
      dispatch(
        invoiceSlice.actions.toggleInvoiceChangeError({
          isNotify: true,
          title: 'Cannot create a replacement order',
        })
      );

      dispatch(invoiceSlice.actions.toggleReplacementOrderModal(false));
    } else {
      dispatch(
        invoiceSlice.actions.toggleHasNotifyUser({
          isNotify: true,
          isWarning: true,
          toasterMessage: 'Something went wrong.',
        })
      );
      dispatch(invoiceSlice.actions.toggleIsLoadingReplacement());
    }
  }
};
