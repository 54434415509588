import React, { FunctionComponent } from 'react';

import cx from 'classnames';
import LinkButton from 'domains/shared/components/button/LinkButton';

export interface ModelProps {
  showDisplayingModal: boolean;
  onToggle: (isShowingModal: boolean) => void;
  children: React.ReactElement;
  className?: string;
  headerText?: string | null;
  bodyText?: string | React.ReactElement | null;
  dataTestid?: string;
  showCloseIcon?: boolean;
}

const Modal: FunctionComponent<ModelProps> = ({
  children,
  onToggle,
  className = '',
  showDisplayingModal = false,
  headerText = null,
  bodyText = null,
  dataTestid = 'model',
  showCloseIcon = true,
}: ModelProps): JSX.Element | null => {
  if (!showDisplayingModal) return null;

  return (
    <div
      data-testid={dataTestid}
      className={cx('modal', 'custom-modal', className, {
        'is-active': showDisplayingModal,
        'is-clipped': showDisplayingModal,
      })}
    >
      <LinkButton
        dataTestid={`${dataTestid}-modal-background`}
        className="modal-background"
        onClick={() => onToggle(false)}
        componentContianer="div"
      />

      <section className="modal-content" data-testid={`${dataTestid}-content`}>
        {headerText && (
          <header className="header">
            <h2 className="header-text">{headerText}</h2>
          </header>
        )}

        {bodyText && (
          <div className="modal-message">
            <span className="modal-message-text">{bodyText}</span>
          </div>
        )}

        {children}
        {showCloseIcon && (
          <LinkButton
            dataTestid={`${dataTestid}-modal-close`}
            className="modal-close is-large"
            onClick={() => onToggle(false)}
          >
            X
          </LinkButton>
        )}
      </section>
    </div>
  );
};

export default Modal;
