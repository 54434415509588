import React, { JSXElementConstructor } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from 'domains/api/apiAuth';

export interface PrivateRouteProps {
  path: string;
  component: JSXElementConstructor<{}>;
  exact: boolean;
}

// A wrapper for <Route> that redirects to the login screen if user is not yet authenticated.
const PrivateRoute = ({ path, component: Component, exact: isExactMatch = false }: PrivateRouteProps): JSX.Element => (
  <Route
    exact={isExactMatch}
    path={path}
    render={({ location }): JSX.Element =>
      isAuthenticated() ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location,
            },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
