import React from 'react';

import images from 'config';

export interface LogoProps {
  frontendName: string;
}

const Logo = ({ frontendName }: LogoProps): JSX.Element => (
  <div className="logo-container">
    <img src={images.logo} alt="notch" className="image" width={108} height={28} />
    <span className="text">{frontendName}</span>
  </div>
);

export default Logo;
