import apiClient from '../api/apiAuth';

import { Notification } from './types/Notification';

export const getNotifications = async (): Promise<Notification[]> => {
  try {
    const response = await apiClient().get('/notifications');

    const data: Notification[] | null = await response.data;

    return data && Object.keys(data).length > 0 ? data : [];
  } catch (e) {
    return [];
  }
};

export const markAsRead = async (notificationId: string): Promise<void> => {
  await apiClient().patch(`/notifications/${notificationId}/read`);
};
