import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useFeatures from 'domains/hooks/useFeatures.hook';

const Orders = lazy(() => import('domains/Orders/OrdersContainer'));
const OrderDetail = lazy(() => import('domains/OrderDetails/OrderDetailsContainer'));
const Invoice = lazy(() => import('domains/Invoice/InvoiceContainer'));
const Clients = lazy(() => import('domains/Clients'));
const Settings = lazy(() => import('domains/Settings/SettingsContainer'));
const Inbox = lazy(() => import('domains/Inbox/Inbox'));

const AppBodyRoutes = (): JSX.Element => {
  const {
    features: { isChatEnabled },
  } = useFeatures();
  return (
    <Suspense fallback={<div className="order-container">&nbsp;</div>}>
      <Switch>
        <Route path="/orders" component={Orders} exact />
        <Route path="/orders/loading/:on" component={Orders} exact />
        <Route path="/orders/create" component={OrderDetail} exact />
        <Route path="/orders/:urlsafe/" component={OrderDetail} exact />
        <Route path="/orders/:urlsafe/:accept" component={OrderDetail} exact />
        <Route path="/invoices/:urlsafe" component={Invoice} exact />
        <Route path="/clients" component={Clients} exact />
        <Route path="/settings" component={Settings} exact />
        {isChatEnabled && <Route path="/inbox/:channelId?" component={Inbox} exact />}
        <Redirect to="/orders" />{' '}
        {/* Make sure we don't have other routes declared in other file. If so, remove this line */}
      </Switch>
    </Suspense>
  );
};
export default React.memo(AppBodyRoutes);
