import apiClient from '../../api/apiAuth';
import { Settings } from '../types/Settings';

export const fetchSettings = async (): Promise<Settings> => {
  const response = await apiClient().get(`/settings/`);
  return response.data;
};

export const saveSettings = async (newSettings: Settings): Promise<Settings> => {
  const response = await apiClient().put(`/settings?lft=${newSettings.lastFetchTimestamp}`, newSettings);
  return response.data;
};
