import moment from 'moment';
import { dateToString } from '../../shared/lib/util';

import DateFilter from '../types/DateFilter';

const getStartEndDate = (dateFilter: DateFilter): { startDate: string; endDate: string } => {
  const dates = {
    startDate: dateToString(moment()),
    endDate: dateToString(moment()),
  };

  const day: moment.unitOfTime.DurationConstructor = 'day';
  const month: moment.unitOfTime.DurationConstructor = 'month';
  const week: moment.unitOfTime.DurationConstructor = 'week';
  switch (dateFilter) {
    case DateFilter.Tomorrow:
      dates.startDate = dateToString(moment().add(1, day));
      dates.endDate = dateToString(moment().add(1, day));
      break;
    case DateFilter.Yesterday:
      dates.startDate = dateToString(moment().subtract(1, day));
      dates.endDate = dateToString(moment().subtract(1, day));
      break;
    case DateFilter.ThisMonth:
      dates.startDate = dateToString(moment().startOf('month'));
      dates.endDate = dateToString(moment().add(1, month).startOf('month').subtract(1, day));
      break;
    case DateFilter.LastMonth:
      dates.startDate = dateToString(moment().subtract(1, month).startOf('month'));
      dates.endDate = dateToString(moment().startOf('month').subtract(1, day));
      break;
    case DateFilter.ThisWeek:
      dates.startDate = dateToString(moment().startOf('week'));
      dates.endDate = dateToString(moment().add(1, week).startOf('week').subtract(1, day));
      break;
    case DateFilter.LastWeek:
      dates.startDate = dateToString(moment().startOf('week').subtract(1, week));

      dates.endDate = dateToString(moment().startOf('week').subtract(1, day));
      break;
    case DateFilter.Today:
      break;
    default:
      return { startDate: '', endDate: '' };
  }

  return dates;
};

export default getStartEndDate;
