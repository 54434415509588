/* eslint-disable @typescript-eslint/no-explicit-any */
import { Client } from 'twilio-chat';
import { useCallback, useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getAccountEmail } from 'domains/api/apiAuth';
import { logException, logMessage } from 'domains/shared/lib/logger';
import { Channel } from 'twilio-chat/lib/channel';
import {
  addChannelAction,
  addMessageAction,
  appendMessageAction,
  getAccessTokenAction,
  removeChannelAction,
  removeMessageAction,
  setConnectionStatusAction,
  unreadMsgAction,
  updateChannelAction,
} from '../redux/inboxSlice';
import inboxSelectors from '../redux/inboxSelectors';
import useChatChannel from './useChatChannel.hook';
import useChatMessage from './useChatMessage.hook';
import { ChannelMembers } from '../types';
import { getChannelPayload, prepareMessages } from '../utils/chatHelper';

const useChat = (): {
  getAccessToken: () => void;
  channelsTwilio: any;
} => {
  // This holds the instance of Twilio Chat
  const [chatClient, setChatClient] = useState(null as null | Client);
  const [channelsTwilio, updateChannels] = useState(() => ({}));
  const { accessToken } = useSelector(inboxSelectors.authSelector);
  const {
    channel: { list },
  } = useChatChannel();
  const {
    messages: { isSending },
  } = useChatMessage();
  const dispatch = useAppDispatch();
  const getAccessToken = useCallback(() => {
    dispatch(getAccessTokenAction());
  }, [dispatch]);

  const getChannelMembers = async (channel: Channel): Promise<ChannelMembers> => {
    const channelMembers: ChannelMembers = {};
    const members = await channel.getUserDescriptors();
    members.items.forEach(({ identity, friendlyName }) => {
      channelMembers[identity] = friendlyName || identity;
    });
    return channelMembers;
  };

  const channelAdded = useCallback(
    async (channel: Channel) => {
      const channelMembers = await getChannelMembers(channel);
      const payload = await getChannelPayload(channel, channelMembers);
      dispatch(addChannelAction(payload));
      updateChannels((state: any) => ({
        ...state,
        [channel.sid]: channel,
      }));

      // An event to know when new Chat message is added to the Chat channel
      channel.on('messageAdded', async (message) => {
        const chatMessages = await prepareMessages([message]);
        dispatch(appendMessageAction(channel.sid, chatMessages));
      });

      const messageList = await channel.getMessages(50);
      let messages;
      if (messageList && messageList.items && messageList.items.length) {
        messages = messageList.items;
      }
      if (messages) {
        const chatMessages = await prepareMessages(messages);
        dispatch(addMessageAction(channel.sid, chatMessages));
      }
    },
    [dispatch]
  );

  const updateChannel = useCallback(
    async ({ channel }: { channel: Channel }) => {
      const channelMembers = await getChannelMembers(channel);
      const payload = await getChannelPayload(channel, channelMembers);
      dispatch(updateChannelAction(channel.sid, payload));
      updateChannels((state: any) => ({
        ...state,
        [channel.sid]: channel,
      }));
    },
    [dispatch]
  );

  const channelRemoved = useCallback(
    (channel: Channel) => {
      batch(() => {
        dispatch(removeChannelAction(channel.sid));
        dispatch(removeMessageAction(channel.sid));
        updateChannels((state: any) => {
          const newState = { ...state };
          if (newState[channel.sid]) {
            delete newState[channel.sid];
          }
          return newState;
        });
      });
    },
    [dispatch]
  );

  const connectionStateChanged = useCallback(
    (status: string) => {
      dispatch(setConnectionStatusAction(status));
    },
    [dispatch]
  );

  /**
   * Creates or Updates the chat client object with access token
   */
  const createOrUpdateChatClient = useCallback(async () => {
    try {
      if (!chatClient) {
        const client = await Client.create(accessToken);
        client.on('connectionStateChanged', connectionStateChanged);
        client.on('tokenAboutToExpire', getAccessToken);
        client.on('tokenExpired', getAccessToken);
        client.on('channelAdded', channelAdded);
        client.on('channelUpdated', updateChannel);
        client.on('channelRemoved', channelRemoved);
        setChatClient(client);
      } else {
        await chatClient?.updateToken(accessToken);
      }
    } catch (error) {
      logMessage(`Error occured for user: ${getAccountEmail()} while create/updating chat client: ${chatClient}`);
      logException(error);
    }
  }, [accessToken, channelAdded, channelRemoved, chatClient, connectionStateChanged, getAccessToken, updateChannel]);

  useEffect(() => {
    if (accessToken) {
      createOrUpdateChatClient();
    }
  }, [accessToken, createOrUpdateChatClient]);

  useEffect(
    () => () => {
      if (chatClient) {
        chatClient.shutdown();
      }
    },
    [chatClient]
  );

  // if channel list exists, calculate unread messages
  // whenever channel is updated or created or removed
  useEffect(() => {
    if (Object.keys(list).length && !isSending) {
      dispatch(unreadMsgAction());
    }
  }, [list, isSending, dispatch]);

  return {
    getAccessToken,
    channelsTwilio,
  };
};

export default useChat;
