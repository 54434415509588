import React from 'react';

import cx from 'classnames';

import images from 'config';

export interface ItemImageProps {
  alt: string;
  dataTestid: string;
  src?: string;
  className?: string;
}

const ItemImage = ({ src, alt, dataTestid, className = undefined }: ItemImageProps): JSX.Element => (
  <div
    className={cx('item-image-container', {
      'placeholder-container': !src,
    })}
  >
    {src ? (
      <img
        data-testid={dataTestid}
        className={cx('item-image', className)}
        src={src}
        alt={alt}
        width={56}
        height={56}
      />
    ) : (
      <img
        data-testid={`${dataTestid}-no-image`}
        className="placeholder"
        src={images.placeholder}
        alt={alt}
        width={24}
        height={24}
      />
    )}
  </div>
);

export default ItemImage;
