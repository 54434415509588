import { AppState } from 'types/AppState';

const selectors = {
  isShowingDiscardOrder: ({ app }: AppState): boolean => app.isShowingDiscardOrder,
  isShowingCreateOrder: ({ app }: AppState): boolean => app.isShowingCreateOrder,
  isMobileMenuOpen: ({ app }: AppState): boolean => app.isMobileMenuOpen,
  isMenuOpen: ({ app }: AppState): boolean => app.isMenuOpen,
  animateClose: ({ app }: AppState): boolean => app.animateClose,
};

export default selectors;
