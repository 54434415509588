import React from 'react';
import cx from 'classnames';

export interface TagProps {
  type: 'fulfilled' | 'unpaid' | 'placed' | 'rejected' | 'updated' | 'added' | 'issue-reported' | 'order-type';
  text: string | JSX.Element;
  isSmall?: boolean;
}

const Tag = ({ type, text, isSmall = false }: TagProps): JSX.Element => {
  const className = `custom-tag ${[type]}`;
  return <div className={cx({ [className]: true, 'is-small': isSmall })}>{text}</div>;
};

export default Tag;
