import React from 'react';

export interface SkeletonProps {
  width: string;
  height: string;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
}

const Skeleton = ({ width, height, backgroundColor, className, borderRadius }: SkeletonProps): JSX.Element => {
  let newBackgroundColor = backgroundColor;
  let newBorderRadius = borderRadius;

  if (!newBackgroundColor) {
    newBackgroundColor = '#d8d8d8';
  }

  if (!newBorderRadius) {
    newBorderRadius = '1px';
  }

  return (
    <div
      className={`placement-skeleton ${className || ''}`}
      style={{
        width,
        height,
        backgroundColor: newBackgroundColor,
        borderRadius: newBorderRadius,
      }}
    />
  );
};

export default Skeleton;
