import { combineReducers } from '@reduxjs/toolkit';

import { invoiceSlice } from 'domains/Invoice/redux/invoiceSlice';

import { ordersSlice } from 'domains/Orders/redux/ordersSlice';
import { orderDetailsSlice } from 'domains/OrderDetails/redux/orderDetailsSlice';

import { notificationSlice } from 'domains/Notification/redux/notificationSlice';
import { sessionSlice } from 'domains/Session/redux/sessionSlice';

import { inboxSlice } from 'domains/Inbox/redux/inboxSlice';
import { settingsSlice } from '../domains/Settings/redux/settingsSlice';

import { appSlice } from './appSlice';
import { appFeaturesSlice } from './appFeatures/appFeaturesSlice';

// need to use combineReducers to get the type of rootReducer
const rootReducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [orderDetailsSlice.name]: orderDetailsSlice.reducer,
  [invoiceSlice.name]: invoiceSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [inboxSlice.name]: inboxSlice.reducer,
  [appFeaturesSlice.name]: appFeaturesSlice.reducer,
});

export default rootReducer;
