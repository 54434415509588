import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { createChannelAction } from '../redux/inboxSlice';
import { ChatChannelBody, InboxChannel } from '../types';
import inboxSelectors from '../redux/inboxSelectors';

const useChatChannel = (): {
  channel: InboxChannel;
  createChannel: (data: ChatChannelBody, push: (path: string, state?: unknown) => void) => void;
} => {
  const channel = useSelector(inboxSelectors.channelSelector);
  const dispatch = useAppDispatch();
  const createChannel = useCallback(
    (data: ChatChannelBody, push: (path: string, state?: unknown) => void) => {
      dispatch(createChannelAction(data, push));
    },
    [dispatch]
  );

  return {
    createChannel,
    channel,
  };
};

export default useChatChannel;
