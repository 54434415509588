import React, { useState, createRef, useEffect } from 'react';

import PrintPreview from 'domains/shared/components/PrintPreview';

import { ModalState } from 'domains/shared/components/modal/types/ModalState';

import { GOOGLE_DRIVE_URL_PREFIX } from '../../../constants';

export interface InovicePrintPreviewProps {
  pdfUrl: string;
  modalState: ModalState;
  dataTestid?: string;
}

const InovicePrintPreview = ({
  pdfUrl,
  modalState,
  dataTestid = 'invoice-print-preview',
}: InovicePrintPreviewProps): JSX.Element => {
  const [isShowingLoading, setIsShowingLoading] = useState(true);
  const iframeRef = createRef<HTMLIFrameElement>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (iframeRef.current) {
        iframeRef.current.src = `${GOOGLE_DRIVE_URL_PREFIX + pdfUrl}#view=FitH`;
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [iframeRef, pdfUrl]);

  return (
    <PrintPreview pdfUrl={pdfUrl} isShowingLoading={isShowingLoading} modalState={modalState} dataTestid={dataTestid}>
      <iframe
        className="invoice-preview-iframe"
        data-testid={`iframe-${dataTestid}`}
        src={`${GOOGLE_DRIVE_URL_PREFIX + pdfUrl}#view=FitH`}
        title="iframe"
        id="iframe"
        width="100%"
        height="100%"
        onLoad={() => {
          setIsShowingLoading(false);
        }}
        ref={iframeRef}
      />
    </PrintPreview>
  );
};

export default InovicePrintPreview;
