import React from 'react';
import { OrderCustomItem } from 'domains/Orders/types';
import { round } from 'domains/shared/lib/util';

interface CustomItemProps {
  customItem: OrderCustomItem;
}

const CustomItem = ({ customItem }: CustomItemProps): JSX.Element => {
  const optionalFields = [];

  if (customItem?.externalRef) {
    optionalFields.push(customItem?.externalRef);
  }

  if (customItem?.packaging) {
    optionalFields.push(customItem?.packaging);
  }

  if (customItem?.price) {
    optionalFields.push(`$${round(customItem?.price)}`);
  }

  let optionalFieldsText = '';
  if (optionalFields.length > 0) {
    optionalFieldsText = ` (${optionalFields.join(', ')})`;
  }

  return (
    <div className="order-info">
      <div>{`${customItem?.quantity || 1} x ${customItem?.name}${optionalFieldsText}`}</div>
    </div>
  );
};

export default CustomItem;
