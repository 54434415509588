import { Order, OrderListItem } from '../types';

export const isInvoiced = (order: Order): boolean =>
  order.billingStatus?.toLowerCase() !== 'draft_invoice' &&
  (order.billingStatus?.toLowerCase() === 'paid' || order.fulfillmentStatus?.toLowerCase() === 'delivered');

export const isOrderFulfilled = (order: Order): boolean =>
  ['delivered', 'canceled'].includes(order.fulfillmentStatus?.toLowerCase() || '');

export const hasAnyFulfillments = (orders: Order[], selectedOrders: string[]): boolean =>
  orders.filter((order) => selectedOrders.includes(order.orderId || '')).some((order) => isOrderFulfilled(order));

export const hasAnyUnfulfillments = (orders: Order[], selectedOrders: string[]): boolean =>
  orders.filter((order) => selectedOrders.includes(order.orderId || '')).some((order) => !isOrderFulfilled(order));

export const allAreFulfillments = (orders: Order[], selectedOrders: string[]): boolean =>
  orders.filter((order) => selectedOrders.includes(order.orderId || '')).every((order) => isOrderFulfilled(order));

export const allAreUnfulfillments = (orders: Order[], selectedOrders: string[]): boolean =>
  orders.filter((order) => selectedOrders.includes(order.orderId || '')).every((order) => !isOrderFulfilled(order));

export const allAreFulfillmentsAndNotInvoiced = (orders: Order[], selectedOrders: string[]): boolean =>
  orders
    .filter((order) => selectedOrders.includes(order.orderId || ''))
    .every((order) => isOrderFulfilled(order) && !isInvoiced(order));

export const fulfillmentOrders = (orders: OrderListItem[], selectedOrders: string[]): OrderListItem[] =>
  orders.filter(
    (order) =>
      selectedOrders.includes(order.orderId || '') &&
      ['delivered', 'canceled'].indexOf(order.fulfillmentStatus?.toLowerCase() || '') < 0
  );

export const hasOnlyNonInvoiceOrders = (orders: Order[], selectedOrders: string[]): boolean =>
  orders
    .filter((order) => selectedOrders.includes(order.orderId || ''))
    .every(
      (order) => order.directType?.toLowerCase() === 'byos' || order.fulfillmentStatus?.toLowerCase() === 'canceled'
    );

export const removeNonInvoiceOrders = (orders: Order[], selectedOrders: string[]): string[] =>
  orders
    .filter((order) => selectedOrders.includes(order.orderId || ''))
    .filter(
      (order) => order.directType?.toLowerCase() !== 'byos' && order.fulfillmentStatus?.toLowerCase() !== 'canceled'
    )
    .map((order) => order.orderId || '');

export const removeInvoicedOrders = (orders: Order[], selectedOrders: string[]): string[] =>
  orders
    .filter((order) => selectedOrders.includes(order.orderId || ''))
    .filter(
      (order) => order.directType?.toLowerCase() !== 'byos' && order.fulfillmentStatus?.toLowerCase() !== 'canceled'
    )
    .filter((order) => !isInvoiced(order))
    .map((order) => order.orderId || '');
