import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ErrorBoundary } from 'domains/shared/components';

import { initialize as initializeIntegration } from 'integrations';
import { unregister as unregisterServiceWorker } from './serviceWorker';

import Routes from './routes/Routes';
import { store } from './store';

require('./connect_main.scss');

initializeIntegration();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('supplier-portal-root')
);

unregisterServiceWorker();
