import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import selectors from 'store/appSelectors';

import images from 'config';
import CheckboxField, { CheckboxFieldProps } from '../field/Checkbox';

interface TableColumn {
  data: React.ReactNode;
  className?: string;
  isSticky?: boolean;
  title?: string;
}

export interface TableRow {
  columns: TableColumn[];
  className?: string;
  onClick?: () => void;
}

export interface TableProps {
  columns: {
    displayName: string;
    isSticky?: boolean;
    isSortable?: boolean;
    rowClassName?: string;
  }[];
  rows: TableRow[];
  divRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  isDoneLoading: boolean;
  noDataMessage: string;
  withHeaderCheckbox?: CheckboxFieldProps;
  allCheckboxSelected?: boolean;
  onSort?: (column: string) => void;
  sort?: { column: string; direction: 'asc' | 'desc' };
  numberOfRecordsWhileLoading?: number;
}

const Table = ({
  columns,
  rows,
  divRef,
  isLoading,
  noDataMessage,
  withHeaderCheckbox,
  allCheckboxSelected,
  onSort,
  sort,
  numberOfRecordsWhileLoading = 10,
}: TableProps): JSX.Element => {
  const [coverShadow, setCoverShadow] = useState(true);

  const onHorizontalScroll = useCallback(() => {
    if (!divRef.current) {
      return;
    }

    // when the user starts scrolling we want to change the background color of the second column to transparent
    // in order to keep the the content visible
    if (divRef.current?.scrollLeft > 30) {
      setCoverShadow(false);
    } else {
      setCoverShadow(true);
    }
  }, [divRef]);

  useEffect(() => {
    const currentDivRef = divRef.current;

    if (currentDivRef) {
      currentDivRef.addEventListener('scroll', debounce(onHorizontalScroll));
    }

    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener('scroll', onHorizontalScroll);
      }
    };
  }, [onHorizontalScroll, divRef]);

  const isMenuOpen = useSelector(selectors.isMenuOpen);

  return (
    <div
      ref={divRef}
      className={cx('table-wrapper', {
        close: isMenuOpen,
        open: !isMenuOpen,
      })}
    >
      <table className="table is-fullwidth table-fixed">
        {!isLoading && rows.length > 0 && (
          <thead>
            <tr>
              {withHeaderCheckbox && (
                <th className="table-header with-checkbox">
                  <CheckboxField
                    id={withHeaderCheckbox.id}
                    name={withHeaderCheckbox.name}
                    value={withHeaderCheckbox.value}
                    checked={allCheckboxSelected || withHeaderCheckbox.checked}
                    dataTestid={withHeaderCheckbox.dataTestid}
                    disabled={withHeaderCheckbox.disabled}
                    shouldDisabledCheck={withHeaderCheckbox.shouldDisabledCheck}
                    onChange={withHeaderCheckbox.onChange}
                  />
                </th>
              )}
              {columns.map((column) => (
                <React.Fragment key={column.displayName}>
                  <th
                    className={cx({
                      'table-header': true,
                      sortable: column.isSortable,
                    })}
                    onClick={() => {
                      if (!column.isSortable) {
                        return;
                      }

                      if (onSort) {
                        onSort(column.displayName);
                      }
                    }}
                  >
                    <div>
                      <h4>{column.displayName}</h4>

                      {column.isSortable && (
                        <img
                          className={cx({
                            'sort-icon': true,
                            visible: sort?.column === column.displayName,
                            'sort-up': sort?.direction === 'asc',
                          })}
                          src={images.sortIcon}
                          alt="sort"
                          width={7}
                          height={9}
                        />
                      )}
                    </div>
                  </th>
                  {column.isSticky && (
                    <th
                      className={cx({
                        'table-header': true,
                        'cover-border': true,
                        transparent: !coverShadow,
                      })}
                    >
                      <div
                        className={cx({
                          cover: true,
                          transparent: !coverShadow,
                        })}
                        style={{
                          width: '1px',
                          height: '42px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </th>
                  )}
                </React.Fragment>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <>
            {rows.map((row, rowIdx) => (
              <tr
                key={String(rowIdx)}
                className={cx({ [row.className || '']: true })}
                onClick={() => {
                  if (row.onClick) {
                    row.onClick();
                  }
                }}
              >
                {row.columns.map((column: TableColumn, colIdx) => {
                  const { className } = column;
                  return (
                    <React.Fragment key={`row_${String(rowIdx)}_col_${String(colIdx)}`}>
                      <td
                        className={cx({
                          'table-text': true,
                          [className || '']: true,
                          sticky: column.isSticky,
                        })}
                        title={column.title || ''}
                      >
                        {column.data}
                      </td>
                      {column.isSticky && (
                        <td
                          className={cx({
                            'shadow-cover': true,
                            transparent: !coverShadow,
                          })}
                        >
                          <div
                            className={cx({
                              cover: true,
                              transparent: !coverShadow,
                            })}
                          />
                        </td>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
            {isLoading &&
              new Array(numberOfRecordsWhileLoading).fill(0).map((row, index) => (
                <tr key={String(index)}>
                  {withHeaderCheckbox && (
                    <td className="placeholder_td placeholder-with-checkbox" key={`ph_${String(index)}_${String(0)}`}>
                      <div className="placeholder" />
                    </td>
                  )}
                  {columns.map((col, innerIndex) => (
                    <React.Fragment key={`ph_${String(index)}_${String(innerIndex)}`}>
                      <td
                        className={cx('placeholder_td', {
                          [col.rowClassName || '']: true,
                        })}
                      >
                        <div className="placeholder" />
                      </td>
                      {col.isSticky && (
                        <td className="placeholder_td">
                          <div className="placeholder" />
                        </td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            {!isLoading && rows.length === 0 && (
              <tr>
                <td colSpan={columns.length + 2} className="empty-data-state">
                  {noDataMessage}
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
