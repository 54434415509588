import React from 'react';

export interface PaginationLinkProps {
  currentPage: number;
  index: number;
  onChange: (n: number) => void;
  className?: string;
}

const PaginationLink = ({ currentPage, index, onChange, className }: PaginationLinkProps): JSX.Element => (
  <li>
    <button
      type="button"
      className={`pagination-link ${className} ${
        (currentPage === index || (index === 1 && !currentPage)) && 'is-current'
      }`}
      aria-label={`Goto page ${index}`}
      aria-current={index === currentPage && 'page'}
      onClick={() => onChange(index)}
    >
      {index}
    </button>
  </li>
);

export default PaginationLink;
