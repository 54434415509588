import moment from 'moment';
import { batch } from 'react-redux';

import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

import { round, getTypeObjectKeys } from 'domains/shared/lib/util';
import { isEqual, omit } from 'lodash';

import {
  sendQBDSyncRequest as sendQBDSyncRequestAPI,
  getOrderDetails,
  markInProgress,
  fulfillOrder,
  cancelOrder,
  editOrder,
  createOrder,
  createReplacementOrder,
  markDirectAsPaid,
  getPaymentSources,
  attachCard,
  updateSupplierNote,
  getCustomer,
} from 'domains/api';
import getCustomItems from 'domains/api/catalog';

import { AppThunk } from 'types/AppThunk';
import { NotifyUser, ConflictNotifier } from 'types/Notifiers';

import {
  Customer,
  CustomerFromAutoComplate,
  Order,
  OrderCustomItem,
  OrderItem,
  PaymentSource,
  Product,
  ReplacementProduct,
} from 'domains/Orders/types';

import { resetState as InvoiceReset } from 'domains/Invoice/redux/invoiceSlice';

import { FormValues } from '../types/FormValues';
import PaymentType from '../../Orders/types/PaymentType';

interface StartNewOrderPayloadAction {
  customer: CustomerFromAutoComplate;
  parentOrder?: string;
  lastFetchTimestamp?: string;
}

export interface OrderDetails {
  orderDetails: Order | null;
  initialOrderDetails: Order | null;
  orderDetailsChanges: OrderDetailsChanges;
  formikInitialValues: FormValues;
  isEditing: boolean;
  isLoading: boolean;
  isError: boolean;
  notifyUser: NotifyUser;
  showOrderAccept: boolean;
  showOrderReject: boolean;
  showOrderChangeConfirmation: boolean;
  showOrderChangesConfrimed: boolean;
  showRemoveItemWarning: boolean;
  isSubmittingConfirmOrder: boolean;
  isShowingPackingSlipModal: boolean;
  isShowingPrintInvoiceConfirmationModal: boolean;
  isShowingInvoicePreview: boolean;
  isQBDSyncRequestSent: boolean;
  isAddingPaymentMethod: boolean;
  isLoadingCustomerDetails: boolean;
  isLoadingPaymentMethods: boolean;
  shouldRefocusItemSearchField: boolean;
  orderChangeError: ConflictNotifier;
  balanceToken: string;
  pendingOrderId: string;
  paymentId: string;
  paymentTerm: number;
  customItems: OrderCustomItem[];
}

interface OrderDetailsChanges {
  items: OrderItem[];
  deliveryDate?: string;
  messageToCustomer?: string;
}

export const initialState: OrderDetails = {
  orderDetails: null,
  initialOrderDetails: null,
  orderDetailsChanges: { items: [] },
  formikInitialValues: {
    deliveryDate: moment().format('YYYY-MM-DD'),
    items: [],
    additionalFees: [],
    messageToCustomer: '',
  },
  isEditing: false,
  isLoading: false,
  isError: false,
  notifyUser: {
    isNotify: false,
    isWarning: false,
    toasterMessage: '',
  },
  showOrderAccept: false,
  showOrderReject: false,
  showOrderChangeConfirmation: false,
  showOrderChangesConfrimed: false,
  showRemoveItemWarning: false,
  isSubmittingConfirmOrder: false,
  isShowingPackingSlipModal: false,
  isShowingPrintInvoiceConfirmationModal: false,
  isShowingInvoicePreview: false,
  isQBDSyncRequestSent: false,
  isAddingPaymentMethod: false,
  isLoadingCustomerDetails: false,
  isLoadingPaymentMethods: false,
  shouldRefocusItemSearchField: false,
  orderChangeError: { isNotify: false, title: '' },
  balanceToken: '',
  pendingOrderId: '',
  paymentId: '',
  paymentTerm: 0,
  customItems: [],
};
const newCard = 'new_card';
const card = 'card';
const newPrefix = 'new_';

// whether to deal with the item as a packaging or a per unit
export const isPackageCheck = (item: OrderItem): boolean =>
  item?.unitQuantity == null || (item?.originalUnitQuantity === 1 && (item?.unit === 'case' || item?.unit === 'each')) || item?.unit == null || item?.unit === '';

export const subtotal = (order: OrderDetails): number => {
  if (!order.isEditing && order.orderDetailsChanges.items.length === 0) {
    return order.orderDetails?.subtotal || 0;
  }
  const itemsInEdit: string[] = [];
  let sum = order.orderDetailsChanges.items.reduce((accumulator, item) => {
    itemsInEdit.push(item.id || '');

    if (isPackageCheck(item)) {
      if (!item.packagingPrice || !item.packagingQuantity) {
        return accumulator;
      }

      return accumulator + item.packagingPrice * item.packagingQuantity;
    }
    if (!item.unitPrice || !item.unitQuantity) {
      return accumulator;
    }

    return accumulator + item.unitPrice * item.unitQuantity;
  }, 0);

  sum +=
    order.orderDetails?.items?.reduce((accumulator, item) => {
      if (isPackageCheck(item)) {
        if (itemsInEdit.indexOf(item.id || '') > -1 || !item.packagingQuantity || !item.packagingPrice) {
          return accumulator;
        }

        return accumulator + item.packagingPrice * item.packagingQuantity;
      }
      if (itemsInEdit.indexOf(item.id || '') > -1 || !item.unitQuantity || !item.unitPrice) {
        return accumulator;
      }

      return accumulator + item.unitPrice * item.unitQuantity;
    }, 0) || 0;

  return sum;
};

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetStateExcludeSpecificKeys: (state: OrderDetails, action: PayloadAction<Partial<OrderDetails>>) => ({
      ...initialState,
      ...action.payload,
    }),
    getOrderDetailsStart: (state) => ({
      ...state,
      isLoading: true,
      isError: false,
      isEditing: false,
      orderChangeError: initialState.orderChangeError,
      orderDetailsChanges: { items: [] },
      formikInitialValues: {
        deliveryDate: moment().format('YYYY-MM-DD'),
        items: [],
        additionalFees: [],
        messageToCustomer: '',
      }, // resets order details changes on next fetch
    }),
    getOrderDetailsSuccess: (state: OrderDetails, action) => {
      const initialOrderDetails = { ...action.payload.orderDetails };
      action.payload.orderDetails.items = action.payload.orderDetails.items.map((item: OrderItem) => ({
        ...item,

        imageUrl: item.imageUrl,

        /*
         * to show a 0 instead of empty price.
         * this is fine because we do not depend on unitPrice
         * anymore in checking whether an item is weight adjustable.
         */
        unitPrice: item.unitPrice || 0,

        originalUnitQuantity: (item.originalUnitQuantity || 1) / (item.originalPackagingQuantity || 1),
      }));

      return {
        ...state,
        orderDetails: action.payload.orderDetails,
        initialOrderDetails,
        isLoading: false,
        isError: false,
        isEditing: false,
      };
    },

    getOrderDetailsFailure: (state) => ({
      ...state,
      isLoading: false,
      isError: true,
      isEditing: false,
    }),
    setTransactionToken: (state, action) => ({
      ...state,
      balanceToken: action.payload,
    }),
    setPendingOrderId: (state, action) => ({
      ...state,
      pendingOrderId: action.payload,
    }),
    setPaymentId: (state, action) => ({
      ...state,
      paymentId: action.payload,
    }),
    setPaymentTerm: (state, action) => ({
      ...state,
      paymentTerm: action.payload,
    }),
    enableOrderDetailsEditMode: (state) => ({
      ...state,
      isEditing: true,
    }),
    disableOrderDetailsEditMode: (state) => ({
      ...state,
      isEditing: false,
    }),
    showOrderDetailsAcceptMessage: (state) => ({
      ...state,
      showOrderAccept: true,
    }),
    hideOrderDetailsAcceptMessage: (state) => ({
      ...state,
      showOrderAccept: false,
    }),
    showOrderDetailsRejectPopup: (state) => ({
      ...state,
      showOrderReject: true,
    }),
    hideOrderDetailsRejectPopup: (state) => ({
      ...state,
      showOrderReject: false,
    }),
    showChangeOrderConfirmation: (state) => ({
      ...state,
      showOrderChangeConfirmation: true,
    }),
    hideChangeOrderConfirmation: (state) => ({
      ...state,
      showOrderChangeConfirmation: false,
    }),

    showPackingSlipModal: (state) => ({
      ...state,
      isShowingPackingSlipModal: true,
    }),
    hidePackingSlipModal: (state) => ({
      ...state,
      isShowingPackingSlipModal: false,
    }),
    togglePrintInvoiceConfirmationModal: (state, action) => ({
      ...state,
      isShowingPrintInvoiceConfirmationModal: action.payload,
    }),
    toggleInvoicePreview: (state, action) => ({
      ...state,
      isShowingInvoicePreview: action.payload,
    }),

    addPlaceholderItem: (state: OrderDetails, action) => {
      const placeholderItem = {
        ...action.payload,
        isLoading: true,
        packagingQuantity: 1,
        originalPackagingQuantity: 1,
        isNew: true,
      };

      if (placeholderItem?.unit) {
        // unit price is null but item should be weight adjustable because unit exists
        placeholderItem.unitQuantity = placeholderItem.unitQuantity || 1;
      }

      if (state.orderDetails?.items) {
        state.orderDetails.items.push(placeholderItem);
      } else if (state.orderDetails) {
        state.orderDetails.items = [placeholderItem];
      }

      return state;
    },

    addOrderItem: (state: OrderDetails, action: PayloadAction<Product>) => {
      let placeholderItem;
      if (state.orderDetails?.items) {
        // find placeholder item before removing it as it contains the updated quantity
        placeholderItem = state.orderDetails.items.find((item) => item.isLoading);
        state.orderDetails.items = state.orderDetails.items.filter((item) => !item.isLoading);
      }

      const newItem = {
        ...action.payload,
        id: `new_${nanoid()}`,
        sourceId: action.payload.id,
        packagingQuantity: placeholderItem?.packagingQuantity || 1,
        placeHolderPackagingQuantity: placeholderItem?.placeHolderPackagingQuantity,
        originalPackagingQuantity: 1,
        originalUnitQuantity: action.payload.unitQuantity || 1,
        isNew: placeholderItem?.isNew
      };

      // only caclculate origin weight if item is per unit (unitPrice exists)
      if (newItem?.unitPrice != null && newItem?.unitPrice !== 0) {
        if (
          !newItem.unitQuantity ||
          !newItem.originalUnitQuantity ||
          newItem.unitQuantity === 0 ||
          newItem.originalUnitQuantity === 0
        ) {
          /*
           * Initial total weight is not fetched from Search API when adding a new item.
           * Therefore, the weight and original weight are calculated from the prices fields.
           * If prices fields do not have a value, the weights will be initialized to 1.
           */
          let initialWeight;
          if (newItem.packagingPrice && newItem.unitPrice) {
            initialWeight = Number(round(newItem.packagingPrice / newItem.unitPrice));
          } else {
            initialWeight = 1;
          }

          newItem.unitQuantity = 0;
          newItem.originalUnitQuantity = initialWeight;
        } else if (isPackageCheck(newItem)) {
          newItem.unitQuantity = 0;
          newItem.unitPrice = 0;
        }
      } else if (newItem?.unit) {
        // unit price is null but item should be weight adjustable because unit exists
        newItem.unitPrice = 0;
      }

      /*
       * If there exists a change with the same external id, it must be a removed item change.
       * We can assume it is a removed item because the only way for there to be an order detail change after an add is if the
       * original item existed and was removed.
       * In this situation, set the newItem to push to have the same id as the removed item, and filter out the remove item change.
       */
      const removedItem = state.orderDetailsChanges.items.find(
        (item: OrderItem) => item.externalId === newItem.externalId
      );
      if (removedItem && removedItem.id) {
        newItem.id = removedItem.id;
        state.orderDetailsChanges.items = state.orderDetailsChanges.items.filter(
          (item: OrderItem) => item.id !== removedItem.id
        );
      }

      state.orderDetailsChanges.items.push(newItem);

      if (state.orderDetails?.items) {
        state.orderDetails.items.push({
          ...newItem,
          imageUrl: action.payload.imageUrl,
          isUpdated: true,
        });
      }

      return state;
    },
    addReplacementOrderItem: (state: OrderDetails, action: PayloadAction<ReplacementProduct>) => {
      const newItem = {
        ...action.payload,
      };

      state.orderDetailsChanges.items.push(newItem);

      if (state.orderDetails?.items) {
        state.orderDetails.items.push({
          ...newItem,
          imageUrl: action.payload.imageUrl,
          isUpdated: true,
          isNew: true,
        });
      }

      return state;
    },
    setPaymentType: (state: OrderDetails, action) => {
      // TODO: process.env.REACT_APP_NOTCH_PAY_ENABLED's value should be a
      // primitive Boolean instead of a string.
      const isNotchPayEnabled = process.env.REACT_APP_NOTCH_PAY_ENABLED === 'true';
      const { payload } = action;

      if (state.orderDetails) {
        state.orderDetails.paymentSourceType = payload.type;
        state.orderDetails.paymentProviderSourceTokenId = '';

        if (
          (isNotchPayEnabled &&
            [PaymentType.ach, PaymentType.eft, PaymentType.pads, PaymentType.bank].includes(payload.type)) ||
          payload.type === PaymentType.card
        ) {
          state.orderDetails.paymentProviderSourceTokenId = payload.id;
        }
      }

      return state;
    },
    setSelectedPaymentTerm: (state: OrderDetails, action) => {
      const { payload } = action;
      if (state.orderDetails) {
        state.orderDetails.selectedPaymentTerm = payload;
      }

      return state;
    },
    addPaymentSource: (state: OrderDetails, action) => {
      if (state.orderDetails) {
        if (state.orderDetails.paymentSources) {
          state.orderDetails.paymentSources = [...state.orderDetails.paymentSources, action.payload];
        } else {
          state.orderDetails.paymentSources = [action.payload];
        }
      }

      return state;
    },
    setCustomer: (state: OrderDetails, action: PayloadAction<Customer>) => {
      state.orderDetails = {
        ...state.orderDetails,
        buyerName: action.payload.name || '',
        contactDetails: action.payload.contactDetails,
        paymentProviderSourceTokenId: '',
        paymentSourceType: 'new_card',
        selectedPaymentTerm: action.payload.paymentTerms ? action.payload.paymentTerms : 0,
        customerPreAuth: false,
        customerIsByos: action.payload.customerIsByos,
        vendorIsPayfacLite: action.payload.vendorIsPayfacLite,
      };

      return state;
    },
    setPaymentSources: (state: OrderDetails, action) => {
      if (state.orderDetails) {
        state.orderDetails.paymentSources = action.payload;

        if (state.orderDetails.paymentSources?.length && !state.orderDetails.directType) {
          state.orderDetails.directType = '';
        }

        const defaultPayment = action.payload.find((source: PaymentSource) => source.default);

        if (defaultPayment) {
          state.orderDetails.paymentSourceType = defaultPayment.type;
          if (defaultPayment.type === card) {
            state.orderDetails.paymentProviderSourceTokenId = defaultPayment.id;
          }
        }
      }
      return state;
    },
    startNewOrder: (state: OrderDetails, action: PayloadAction<StartNewOrderPayloadAction>) => {
      state.orderDetails = {
        ...state.orderDetails,
        buyerId: action.payload.customer.buyerId,
        customerId: action.payload.customer.customerId,
        supplierId: action.payload.customer.vendorId,
        buyerBillingAddress: {
          fullAddress: action.payload.customer.address,
        },
        buyerShippingAddress: {
          fullAddress: action.payload.customer.address,
          countryCode: action.payload.customer.countryCode,
        },
        deliveryDate: moment(new Date()).format('YYYY-MM-DD'),
        deliveryInstructions: action.payload.customer.deliveryInstructions || '',
        paymentProviderId: action.payload.customer.paymentProviderId || '',
        items: [],
        billingStatus: 'draft_invoice',
        directType: action.payload.customer.isByos ? 'byos' : '',
        isReplacementOrderCreationFlow: action.payload.parentOrder !== undefined,
        parentOrder: action.payload.parentOrder,
        lastFetchTimestamp: action.payload.lastFetchTimestamp,
      };

      return state;
    },
    updateDeliveryDate: (state: OrderDetails, action: PayloadAction<string>) => {
      state.orderDetailsChanges.deliveryDate = action.payload;
      if (state.orderDetails) {
        state.orderDetails.fees = state.formikInitialValues.additionalFees;
      }

      return state;
    },
    updateFormikInitialValues: (state: OrderDetails, action: PayloadAction<FormValues>) => {
      state.formikInitialValues = action.payload;

      return state;
    },
    updatePlaceHolderOrderQuantity: (
      state: OrderDetails,
      action: PayloadAction<{
        id: string;
        placeHolderPackagingQuantity: number;
      }>
    ) => {
      const { id, placeHolderPackagingQuantity } = action.payload;

      if (!state.orderDetails?.items) {
        return state;
      }
      const foundItem = state.orderDetails.items.find((item) => item.id === id);

      if (!foundItem) {
        return state;
      }

      state.orderDetails.items = state.orderDetails.items.map((item) =>
        item.id === id ? { ...item, placeHolderPackagingQuantity } : item
      );

      return state;
    },
    updateOrderItem: (state: OrderDetails, action: PayloadAction<OrderItem>) => {
      const { id, packagingPrice, packagingQuantity, unitPrice, unitQuantity } = action.payload;

      // make sure we have the edited items
      if (!state.orderDetails || !state.orderDetails.items?.length) {
        return state;
      }

      const foundItem = state.orderDetails.items.find((item) => item.id === id);

      if (!foundItem) {
        return state;
      }

      const itemChanges = state.orderDetailsChanges.items.find((item) => item.id === id);

      let unitQuantityNewValue = unitQuantity;

      /*
       * check if packagingQuantity has changed by user:
       * if item exists in change list -> check there
       * if not, check in items list
       */
      const hasQuantityChanged = itemChanges?.packagingQuantity
        ? packagingQuantity !== itemChanges?.packagingQuantity
        : packagingQuantity !== foundItem.packagingQuantity;

      /*
       * check if unitQuantity has changed by user:
       * if item exists in change list -> check there
       * if not, check in items list
       */
      const hasWeightChanged = itemChanges?.unitQuantity
        ? unitQuantity !== itemChanges?.unitQuantity
        : unitQuantity !== foundItem.unitQuantity;

      /*
       * if quantity has changed -> change total weight (always)
       * if the weight has changed, skip this if statement
       * otherwise the weight will be reverted back
       */
      if (
        !isPackageCheck(foundItem) &&
        packagingQuantity &&
        foundItem.originalUnitQuantity &&
        hasQuantityChanged &&
        !hasWeightChanged
      ) {
        unitQuantityNewValue = foundItem.originalUnitQuantity * packagingQuantity;
      }

      /*
       * Valudate that the prices and QTY are corret otherwise rest them
       */
      const newPackagingPrice =
        packagingPrice === undefined || packagingPrice === null || packagingPrice < 0
          ? foundItem.packagingPrice
          : packagingPrice;
      const newPackagingQuantity =
        packagingQuantity === undefined || packagingQuantity === null || packagingQuantity < 0
          ? foundItem.packagingQuantity
          : packagingQuantity;
      const newUnitPrice =
        unitPrice === undefined || unitPrice === null || unitPrice < 0 ? foundItem.unitPrice : unitPrice;

      const orderItemUpdateFields: OrderItem = {
        packagingPrice: newPackagingPrice,
        packagingQuantity: newPackagingQuantity,
        unitPrice: isPackageCheck(foundItem) ? undefined : newUnitPrice,
        unitQuantity: isPackageCheck(foundItem) ? undefined : (unitQuantityNewValue || foundItem.unitQuantity || undefined),
        unit: foundItem.unit,
        originalUnitQuantity: foundItem.originalUnitQuantity,
      };

      // check if there are any changes
      // in case of a replacement order we never want to stop the update
      // as the default view (price: 0) is also an update
      const shouldStopUpdate =
        getTypeObjectKeys(foundItem).every(
          (field) => orderItemUpdateFields[field] === undefined || orderItemUpdateFields[field] === foundItem[field]
        ) && !state.orderDetails.isReplacementOrderCreationFlow;

      // if item is newly added, we need to always deal with the update even if the fields were not changed
      if (shouldStopUpdate && foundItem.id?.toLowerCase().indexOf(newPrefix) !== 0) {
        if (!foundItem.isUpdated) {
          state.orderDetailsChanges = {
            ...state.orderDetailsChanges,
            items: state.orderDetailsChanges.items.filter((item) => item.id !== id),
          };
        }
        return state;
      }

      // item may already be in the list of changed items
      // add it here if it's not
      let updatedItems = state.orderDetailsChanges.items;
      if (!state.orderDetailsChanges.items?.find((item) => item.id === id)) {
        if (updatedItems) {
          updatedItems.push({
            id: foundItem.id,
            ...orderItemUpdateFields,
          });
        }
      } else {
        // update only the current item, just in case it was already in the list
        updatedItems = (state.orderDetailsChanges.items || []).map((item) => {
          let newItem = item;

          if (newItem.id === id) {
            if (newItem.id?.toLowerCase().indexOf(newPrefix) === 0) {
              newItem = {
                ...newItem,
                ...orderItemUpdateFields,
                isUpdated: true,
              };
            } else {
              newItem = {
                id,
                ...orderItemUpdateFields,
                isUpdated: true,
              };
            }
          }

          return newItem;
        });
      }

      if (updatedItems) {
        state.orderDetailsChanges.items = updatedItems;
      }

      return state;
    },
    removeOrderItem: (state: OrderDetails, action) => {
      if (!state.orderDetails || !state.orderDetails.items) {
        return state;
      }

      const { id, externalId } = action.payload;
      let items = state.orderDetailsChanges.items || [];
      if (!items?.find((item) => item.id === id)) {
        if (items) {
          items.push({
            id,
            externalId,
            packagingQuantity: 0,
          });
        }
      } else if (id.indexOf(newPrefix) === 0) {
        items = items.filter((item) => item.id !== id);
      } else {
        items = items.map((item) => {
          let newItem = item;

          if (newItem.id === id) {
            newItem = {
              id,
              externalId,
              packagingQuantity: 0,
            };
          }

          return newItem;
        });
      }

      state.orderDetailsChanges.items = items;
      state.orderDetails.items = state.orderDetails.items.filter((item) => item.id !== id);

      return state;
    },
    addOrderMessage: (state: OrderDetails, action) => {
      state.orderDetailsChanges.messageToCustomer = action.payload.orderMessage;
      if (state.orderDetails) {
        state.orderDetails.orderMessage = action.payload.orderMessage;
      }

      return state;
    },
    // this reducer will only be used during order create
    // as we don't allow the note to be updated on edit mode
    // using formik is also not ideal as we don't reset the form once the order is created
    // if the supplier edits the order after creating it, we might use the wrong value
    addOrderNote: (state: OrderDetails, action) => {
      if (state.orderDetails) {
        state.orderDetails.supplierNote = action.payload;
      }

      return state;
    },
    toggleHasNotifyUser: (state: OrderDetails, action: PayloadAction<NotifyUser>) => {
      state.notifyUser = action.payload;

      return state;
    },

    toggleIsSubmittingConfirmOrder: (state: OrderDetails, action: PayloadAction<boolean>) => {
      state.isSubmittingConfirmOrder = action.payload;

      return state;
    },

    toggleRemoveItemWarning: (state: OrderDetails, action: PayloadAction<boolean>) => {
      state.showRemoveItemWarning = action.payload;

      return state;
    },

    showNewPaymentMethod: (state: OrderDetails) => {
      state.isAddingPaymentMethod = true;
      return state;
    },
    hideNewPaymentMethod: (state: OrderDetails) => {
      state.isAddingPaymentMethod = false;
      return state;
    },
    setCustomerDetailsLoad: (state: OrderDetails, action: PayloadAction<boolean>) => {
      state.isLoadingCustomerDetails = action.payload;
      return state;
    },
    setPaymentMethodsLoad: (state: OrderDetails, action: PayloadAction<boolean>) => {
      state.isLoadingPaymentMethods = action.payload;
      return state;
    },
    markSendQBDRequest: (state: OrderDetails) => {
      state.isQBDSyncRequestSent = true;
      return state;
    },
    confirmPrintInvoice: (state: OrderDetails) => state,
    toggleShouldRefocusItemSearchField: (state: OrderDetails, action: PayloadAction<boolean>) => {
      state.shouldRefocusItemSearchField = action.payload;
      return state;
    },
    toggleOrderChangeError: (state: OrderDetails, action: PayloadAction<ConflictNotifier>) => {
      state.orderChangeError = action.payload;
      return state;
    },
    setCustomItems: (state: OrderDetails, action) => {
      state.customItems = action.payload;
      return state;
    },
  },
});

export const {
  enableOrderDetailsEditMode,
  disableOrderDetailsEditMode,
  showOrderDetailsAcceptMessage,
  hideOrderDetailsAcceptMessage,
  showOrderDetailsRejectPopup,
  hideOrderDetailsRejectPopup,
  addOrderItem,
  addReplacementOrderItem,
  startNewOrder,
  updatePlaceHolderOrderQuantity,
  updateOrderItem,
  removeOrderItem,
  addOrderMessage,
  addOrderNote,
  hideChangeOrderConfirmation,
  showChangeOrderConfirmation,
  updateDeliveryDate,
  toggleHasNotifyUser,
  toggleRemoveItemWarning,
  toggleIsSubmittingConfirmOrder,
  resetState,
  resetStateExcludeSpecificKeys,
  showPackingSlipModal,
  hidePackingSlipModal,
  togglePrintInvoiceConfirmationModal,
  toggleInvoicePreview,
  showNewPaymentMethod,
  hideNewPaymentMethod,
  setCustomer,
  setPaymentSources,
  setPaymentType,
  addPaymentSource,
  setCustomerDetailsLoad,
  setPaymentMethodsLoad,
  updateFormikInitialValues,
  markSendQBDRequest,
  addPlaceholderItem,
  toggleShouldRefocusItemSearchField,
  toggleOrderChangeError,
  setTransactionToken,
  setPendingOrderId,
  setPaymentId,
  setPaymentTerm,
  setSelectedPaymentTerm,
} = orderDetailsSlice.actions;

export const fetchOrderDetails = (urlsafe: string | undefined, notifyUser?: boolean): AppThunk => async (dispatch) => {
  if (urlsafe === undefined) return;
  try {
    dispatch(orderDetailsSlice.actions.getOrderDetailsStart());

    const orderDetails = await getOrderDetails(urlsafe);

    dispatch(orderDetailsSlice.actions.getOrderDetailsSuccess({ orderDetails }));

    const customItems = await getCustomItems(
      urlsafe,
      orderDetails.buyerId as string
    );

    dispatch(orderDetailsSlice.actions.setCustomItems(customItems));

    dispatch(
      updateFormikInitialValues({
        ...orderDetails,
        items: orderDetails.items || [],
        deliveryDate: orderDetails.deliveryDate || '',
        additionalFees: orderDetails.fees || [],
      })
    );

    if (notifyUser) {
      dispatch(
        toggleHasNotifyUser({
          isNotify: true,
          toasterMessage: `Order #${orderDetails.orderNumber} has been created.`,
        })
      );
    }
  } catch (error) {
    dispatch(orderDetailsSlice.actions.getOrderDetailsFailure());
    throw error;
  }
};

export const sendQBDSyncRequest = (orderDetails: Order | null): AppThunk => async (dispatch) => {
  try {
    if (orderDetails?.allowSyncWithThirdParty) {
      const orderId = orderDetails?.orderId;

      if (orderId) {
        dispatch(markSendQBDRequest());
        const syncedOrder = await sendQBDSyncRequestAPI(orderId);
        dispatch(
          orderDetailsSlice.actions.getOrderDetailsSuccess({
            orderDetails: syncedOrder,
          })
        );
      }
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }
};

export const attachCardToBuyer = (paymentSource: PaymentSource, token: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    await attachCard(token, getState().orderDetails.orderDetails.customerId);
    dispatch(addPaymentSource(paymentSource));
  } catch (e) {
    const error = e?.response?.data?.message || 'Someting went wrong';
    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        isWarning: true,
        toasterMessage: error,
      })
    );
  }
};

/**
 * Contains information about how to retry an Order Update if we get a 409 error
 */
type RetryUpdate = {
  lastFetchTimestamp: string;
};

/**
 * Checks the last Order we received from the backend against a fresh copy.
 *
 * @returns a RetryUpdate if there is no conflict or null otherwise
 */
export const shouldRetryUpdate = async (intialOrderDetails: Order): Promise<RetryUpdate | null> => {
  const orderDetails = { ...intialOrderDetails };
  if (orderDetails.orderId === undefined) {
    return null;
  }
  try {
    const updatedOrderDetails = await getOrderDetails(orderDetails.orderId);
    const { lastFetchTimestamp } = updatedOrderDetails;

    // TODO: Fill omitList with all properties that can be changed without causing a conflict
    const omitList = ['lastFetchTimestamp'];

    // Check equality between our last retrieved order and the updated order from the backend
    const equal = isEqual(omit(updatedOrderDetails, omitList), omit(orderDetails, omitList));
    return equal && lastFetchTimestamp !== undefined ? { lastFetchTimestamp } : null;
  } catch (e) {
    return null;
  }
};

/**
 * Update the Order. If it fails with a 409 response, then check and see if we should retry
 *
 * @param formValues contains changes from the FormState
 * @param retryWith @default null othewise contains a RetryUpdate
 */
export const updateOrder = (formValues?: FormValues, retryWith: RetryUpdate | null = null): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState().orderDetails;
  try {
    dispatch(toggleIsSubmittingConfirmOrder(true));
    const lastFetchTimestamp =
      retryWith !== null ? retryWith.lastFetchTimestamp : state.orderDetails.lastFetchTimestamp;

    const additionalFees = formValues?.additionalFees.map((fee) => {
      const newFee = { ...fee };
      if (newFee.isPCT) {
        // the api doesn't support fee as percentage
        // we need to convert the amount to a dollar amount
        newFee.feeAmount = (fee.feeAmount / 100) * subtotal(state);
      }

      delete newFee.isPCT;
      return newFee;
    });

    const orderDetails = await editOrder(state.orderDetails.orderId, {
      ...(formValues || {}),
      ...state.orderDetailsChanges,
      additionalFees,
      lastFetchTimestamp,
      items: state.orderDetailsChanges.items.map((item: OrderItem) => {
        const newItem = { ...item };

        if (item.id?.indexOf(newPrefix) === 0) {
          delete newItem.id;
        }
        return newItem;
      }),
    });

    if (orderDetails.fulfillmentStatus?.toLowerCase() === 'delivered') {
      dispatch(sendQBDSyncRequest(orderDetails));
    }

    dispatch(orderDetailsSlice.actions.getOrderDetailsStart());
    dispatch(orderDetailsSlice.actions.hideChangeOrderConfirmation());
    dispatch(orderDetailsSlice.actions.getOrderDetailsSuccess({ orderDetails }));

    dispatch(
      updateFormikInitialValues({
        ...orderDetails,
        items: orderDetails.items || [],
        deliveryDate: orderDetails.deliveryDate || '',
        additionalFees: orderDetails.fees || [],
      })
    );

    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        toasterMessage: `Order #${orderDetails?.orderNumber} has been saved.`,
      })
    );

    dispatch(toggleIsSubmittingConfirmOrder(false));
  } catch (error) {
    const statusCode = error?.response?.status;
    if (statusCode === 409 && retryWith === null) {
      const retryObject = await shouldRetryUpdate(state.initialOrderDetails);
      if (retryObject !== null) {
        // We need to await for testing reasons, if we do not the getActions will not include the second run
        await dispatch(updateOrder(formValues, retryObject));
        return;
      }
    }

    if (statusCode === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot save changes',
          })
        );
        dispatch(toggleIsSubmittingConfirmOrder(false));
        dispatch(orderDetailsSlice.actions.hideChangeOrderConfirmation());
      });
    } else {
      batch(() => {
        dispatch(
          toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(toggleIsSubmittingConfirmOrder(false));
        dispatch(orderDetailsSlice.actions.hideChangeOrderConfirmation());
        dispatch(fetchOrderDetails(getState().orderDetails.orderDetails.orderId));
      });
    }
    throw error;
  }
};

export const markOrderInProgress = (urlsafe: string): AppThunk => async (dispatch, getState) => {
  try {
    const { orderDetails } = getState();

    await markInProgress(urlsafe, orderDetails.orderDetails?.lastFetchTimestamp);

    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        toasterMessage: `Order #${orderDetails.orderDetails?.orderNumber} has been marked in progress.`,
      })
    );

    dispatch(fetchOrderDetails(urlsafe));
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot mark order in progres',
          })
        );
      });
    } else {
      batch(() => {
        dispatch(
          orderDetailsSlice.actions.toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(fetchOrderDetails(getState().orderDetails.orderDetails.orderId));
      });
    }
    throw error;
  }
};

export const markOrderFulfilled = (urlsafe: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();

    const orderDetails = await fulfillOrder(urlsafe, state.orderDetails.orderDetails?.lastFetchTimestamp);

    dispatch(orderDetailsSlice.actions.getOrderDetailsSuccess({ orderDetails }));
    if (orderDetails?.invoiceNumber && orderDetails.thirdPartyIntegrationStatus?.toLowerCase() === 'unsent') {
      dispatch(sendQBDSyncRequest(orderDetails));
    }

    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        toasterMessage: `Order #${orderDetails?.orderNumber} has been marked as fulfilled.`,
      })
    );
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot mark order as fulfilled',
          })
        );
      });
    } else {
      batch(() => {
        dispatch(
          orderDetailsSlice.actions.toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(fetchOrderDetails(getState().orderDetails.orderDetails.orderId));
      });
    }
    throw error;
  }
};

export const markPaid = (urlsafe: string): AppThunk => async (dispatch, getState) => {
  try {
    const orderDetails = await markDirectAsPaid(urlsafe, getState().orderDetails.orderDetails?.lastFetchTimestamp);
    dispatch(orderDetailsSlice.actions.getOrderDetailsSuccess({ orderDetails }));
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot mark order as paid',
          })
        );
        dispatch(hideOrderDetailsRejectPopup());
      });
    }
  }
};

export const rejectOrder = (urlsafe: string, rejectReason: string): AppThunk => async (dispatch, getState) => {
  try {
    const { orderDetails } = getState();

    await cancelOrder(urlsafe, orderDetails.orderDetails?.lastFetchTimestamp, rejectReason);

    dispatch(
      toggleHasNotifyUser({
        isNotify: true,
        isWarning: true,
        toasterMessage: `Order #${orderDetails.orderDetails?.orderNumber} has been rejected.`,
      })
    );

    dispatch(orderDetailsSlice.actions.hideOrderDetailsRejectPopup());
    dispatch(fetchOrderDetails(urlsafe));
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot reject the order',
          })
        );
        dispatch(hideOrderDetailsRejectPopup());
      });
    } else {
      batch(() => {
        dispatch(
          orderDetailsSlice.actions.toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(orderDetailsSlice.actions.hideOrderDetailsRejectPopup());
        dispatch(fetchOrderDetails(getState().orderDetails.orderDetails.orderId));
      });
    }
    throw error;
  }
};

export const setUpNewOrder = (
  selectCustomer: CustomerFromAutoComplate,
  parentOrder?: string,
  items?: OrderItem[],
  lastFetchTimestamp?: string
): AppThunk => async (dispatch) => {
  dispatch(resetState());
  dispatch(enableOrderDetailsEditMode());
  dispatch(setPaymentMethodsLoad(true));
  dispatch(setCustomerDetailsLoad(true));
  if (process.env.REACT_APP_NOTCH_PAY_ENABLED === 'true') {
    getPaymentSources(selectCustomer.buyerId)
      .then((sources: PaymentSource[]) => {
        dispatch(setPaymentSources(sources));
        dispatch(setPaymentMethodsLoad(false));
      })
      .catch(() => {
        dispatch(setPaymentMethodsLoad(false));
      });
  } else {
    getPaymentSources(selectCustomer.customerId)
      .then((sources: PaymentSource[]) => {
        dispatch(setPaymentSources(sources));
        dispatch(setPaymentMethodsLoad(false));
      })
      .catch(() => {
        dispatch(setPaymentMethodsLoad(false));
      });
  }

  getCustomer(selectCustomer.customerId)
    .then((customer: Customer) => {
      const res = customer;
      dispatch(setPaymentTerm(res.paymentTerms));
      dispatch(setCustomer(customer));
      dispatch(setCustomerDetailsLoad(false));
    })
    .catch(() => {
      dispatch(setCustomerDetailsLoad(false));
    });

  dispatch(
    startNewOrder({
      customer: selectCustomer,
      parentOrder,
      lastFetchTimestamp,
    })
  );

  if (parentOrder && items) {
    items.forEach((item) =>
      dispatch(
        addReplacementOrderItem({
          id: item.id || '',
          externalId: item.externalId || '',
          productId: '',
          variantId: item.variantId || '',
          packagingPrice: 0,
          name: item.name || '',
          packagingDescription: item.packagingDescription || '',
          unitPrice: 0,
          unitQuantity: item.unitQuantity || null,
          originalUnitQuantity: item.originalUnitQuantity || 0,
          imageUrl: item.imageUrl || '',
          taxable: item.taxable || false,
          unit: item.unit || '',
          packagingQuantity: item.packagingQuantity || 1,
          originalPackagingQuantity: item.packagingQuantity || 1,
          selectedReplacedItem: item,
        })
      )
    );
  }
};

export const submitOrderForm = (
  orderDetails: FormValues,
  routerPush: (path: string, state?: unknown) => void | OrderDetails,
  isEditing: boolean
): AppThunk => async (dispatch, getState) => {
  try {
    const isCreating = isEditing && !orderDetails.orderId;

    /*
      TODO: Update this code when we move to over to completly using Formik
      As this is ment as a stop gap for bridging non-standard fields with
      standard fields
    */
    const { orderDetails: appStoreOrderDetails } = getState();
    const currentOrderDetailsItem = appStoreOrderDetails.orderDetails?.items || [];
    const additionalFees = orderDetails?.additionalFees.map((fee) => {
      const newFee = { ...fee };
      if (newFee.isPCT) {
        // the api doesn't support fee as percentage
        // we need to convert the amount to a dollar amount
        newFee.feeAmount = (fee.feeAmount / 100) * subtotal(appStoreOrderDetails);
      }

      delete newFee.isPCT;
      return newFee;
    });

    if (currentOrderDetailsItem.length === 0) {
      dispatch(toggleRemoveItemWarning(true));

      return;
    }

    if (isCreating) {
      const newOrderToBeCreated = {
        ...orderDetails,
        additionalFees,
        items: orderDetails.items.map((item) => {
          const newItem = { ...item };

          // keep id of replaced items
          if (item.id?.indexOf(newPrefix) === 0) {
            delete newItem.id;
          }

          // unit price takes priority in the backend
          // if it's not set let's make sure not to send it
          if (!item.unitPrice && item.packagingPrice) {
            delete newItem.unitPrice;
          }

          return newItem;
        }),
        vendorId: orderDetails.supplierId,
      };

      let newOrder: Order;

      newOrderToBeCreated.paymentMethodId = '';
      newOrderToBeCreated.paymentProviderSourceTokenId = '';
      newOrderToBeCreated.paymentSourceType = '';
      newOrderToBeCreated.paymentProviderId = '';

      if (orderDetails.isReplacementOrderCreationFlow && orderDetails.parentOrder) {
        newOrder = await createReplacementOrder(
          orderDetails.parentOrder,
          newOrderToBeCreated,
          appStoreOrderDetails.orderDetails.lastFetchTimestamp
        );
        dispatch(InvoiceReset());
        routerPush(`/orders/${newOrder?.orderId}`, { isNewOrder: true });
      } else {
        newOrder = await createOrder(newOrderToBeCreated);

        if (newOrder) {
            routerPush(`/orders/${newOrder?.orderId}`, { isNewOrder: true });
            return;
        } else {
          throw new Error('Unable to create order');
        }
      }
    }

    dispatch(showChangeOrderConfirmation());

    return;
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot create a replacement order',
          })
        );
      });
    } else {
      dispatch(
        toggleHasNotifyUser({
          isNotify: true,
          isWarning: true,
          toasterMessage: error?.response?.data?.error || `Something went wrong`,
        })
      );

      throw error;
    }
  }
};

export const updateDate = (date: moment.Moment | null): AppThunk => async (dispatch) => {
  if (date) {
    const newDate = date.format('YYYY-MM-DD');

    dispatch(updateDeliveryDate(newDate));
  }
};

// before end of delivery day, this action does not actually generate the invoice
// but only prints it
export const generateInvoice = (): AppThunk => async (dispatch) => {
  try {
    dispatch(orderDetailsSlice.actions.toggleInvoicePreview(true));
    dispatch(orderDetailsSlice.actions.togglePrintInvoiceConfirmationModal(false));
  } catch (error) {
    batch(() => {
      dispatch(
        toggleHasNotifyUser({
          isNotify: true,
          isWarning: true,
          toasterMessage: `Something went wrong. Could not print invoice.`,
        })
      );
    });

    throw error;
  }
};

export const syncOrderNote = (note: string): AppThunk => async (dispatch, getState) => {
  const state = getState().orderDetails;
  const { orderDetails } = state;
  dispatch(orderDetailsSlice.actions.getOrderDetailsStart());
  try {
    await updateSupplierNote(state.orderDetails.orderId, state.orderDetails.lastFetchTimestamp, note);
    batch(() => {
      dispatch(
        orderDetailsSlice.actions.toggleHasNotifyUser({
          isNotify: true,
          toasterMessage: 'Order note was updated successfully.',
        })
      );
      dispatch(fetchOrderDetails(state.orderDetails.orderId));
    });
  } catch (error) {
    if (error?.response?.status === 409) {
      batch(() => {
        dispatch(
          toggleOrderChangeError({
            isNotify: true,
            title: 'Cannot update order note',
          })
        );
        dispatch(
          orderDetailsSlice.actions.getOrderDetailsSuccess({
            orderDetails: { ...orderDetails },
          })
        );
      });
    } else {
      batch(() => {
        dispatch(
          orderDetailsSlice.actions.toggleHasNotifyUser({
            isNotify: true,
            isWarning: true,
            toasterMessage: 'Something went wrong.',
          })
        );

        dispatch(fetchOrderDetails(getState().orderDetails.orderDetails.orderId));
      });
    }
    throw error;
  }
};
