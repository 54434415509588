import React from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import { Image } from 'types/Image';

export interface TextFieldProps {
  id: string;
  name: string;
  label: string;
  type: string;
  disabled?: boolean;
  dataTestid?: string;
  icon?: Image;
}

const TextField = ({
  label,
  disabled = false,
  dataTestid = 'text-field',
  icon = undefined,
  ...props
}: TextFieldProps): JSX.Element => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;

  return (
    <div
      className={cx('input-text-field', {
        'input-text-field-error': hasError,
      })}
    >
      <input
        data-testid={dataTestid}
        className={cx('text-input', {
          'text-input-error': hasError,
          'text-input-text-icon': icon,
        })}
        disabled={disabled}
        // must be down because it is unknown what Foremilk props it is need
        // same goes with rpops as all Formilk props need some custom props
        // such as id and name
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <label className="text-input-label" htmlFor={field.name}>
        {label}
      </label>

      {icon && (
        <span className="text-input-icon">
          <img src={icon.src} alt={icon.alt} />
        </span>
      )}

      {hasError && (
        <div
          data-testid={`${dataTestid}-error`}
          className={cx('error-text-container', {
            error: hasError,
          })}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default TextField;
