/**
 * Because this code comes form Intercom Script tag and not use we decied not to
 * maintain the linting for this file.
 */
/* eslint-disable */
import User from 'types/User';
import Supplier from 'types/Supplier';

/*
 ** Intercom
 */
export const initialize = (): void => {
  // Skip init when running unit tests
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_ID}`;
        var x: any = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const initLoggedOutUser = (): void => {
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        clearSession();

        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID,
          name: 'Non-logged in user',
          widget: { activator: '#IntercomDefaultWidget' },
        });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

export const initLoggedInUser = (user: User, supplier: Supplier) => {
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        clearSession();

        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID,
          user_id: String(user.id),
          name: user.name,
          user: user.name,
          email: user.email,
          widget: { activator: '#IntercomDefaultWidget' },
          company: {
            id: String(supplier.id),
            name: `Supplier ${supplier.name}`,
            email: supplier.email,
          },
        });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

export const open = (): void => {
  if (window.Intercom) {
    window.Intercom('show');
  }
};

export const clearSession = (): void => {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
};

export const hide = (): void => {
  // TODO should be a wrapper for all these methods
  // The irony here is that wrapping this with setInterval gives enought time for Intercom to be available.
  let retry = 0;
  const waitForIntercom = setInterval(() => {
    if (retry <= 5) {
      if (window.Intercom) {
        window.Intercom('update', { hide_default_launcher: true });

        clearInterval(waitForIntercom);
      } else {
        retry++;
      }
    } else {
      clearInterval(waitForIntercom);
    }
  }, 200);
};

const setHorizontalPadding = (margin: number) => {
  if (window.Intercom) {
    window.Intercom('update', { horizontal_padding: 20 + margin });
  }
};

export const shiftLeft = (): void => setHorizontalPadding(385);
export const resetPosition = (): void => setHorizontalPadding(0);
