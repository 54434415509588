const baseUrl = process.env.REACT_APP_ASSETS_URL;

const images = {
  logo: `${baseUrl}/images/connect_logo.svg`,
  processLogo: `${baseUrl}/images/icon-paid-by.svg`,
  hambuergerIcon: `${baseUrl}/images/icon-hamburger-menu.svg`,
  marketIcon: `${baseUrl}/images/icon-connect-market.svg`,
  directIcon: `${baseUrl}/images/icon-connect-direct.svg`,
  placeholder: `${baseUrl}/images/image-placeholder.svg`,
  cancelOrderIcon: `${baseUrl}/images/icon-cancel.svg`,
  editOrderIcon: `${baseUrl}/images/icon-edit-top.svg`,
  editIcon: `${baseUrl}/images/icon-edit.svg`,
  generateInvoiceIcon: `${baseUrl}/images/icon-generate-invoice.svg`,
  markFulfilledIcon: `${baseUrl}/images/icon-mark-fulfilled.svg`,
  ordersIcon: `${baseUrl}/images/icon-orders-color.svg`,
  printIcon: `${baseUrl}/images/icon-print-top.svg`,
  quickBooksIcon: `${baseUrl}/images/icon-qb-top.svg`,
  quickBooksListIcon: `${baseUrl}/images/icon-qb-list.svg`,
  sapIcon: `${baseUrl}/images/icon-sap.svg`,
  inProgressIcon: `${baseUrl}/images/in-progress-check.svg`,
  newOrderIcon: `${baseUrl}/images/new-order-oval.svg`,
  nextMonthIcon: `${baseUrl}/images/next.svg`,
  prevMonthIcon: `${baseUrl}/images/previous.svg`,
  feeEditIcon: `${baseUrl}/images/icon-fee-edit.svg`,
  feeTrashIcon: `${baseUrl}/images/icon-fee-trash.svg`,
  searchIcon: `${baseUrl}/images/icon-search.svg`,
  closeIcon: `${baseUrl}/images/close-icon.svg`,
  logoutIcon: `${baseUrl}/images/icon-logout.svg`,
  mobileMenuCloseIcon: `${baseUrl}/images/icon-mobile-menu-close.svg`,
  additionalActionsIcon: `${baseUrl}/images/additional-actions-icon.svg`,
  expandIcon: `${baseUrl}/images/expand-icon.svg`,
  dropdownIcon: `${baseUrl}/images/dropdown-icon.svg`,
  qbSynced: `${baseUrl}/images/qb-synced-icon.svg`,
  qbSyncing: `${baseUrl}/images/clock.svg`,
  qbNotSynced: `${baseUrl}/images/qb-not-synced.svg`,
  qbError: `${baseUrl}/images/qbd-error.svg`,
  qbReadyToSync: `${baseUrl}/images/qb-ready-to-synced.svg`,
  sapError: `${baseUrl}/images/SAP-error.svg`,
  sapSynced: `${baseUrl}/images/SAP-synced.svg`,
  adjustInvoiceIcon: `${baseUrl}/images/icon-adjust-invoice.svg`,
  adjustInvoiceIconDisabled: `${baseUrl}/images/icon-adjust-invoice-disabled.svg`,
  replacementOrder: `${baseUrl}/images/icon-replacement-order.svg`,
  termsIcon: `${baseUrl}/images/terms-icon.svg`,
  codIcon: `${baseUrl}/images/cod-icon.svg`,
  visaIcon: `${baseUrl}/images/visa-icon.svg`,
  mastercardIcon: `${baseUrl}/images/mastercard-icon.svg`,
  amexIcon: `${baseUrl}/images/amex-icon.svg`,
  backArrowIcon: `${baseUrl}/images/back-arrow-icon.svg`,
  loadingIcon: `${baseUrl}/images/loading.svg`,
  infoIcon: `${baseUrl}/images/icon-info.svg`,
  settingsIcon: `${baseUrl}/images/settings-icon.svg`,
  notifyToasterGreySquareIcon: `${baseUrl}/images/notify-toaster-grey-square.svg`,
  sortIcon: `${baseUrl}/images/icon-sort-arrow.svg`,
  filterIcon: `${baseUrl}/images/icon-filter.svg`,
  sortMenuIcon: `${baseUrl}/images/sort-menu-icon.svg`,
  clearIcon: `${baseUrl}/images/icon-clear.svg`,
  arrowIcon: `${baseUrl}/images/icon-arrow.svg`,
  notchAccentLogo: `${baseUrl}/images/logo-notch-accent.svg`,
  inboxIcon: `${baseUrl}/images/inbox-icon.svg`,
  chefIcon: `${baseUrl}/images/chef-icon.svg`,
  orderIcon: `${baseUrl}/images/order-icon.svg`,
  deliveredIcon: `${baseUrl}/images/delivered-icon.svg`,
  readIcon: `${baseUrl}/images/read-icon.svg`,
  condensedLogo: `${baseUrl}/images/logo-connect-condensed.svg`,
  infoActiveIcon: `${baseUrl}/images/info-active-icon.svg`,
  infoInactiveIcon: `${baseUrl}/images/info-inactive-icon.svg`,
  resolveIcon: `${baseUrl}/images/resolve-icon.svg`,
  messageIcon: `${baseUrl}/images/message-icon.svg`,
  attachmentIcon: `${baseUrl}/images/attachment-icon.svg`,
  closeDarkIcon: `${baseUrl}/images/close-dark-icon.svg`,
  downloadIcon: `${baseUrl}/images/download-icon.svg`,
  ccIcon: `${baseUrl}/images/cc-icon.svg`,
  backBlackIcon: `${baseUrl}/images/back-black-icon.svg`,
  iconPayLater: `${baseUrl}/images/icon-paylater.svg`,
  alert: `${baseUrl}/images/alert.svg`,
  bank: `${baseUrl}/images/icon_bank.svg`,
};

export default images;
