import images from 'config';

export const ORDERS_MENU = 'orders';
export const CLIENTS_MENU = 'clients';
export const SETTINGS_MENU = 'settings';
export const INBOX_MENU = 'inbox';
export const SELECTED_MENU = ORDERS_MENU;

export const COMPANY_NAME = 'notch';
export const FRONTEND_NAME = 'Connect';
export const DEBOUNCE_DELAY_IN_MS = 300;
export const GET_NOTIFICATION_IN_MS = 30000;
export const GOOGLE_DRIVE_URL_PREFIX = 'https://docs.google.com/gview?embedded=true&url=';
export const THIRD_PARTY_INTEGRATION: {
  [key: string]: Record<string, string>;
} = {
  sap: {
    thirdPartyIntIcon: images.sapIcon,
    thirdPartyIntError: images.sapError,
    thirdPartyIntSynced: images.sapSynced,
    alertnativeText: 'sap ready to sync',
    text: 'Sync to SAP',
  },
  'quickbooks-desktop': {
    thirdPartyIntIcon: images.quickBooksIcon,
    thirdPartyIntError: images.qbError,
    thirdPartyIntSynced: images.qbSynced,
    alertnativeText: 'quickbooks ready to sync',
    text: 'Sync to QuickBooks',
  },
  'quickbooks-online': {
    thirdPartyIntIcon: images.quickBooksIcon,
    thirdPartyIntError: images.qbError,
    thirdPartyIntSynced: images.qbSynced,
    alertnativeText: 'quickbooks ready to sync',
    text: 'Sync to QuickBooks',
  },
};
export const LINE_ITEM_DECIMAL_PLACES = 3;
