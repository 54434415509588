export enum Index {
  development = 'development',
  production = 'production',
  staging = 'staging',
  experimental = 'experimental',
}
export type IFirebase = {
  [k in Index]: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
};

export const FIREBASE_CONFIG = {
  development: {
    apiKey: 'AIzaSyD3GZ7zDOZVx57Zhqnf2NlY_eiq5jgUUzs',
    authDomain: 'chefhero-experimental.firebaseapp.com',
    databaseURL: 'https://chefhero-experimental.firebaseio.com',
    projectId: 'chefhero-experimental',
    storageBucket: 'chefhero-experimental.appspot.com',
    messagingSenderId: '823787576485',
    appId: '1:823787576485:web:c10741bf6ebd1285a6414c',
  },
  production: {
    apiKey: 'AIzaSyCSAZwKyTWUtyRl6T6b4wEwIORlEY1ZiZ4',
    authDomain: 'producehero-project.firebaseapp.com',
    databaseURL: 'https://producehero-project.firebaseio.com',
    projectId: 'producehero-project',
    storageBucket: 'producehero-project.appspot.com',
    messagingSenderId: '182019323643',
    appId: '1:182019323643:web:bad8c6bc19fa5b57c7a25e',
  },
  staging: {
    apiKey: 'AIzaSyDUBBBgxl9qHwZI8SLHjKSaaoIhWyqBZlU',
    authDomain: 'chefhero-release.firebaseapp.com',
    databaseURL: 'https://chefhero-release.firebaseio.com',
    projectId: 'chefhero-release',
    storageBucket: 'chefhero-release.appspot.com',
    messagingSenderId: '136338530075',
    appId: '1:136338530075:web:11b31d18e8321a5af17dbe',
  },
  experimental: {
    apiKey: 'AIzaSyD3GZ7zDOZVx57Zhqnf2NlY_eiq5jgUUzs',
    authDomain: 'chefhero-experimental.firebaseapp.com',
    databaseURL: 'https://chefhero-experimental.firebaseio.com',
    projectId: 'chefhero-experimental',
    storageBucket: 'chefhero-experimental.appspot.com',
    messagingSenderId: '823787576485',
    appId: '1:823787576485:web:c10741bf6ebd1285a6414c',
  },
} as IFirebase;
