import LogRocket from 'logrocket';

export const logException = (exception: Error, functionName = ''): void => {
  LogRocket.captureException(exception, {
    extra: {
      functionName,
    },
  });
};

export const logMessage = (message: string): void => {
  LogRocket.captureMessage(message);
};
