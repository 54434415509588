import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import { AppDispatch } from 'store';
import { setChat, setOrderChat, setLoading } from 'store/appFeatures/appFeaturesSlice';
import { FIREBASE_CONFIG, Index } from './config';

const setFeatureFlag = async (dispatch: AppDispatch): Promise<void> => {
  const index = process.env.REACT_APP_FIREBASE ? process.env.REACT_APP_FIREBASE : 'experimental';
  initializeApp(FIREBASE_CONFIG[index as Index]);
  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 10;

  await fetchAndActivate(remoteConfig);

  const isChatEnabled = getValue(remoteConfig, 'chat_enabled');
  dispatch(setChat(isChatEnabled.asBoolean()));

  const isOrderChatEnabled = getValue(remoteConfig, 'chat_order_specific_enabled');
  dispatch(setOrderChat(isOrderChatEnabled.asBoolean()));

  dispatch(setLoading(false));
};

export default setFeatureFlag;
