import React from 'react';

export interface RadioButtonProps {
  id: string;
  name: string;
  label: string;
  isChecked: boolean;
  onChecked: () => void;
}

const RadioButton = ({ id, name, label, isChecked, onChecked }: RadioButtonProps): JSX.Element => (
  <label htmlFor={id}>
    <input
      type="radio"
      name={name}
      id={id}
      checked={isChecked}
      onChange={(e) => {
        if (e.target.checked) {
          onChecked();
        }
      }}
    />
    {label}
  </label>
);

export default RadioButton;
