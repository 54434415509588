import React from 'react';

import { useSelector } from 'react-redux';

import cx from 'classnames';

import AppBodyRoutes from 'routes/AppBodyRoutes';

import selectors from 'store/appSelectors';

const Body = (): JSX.Element => {
  const isMenuOpen = useSelector(selectors.isMenuOpen);
  return (
    <div className="app-body">
      {/* RIGHT SIDE */}

      <div
        className={cx('right-container', {
          open: isMenuOpen,
          close: !isMenuOpen,
        })}
      >
        <AppBodyRoutes />
      </div>
    </div>
  );
};

export default Body;
