import { Action, configureStore, Middleware, ThunkDispatch } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';
import LogRocket from 'logrocket';

import { AppState } from 'types/AppState';

import loggerMiddleware from '../middlewares/loggerMiddleware';

import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({})
      .concat(
        // has to be typed manually
        LogRocket.reduxMiddleware() as Middleware<(action: Action<'specialAction'>) => number, AppState>
      )
      // must come before getDefaultMiddleware, otherwise the function actions
      // are not shown
      .prepend(loggerMiddleware),
  devTools: { trace: true },
});

export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = (): ThunkDispatch<AppState, void, Action> => useDispatch<AppDispatch>();

export const initStore = store.getState();
